import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { baseURL } from "../../Api";
import { Button } from "../../components/Antd";
import { Auth } from "../../models";
import { useAuth } from "../../Providers/AuthProvider";

export function Login() {
  const { instance, inProgress, accounts } = useMsal();

  const { authed, login } = useAuth();
  const [error, setError] = useState<string | null>();

  function handleAuthResponse(accessTokenResponse: AuthenticationResult) {
    loginViaMicrosoft(accessTokenResponse)
      .then((authResp: Auth) => {
        const user = authResp.user;

        if (login && user) {
          login(user, authResp.token);
          return;
        }

        setError("user missing");
      })
      .catch((e) => {
        setError(e.message);
      });
  }

  const initializeSignIn = () => {
    instance.loginRedirect();
  };

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      const accessTokenRequest = {
        scopes: ["user.read"],
        account: accounts[0],
      };

      instance
        .acquireTokenSilent(accessTokenRequest)
        .then(handleAuthResponse)
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(accessTokenRequest)
              .then(handleAuthResponse)
              .catch((error) => {
                // Acquire token interactive failure
                console.log(error);
              });
          }
          console.log(error);
        });
    }
  }, [instance, authed, accounts, inProgress]);

  if (authed) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="w-full h-svh flex items-center justify-center flex-col">
      <h2>Staffing</h2>
      <div>
        <UnauthenticatedTemplate>
          <Button type="primary" onClick={initializeSignIn}>
            Sign in
          </Button>
        </UnauthenticatedTemplate>

        <AuthenticatedTemplate>
          Logging you in, please wait
        </AuthenticatedTemplate>

        <div>{error && <div>error: {error}</div>}</div>
      </div>
    </div>
  );
}

async function loginViaMicrosoft(response: any) {
  return fetch(baseURL + "/auth/microsoft", {
    body: JSON.stringify(response),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
    });
}
