import { Link } from "react-router-dom";
import { Location } from "../models";

interface LinkToLocationProps {
  location: Location | null | undefined
}

export function LinkToLocation(props: LinkToLocationProps) {
  const location = props.location;

  if (location === null || location === undefined) {
    return <b>No location assigned</b>;
  }

  return <Link to={`/locations/${location.id}`}>{location.name}</Link>;
}
