import { Project } from "../models";
import { Link } from "react-router-dom";

interface LinkToProjectProps {
  id?: string;
  name?: string;
  project?: Project;
}

export function LinkToProject(props: LinkToProjectProps) {
  const project = props.project;
  if (project) {
    return <Link to={`/projects/${project.id}`}>{project.name}</Link>;
  }

  return <Link to={`/projects/${props.id}`}>{props.name}</Link>;
}

// not great, first real tie to sf in the frontend.
// find a way to not do this.
const salesforceURL = import.meta.env.VITE_SALESFORCE_URL ?? "/#";

export function LinkToSalesforceProject(props: {
  project: Project;
  children: React.ReactNode;
}) {
  return (
    <Link to={`${salesforceURL}/${props.project.external_id}`}>
      {props.children}
    </Link>
  );
}
