import type { Configuration } from "@azure/msal-browser";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

const clientId = "43883553-7eb8-49f3-8115-dbc22a8f8398";
const tenantId = "98d33e71-cd98-4f8a-bc3f-7ffb5543e0f9";

const msalConfig: Configuration = {
  auth: {
    clientId: clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: "/login",
  },
};

const pca = new PublicClientApplication(msalConfig);

export function Microsoft(props: { children: React.ReactNode }) {
  return <MsalProvider instance={pca}>{props.children}</MsalProvider>;
}
