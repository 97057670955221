export function IconHolder(props: { children: React.ReactNode }) {
  return <span style={{ position: "absolute", right: "-5px", top: "-5px" }}>{props.children}</span>;
}

export function VacationIcon() {
  return <span>🌴</span>;
}

export function HolidayIcon() {
  return <span>🗓</span>;
}
