import { useEffect, useRef, useState } from "react";

export function DragableDualColumnView(props: {
  left: React.ReactNode;
  right: React.ReactNode;
  end?: React.ReactNode;
}) {
  const [initialWidth] = useState(200); // Only used for initial render
  const [isDragging, setIsDragging] = useState(false);
  const dragStartX = useRef(0);
  const dragStartWidth = useRef(0);
  const lastX = useRef(0);
  const middleColumnRef = useRef<HTMLDivElement>(null);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);

    if (!middleColumnRef.current) {
      return;
    }

    lastX.current = e.clientX;
    dragStartX.current = e.clientX;
    dragStartWidth.current = middleColumnRef.current.offsetWidth;
    document.body.style.userSelect = "none";
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isDragging || !middleColumnRef.current) return;

    const deltaX = e.clientX - lastX.current;
    const currentWidth = middleColumnRef.current.offsetWidth;
    const newWidth = Math.max(100, currentWidth + deltaX);

    middleColumnRef.current.style.width = `${newWidth}px`;
    lastX.current = e.clientX;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    document.body.style.userSelect = "";
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  return (
    <div className="flex items-stretch justify-between w-full overflow-hidden">
      <div
        ref={middleColumnRef}
        className="flex-shrink-0"
        style={{ width: `${initialWidth}px` }}
      >
        {props.left}
      </div>
      <div
        className="bg-red-400 w-[10px] min-w-[10px] cursor-move"
        onMouseDown={handleMouseDown}
      ></div>
      <div className="w-full flex-grow overflow-x-scroll">{props.right}</div>
      {props.end ? (
        <div className="w-36 flex-shrink-0 overflow-hidden">{props.end}</div>
      ) : null}
    </div>
  );
}
