import { MainTitle } from "@/components/Typography";
import { WeekOverview } from "@/components/ui/WeekOverview";
import { Suspense } from "react";
import { useAuth } from "../../Providers/AuthProvider";
import { Card } from "../../components/Card";
import { MyProjectIssues } from "../../components/Dashboard/MyProjectIssues";
import { InternalError } from "../../components/Status/InternalError";
import { Loading } from "../../components/Status/Loading";
import { Absences } from "../../components/Users/Absences";
import { Holidays } from "../../components/Users/Holidays";
import { LinkToUser } from "../../components/Users/LinkToUser";
import { DashboardStats, UserAndProject } from "../../models";
import { addMonths } from "../../utils";
import { LinkToProject } from "../../utils/project";
import { UserStaffing } from "../Users/UserStaffing";
import { UserTimeEntries } from "../Users/UserTimeEntries";
import { DirectReportIssues } from "./DirectReportsIssues";

export function Dashboard() {
  const { api, user } = useAuth();

  const reqMyDashboard = api.fetchMyDashboardInfo();

  if (reqMyDashboard.isPending) {
    return <Loading />;
  }

  if (reqMyDashboard.isError) {
    return <InternalError />;
  }

  const data = reqMyDashboard.data;

  const start = new Date();
  const end = addMonths(start, 3).toDate();

  return (
    <div>
      <MainTitle title="Dashboard">
        Hi <LinkToUser user={user} />, welcome back.
      </MainTitle>
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-3 flex flex-col gap-4">
          <MyProjectIssues />
          <div>
            <WeekOverview userId={user.id.toString()} date={start} />
          </div>
          {data.staffing_suggestion_summaries.length > 0 && (
            <div>
              <StaffingSuggestionSummaryCard
                staffing_suggestion_summaries={
                  data.staffing_suggestion_summaries
                }
              />
            </div>
          )}
          <DashboardStatsComponent stats={data.stats} />
          <UserStaffing user={user} />
          <UserTimeEntries user={user} months={2} />
        </div>
        <div className="col-span-1">
          <div className="flex flex-col gap-2">
            <Card title="Upcoming">
              <Absences userId={user.id} start={start} end={end} />
            </Card>

            <Card title="Holidays">
              <Holidays locationId={user.location_id} start={start} end={end} />
            </Card>

            <DirectReportIssues />
          </div>
        </div>
      </div>
    </div>
  );
}

function DashboardStatsComponent(props: { stats: DashboardStats[] }) {
  return null;
  const _stats = props.stats;
  return (
    <div>
      <Suspense fallback={<Loading />}>tbd</Suspense>
    </div>
  );
}

function StaffingSuggestionSummaryCard(props: {
  staffing_suggestion_summaries: Array<UserAndProject>;
}) {
  const srs = props.staffing_suggestion_summaries;

  return (
    <Card title="Staffing Suggestions">
      <ul>
        {srs.map((s) => {
          return (
            <li key={`${s.project.id}|${s.user.id}`}>
              <LinkToUser user={s.user} />
              <span> suggested staffing on </span>
              <LinkToProject project={s.project} />
            </li>
          );
        })}
      </ul>
    </Card>
  );
}
