import type {
  Absence,
  Booking,
  Holiday,
  Project,
  Location,
  TimeEntry,
  User,
  UserWorkDaySchedule,
  UserWithUserSkill,
} from "../models";
import { weekKeyFromDate } from "./date";

type GroupByFunc<T> = {
  (keyFunc: T): any;
};

function groupBySingle<T>(xs: Array<T>, fn: GroupByFunc<T>): Map<any, T> {
  const m = new Map<any, T>();
  (xs ?? []).forEach((x) => {
    const keyVal = fn(x);
    m.set(keyVal, x);
  });

  return m;
}

function groupBy<T>(xs: Array<T>, fn: GroupByFunc<T>): Map<any, Array<T>> {
  const m = new Map<any, Array<T>>();
  (xs ?? []).forEach((x) => {
    const keyVal = fn(x);

    let arr: Array<T> | undefined = m.get(keyVal);
    if (arr === undefined) {
      arr = new Array<T>();
    }
    m.set(keyVal, [...arr, x]);
  });

  return m;
}

export function groupUserWithUserSkillsBySkillLevel(
  uwus: Array<UserWithUserSkill>,
): Map<number, Array<UserWithUserSkill>> {
  return groupBy<UserWithUserSkill>(uwus, (u) => u.user_skill.level);
}

export function groupBookingsByProjectId(
  bookings: Array<Booking>,
): Map<number, Array<Booking>> {
  // todo: use Object.groupBy once we figure out how to get tsc not to complain
  return groupBy<Booking>(bookings, (b) => b.project_id);
}

export function groupLocationsById(
  locations: Array<Location>,
): Map<number, Location> {
  return groupBySingle<Location>(locations, (h) => h?.id);
}

export function groupUsersById(users: Array<User>): Map<number, User> {
  return groupBySingle<User>(users, (h) => h.id);
}

export function groupProjectsById(
  projects: Array<Project>,
): Map<number, Project> {
  return groupBySingle<Project>(projects, (h) => h.id);
}

export function groupHolidaysByLocationId(
  holidays: Array<Holiday>,
): Map<number, Array<Holiday>> {
  return groupBy<Holiday>(holidays, (h) => h.location_id);
}

export function groupHolidaysByWeek(
  holidays: Array<Holiday>,
): Map<string, Array<Holiday>> {
  return groupBy<Holiday>(holidays, (h) => weekKeyFromDate(new Date(h.date)));
}

export function groupAbsencesByWeek(
  absences: Array<Absence>,
): Map<string, Array<Absence>> {
  // todo: use Object.groupBy once we figure out how to get tsc not to complain
  return groupBy<Absence>(absences, (a) => weekKeyFromDate(new Date(a.date)));
}

export function groupBookingsByWeek(
  bookings: Array<Booking>,
): Map<string, Array<Booking>> {
  // todo: use Object.groupBy once we figure out how to get tsc not to complain
  return groupBy<Booking>(bookings, (b) => weekKeyFromDate(b.date));
}

export function groupTimeEntriesByWeek(
  timeEntries: Array<TimeEntry>,
): Map<string, Array<TimeEntry>> {
  return groupBy<TimeEntry>(timeEntries, (t) => {
    return weekKeyFromDate(new Date(t.date));
  });
}

export function groupUserWorkDaySchedulesByUserId(
  userworkdayschedules: Array<UserWorkDaySchedule>,
): Map<number, Array<UserWorkDaySchedule>> {
  // todo: use Object.groupBy once we figure out how to get tsc not to complain
  return groupBy<UserWorkDaySchedule>(userworkdayschedules, (t) => t.user_id);
}

export function groupAbsencesByUserId(
  absences: Array<Absence>,
): Map<number, Array<Absence>> {
  // todo: use Object.groupBy once we figure out how to get tsc not to complain
  return groupBy<Absence>(absences, (t) => t.user_id);
}

export function groupBookingsByUserId(
  bookings: Array<Booking>,
): Map<number, Array<Booking>> {
  // todo: use Object.groupBy once we figure out how to get tsc not to complain
  return groupBy<Booking>(bookings, (t) => t.user_id);
}

export function groupBookingsByProjectUserId(
  bookings: Array<Booking>,
): Map<number, Array<Booking>> {
  // todo: use Object.groupBy once we figure out how to get tsc not to complain
  return groupBy<Booking>(bookings, (t) => t.project_user_id);
}

export function groupTimeEntriesByUserId(
  timeEntries: Array<TimeEntry>,
): Map<number, Array<TimeEntry>> {
  // todo: use Object.groupBy once we figure out how to get tsc not to complain
  return groupBy<TimeEntry>(timeEntries, (t) => t.user_id);
}

export function groupTimeEntriesByProjectId(
  timeEntries: Array<TimeEntry>,
): Map<number, Array<TimeEntry>> {
  // todo: use Object.groupBy once we figure out how to get tsc not to complain
  return groupBy<TimeEntry>(timeEntries, (t) => t.project_id);
}
