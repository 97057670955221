import { useSearchParams } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";
import { parseDate } from "../../utils";
import type { ReportWeekOverview } from "../../routes/Reports/types";
import { Popover } from "antd";

export function WeekOverviewPage() {
  const [searchParams, _] = useSearchParams();

  const userId = searchParams.get("userId");
  const inputDate = searchParams.get("date");
  const datetime = inputDate != null ? parseDate(inputDate) : new Date();
  const date = new Date(datetime.toDateString());

  if (userId === null) {
    return <div>not found</div>;
  }

  return <WeekOverview userId={userId} date={date} />;
}

export function WeekOverview(props: { userId: string; date: Date }) {
  const { api } = useAuth();

  const fetchWeekOverview = api.fetchReportWeekOverview(
    props.userId,
    props.date,
  );

  if (fetchWeekOverview.isPending) {
    return <div>loading</div>;
  }

  if (fetchWeekOverview.isError) {
    return <div>error</div>;
  }

  return <WeekSummaryInner weekOverview={fetchWeekOverview.data} />;
}

function StatContainer(props: {
  value: number;
  limit: number;
  title: string;
  link: string;
}) {
  const v = Number(props.value);
  const l = Number(props.limit);
  const r = l > 0 ? v / l : 0;
  const c = borderColorForPercent(r);

  return (
    <Container
      title={props.title}
      link={props.link}
      inner={`${v.toFixed(1)} / ${l.toFixed(1)}`}
      small={`${(100 * r).toFixed(1)}%`}
      color={c}
    />
  );
}

function WeekSummaryInner(props: { weekOverview: ReportWeekOverview }) {
  const w = props.weekOverview;
  const emoji = trackingQualityEmoji(w.tracking_quality_percentage);
  const trackingQuality = Number(w.tracking_quality_percentage ?? 0).toFixed(0);
  const trackingColor = borderColorForPercent(w.tracking_quality_percentage);

  return (
    <div className="flex justify-between align-middle w-full h-full">
      <StatContainer
        title="Billable this week"
        link="https://salesfive.lightning.force.com/lightning/r/Report/00OQF000000x1OH2AY/view"
        value={w.billable_hours_this_week}
        limit={w.possible_hours_this_week}
      />
      <StatContainer
        title="Total this week"
        link="https://salesfive.lightning.force.com/lightning/r/Report/00OQF000000x1OI2AY/view"
        value={w.total_hours_this_week}
        limit={w.possible_hours_this_week}
      />
      <StatContainer
        title="Billable last week"
        link="https://salesfive.lightning.force.com/lightning/r/Report/00OQF000000x1ph2AA/view"
        value={w.billable_hours_last_week}
        limit={w.possible_hours_last_week}
      />
      <StatContainer
        title="Total last week"
        link="https://salesfive.lightning.force.com/lightning/r/Report/00OQF000000x1w92AA/view"
        value={w.total_hours_last_week}
        limit={w.possible_hours_last_week}
      />
      <Container
        title="Tracking Quality"
        inner={`${trackingQuality}%`}
        small={emoji}
        color={trackingColor}
        popover={
          <TrackingPopoverContent
            correct={w.days_tracked_correctly}
            days={w.total_tracked_days}
          />
        }
      />
    </div>
  );
}

function TrackingPopoverContent(props: { correct: number; days: number }) {
  return (
    <div>
      You maintained same-day tracking for {props.correct} out of {props.days}{" "}
      workdays
    </div>
  );
}

function Container(props: {
  title: string;
  link?: string;
  inner: string;
  small: string;
  color: string;
  popover?: React.ReactNode;
}) {
  return (
    <div className="flex-grow mr-2 last:mr-0 lg:min-w-[120px] md:min-w-[50px]">
      <div className="border border-solid border-gray-400 rounded-md shadow-md relative pb-3 overflow-hidden">
        <div className="p-2">
          <div className="text-center">
            <b>
              {props.link ? (
                <a
                  href={props.link}
                  target="_blank"
                  className="text-black font-light"
                >
                  {props.title}
                </a>
              ) : (
                <span className="font-light">{props.title}</span>
              )}
            </b>
          </div>
          <div className="text-center font-bold lg:text-2xl md:text-lg">
            {props.popover ? (
              <Popover content={props.popover} placement="left">
                <div className="cursor-pointer">{props.inner}</div>
              </Popover>
            ) : (
              props.inner
            )}
          </div>
          <div className="text-center">{props.small}</div>
        </div>
        <div
          className="bottom-0 absolute w-full inline-block h-2"
          style={{ backgroundColor: props.color }}
        />
      </div>
    </div>
  );
}

function trackingQualityEmoji(quality: number) {
  if (quality < 80) {
    return "😞";
  }

  if (quality < 100) {
    return "🙂";
  }

  return "🤩";
}

function borderColorForPercent(percent: number) {
  return interpolateColor(percent, 0.8);
}

interface RGB {
  r: number;
  g: number;
  b: number;
}

function interpolateColor(v: number, maxGreen = 0.8): string {
  // Ensure the value is between 0 and maxGreen
  const value = Math.max(0, Math.min(maxGreen, v));

  // Normalize the value to a 0-1 range
  const normalizedValue: number = value / maxGreen;

  // Define the colors
  const colors: RGB[] = [
    { r: 242, g: 42, b: 42 }, // Red (0.0)
    { r: 254, g: 198, b: 42 }, // Orange (0.5)
    { r: 21, g: 128, b: 61 }, // Green (1.0)
  ];

  // Determine which two colors to interpolate between
  let colorIndex: number;
  let segmentValue: number;
  if (normalizedValue < 0.5) {
    colorIndex = 0; // Between red and orange
    segmentValue = normalizedValue * 2; // Rescale value to 0-1 range for this segment
  } else {
    colorIndex = 1; // Between orange and green
    segmentValue = (normalizedValue - 0.5) * 2; // Rescale value to 0-1 range for this segment
  }

  // Interpolate between the two colors
  const color1: RGB = colors[colorIndex];
  const color2: RGB = colors[colorIndex + 1];

  const r: number = Math.round(color1.r + (color2.r - color1.r) * segmentValue);
  const g: number = Math.round(color1.g + (color2.g - color1.g) * segmentValue);
  const b: number = Math.round(color1.b + (color2.b - color1.b) * segmentValue);

  // Return the color as a hex string
  return `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
}
