import type {
  Absence,
  Booking,
  Holiday,
  Project,
  ProjectUser,
  TimeEntry,
  User,
  UserWorkDaySchedule,
} from "../models";
import {
  getTotalAbsences,
  getTotalBookedHours,
  getTotalHolidayHours,
  getTotalTrackedHours,
} from "../routes/Reports/util";
import { accuracy } from "./accuracy";
import { weekKeyFromDate } from "./date";
import {
  groupAbsencesByWeek,
  groupBookingsByProjectId,
  groupBookingsByWeek,
  groupHolidaysByWeek,
  groupTimeEntriesByProjectId,
  groupTimeEntriesByWeek,
} from "./group";
import type { Report, ReportRow } from "./report";

export interface RawUserData {
  user: User;
  projects: Array<Project>;
  bookings: Array<Booking>;
  projectUsers: Array<ProjectUser>;
  timeEntries: Array<TimeEntry>;
  holidays: Array<Holiday>;
  absences: Array<Absence> | [];
  weeks: Array<Date>;
}

export interface UserRowHeaderData {
  title?: string;
  project?: Project;
  isProjectMember: boolean;
}

export interface ProjectCellData {
  week: Date;
  user?: User;
  bookings: Array<Booking> | [];
  timeEntries: Array<TimeEntry> | [];
  workSchedules: Array<UserWorkDaySchedule> | [];
  holidays: Array<Holiday> | [];
  absences: Array<Absence> | [];
  summary?: ProjectCellSummary;
  accuracy: number;
}

export interface ProjectCellSummary {
  amountTracked: number;
  amountBooked: number;
  holidayHours: number;
  absenceHours: number;
  possibleHours: number;
  percentBooked: number;
  trackingDiff: number; // diff between tracked-booked
  averageTracked: number;
  accuracy: number;
}

export type UserReportType = Report<
  RawUserData,
  UserRowHeaderData,
  ProjectCellData,
  ProjectCellSummary
>;

function buildProjectCellSummary(data: ProjectCellData): ProjectCellSummary {
  const trackedHours = getTotalTrackedHours(data.timeEntries);
  const bookedHours = getTotalBookedHours(data.bookings);
  const trackingDiff = Math.abs(bookedHours - trackedHours);
  const absenceHours = getTotalAbsences(data.absences) * 8;
  const holidayHours = getTotalHolidayHours(data.holidays);
  const cellAccuracy = accuracy([
    { tracked: trackedHours, booked: bookedHours },
  ]);
  const availableHours = bookedHours;
  const percentBooked = availableHours > 0 ? trackedHours / availableHours : 1;

  return {
    amountTracked: trackedHours,
    amountBooked: bookedHours,
    absenceHours: absenceHours,
    holidayHours: holidayHours,
    trackingDiff: trackingDiff,
    possibleHours: availableHours,
    percentBooked: percentBooked,
    averageTracked: 0,
    accuracy: cellAccuracy,
  };
}

function buildProjectUserMap(projectUsers: Array<ProjectUser>): Set<number> {
  const projectIds = projectUsers.map((pu) => pu.project_id);
  return new Set(projectIds);
}

function hasInfo(s: ProjectCellSummary) {
  return s.amountTracked > 0 || s.amountBooked > 0;
}

function buildProjectRowSummary(
  data: Array<ProjectCellData>,
): ProjectCellSummary {
  const trackedHours = data.reduce((t, v) => t + v.summary!.amountTracked, 0);
  const bookedHours = data.reduce((t, v) => t + v.summary!.amountBooked, 0);
  const absenceHours = data.reduce((t, v) => t + v.summary!.absenceHours, 0);
  const trackingDiff = data.reduce((t, v) => t + v.summary!.trackingDiff, 0);
  const accurracyMeasurements = data
    .map((v) => v.summary!.accuracy)
    .filter((v) => !Number.isNaN(v));

  const numMeasuresWithBookings = data.reduce(
    (t, v) => t + (hasInfo(v.summary!) ? 1 : 0),
    0,
  );

  const rowAccuracy =
    accurracyMeasurements.reduce((t, v) => t + v, 0) / numMeasuresWithBookings;
  const rowAverageTrackingHours = trackedHours / numMeasuresWithBookings;

  const holidayHours = data.reduce((t, v) => t + v.summary!.holidayHours, 0);
  const availableHours = data.reduce((t, v) => t + v.summary!.possibleHours, 0);
  const percentBooked = availableHours > 0 ? trackedHours / availableHours : 1;

  return {
    amountTracked: trackedHours,
    amountBooked: bookedHours,
    absenceHours: absenceHours,
    trackingDiff: trackingDiff,
    holidayHours: holidayHours,
    possibleHours: availableHours,
    percentBooked: percentBooked,
    averageTracked: rowAverageTrackingHours,
    accuracy: rowAccuracy,
  };
}

export function buildUserReportData(data: RawUserData): UserReportType {
  //const sortedUsers = sortProjectUsersByGivenName(props.projectUsersWithUsers);
  const projects = data.projects;
  const projectMemberships = buildProjectUserMap(data.projectUsers);
  const timeEntriesByProjectId = groupTimeEntriesByProjectId(data.timeEntries);
  const bookingsByProjectId = groupBookingsByProjectId(data.bookings);
  const absences = data.absences;
  const userHolidays = data.holidays;

  const rows = projects.map((project) => {
    const userTimeEntries = timeEntriesByProjectId.get(project.id) ?? [];
    const userBookings = bookingsByProjectId.get(project.id) ?? [];
    const userAbsences = absences;

    const bookingsByWeek = groupBookingsByWeek(userBookings);
    const timeEntriesByWeek = groupTimeEntriesByWeek(userTimeEntries);
    const holidaysByWeek = groupHolidaysByWeek(userHolidays);
    const absencesByWeek = groupAbsencesByWeek(userAbsences);

    const cols = data.weeks.map((week) => {
      const weekKey = weekKeyFromDate(week);
      const bookings = bookingsByWeek.get(weekKey) ?? [];
      const timeEntries = timeEntriesByWeek.get(weekKey) ?? [];
      const holidays = holidaysByWeek.get(weekKey) ?? [];
      const absences = absencesByWeek.get(weekKey) ?? [];

      const cellData = {
        week: week,
        user: data.user,
        bookings: bookings,
        timeEntries: timeEntries,
        holidays: holidays,
        absences: absences,
      } as ProjectCellData;

      cellData.summary = buildProjectCellSummary(cellData);

      return cellData;
    });

    const headerData = {
      project: project,
      isProjectMember: projectMemberships.has(project.id),
    };

    return {
      header: headerData,
      columns: cols,
      summary: buildProjectRowSummary(cols),
    } as ReportRow<UserRowHeaderData, ProjectCellData, ProjectCellSummary>;
  });

  return {
    raw: data,
    rows: rows,
  };
}
