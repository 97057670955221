import React from "react";
import { Project, Timeline, User, UserAndProjectUser } from "../../../models";
import {
  BookingLine,
  BookingsHeader,
  BookingTable,
} from "../../Bookings/Bookings";
import { UserStaffing } from "../../Users/UserStaffing";

interface CurrentUsersProps {
  project: Project;
  projectUsersWithUsers: Array<UserAndProjectUser>;
  filteredUsers: Array<User>;
  label: React.ReactElement;
  onAddUser: (u: User) => void;
  timeline: Timeline;
}

export function CurrentUsers(props: CurrentUsersProps) {
  const projectUsersWithUsers = props.projectUsersWithUsers;
  const existingUserIds = collectUserIdsFromProjectUsers(projectUsersWithUsers);
  const filteredUsers = props.filteredUsers;

  let filteredUserIds = filteredUsers
    .map((u) => u.id)
    .filter((user_id) => existingUserIds.indexOf(user_id) < 0)
    .map((userId) => userId.toString());

  if (filteredUserIds.length > 15) {
    filteredUserIds = [];
  }

  if (filteredUserIds.length > 15) {
    return <div>too many results</div>;
  }

  const hasFilteredResults = filteredUserIds.length > 0;

  if (!hasFilteredResults) {
    return <div>no results</div>;
  }

  return (
    <BookingTable width="250px">
      <BookingLine>
        <BookingsHeader timeline={props.timeline} />
      </BookingLine>

      <UserStaffing
        user_ids={filteredUserIds.map((id) => parseInt(id))}
        timeline={props.timeline}
      />
    </BookingTable>
  );
}

function collectUserIdsFromProjectUsers(
  projectUsersWithUsers: Array<UserAndProjectUser>,
): Array<number> {
  return projectUsersWithUsers.map((pu) => pu.user.id);
}
