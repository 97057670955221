import Alert from "antd/es/alert";
import { useAuth } from "../../Providers/AuthProvider";
import type { Project } from "../../models";
import { formatFriendlyDate } from "../../utils";
import { LinkToProject } from "../../utils/project";

export function MyProjectIssues() {
  const { api } = useAuth();

  const fetchProjectIssues = api.fetchDashboardProjectIssues();

  if (fetchProjectIssues.isPending) {
    return <div> loading </div>;
  }

  if (fetchProjectIssues.isError) {
    return <div> error </div>;
  }

  const issues = fetchProjectIssues.data;

  return (
    <>
      <ExpiredProjects projects={issues.expired_projects} />
      <ZeroStaffingProjects projects={issues.zero_future_staffing} />
    </>
  );
}

function ZeroStaffingProjects(props: { projects: Project[] }) {
  if (props.projects.length === 0) {
    return null;
  }

  const title = buildTitleForZeroStaffingProjects(props.projects);

  return (
    <Alert
      message={<b>{title}</b>}
      showIcon
      closable
      description={<MessageForZeroStaffingProjects projects={props.projects} />}
      type="info"
    />
  );
}

function ExpiredProjects(props: { projects: Project[] }) {
  if (props.projects.length === 0) {
    return null;
  }

  const title = buildTitleForExpiredProjects(props.projects);

  return (
    <Alert
      message={<b>{title}</b>}
      closable
      showIcon
      description={<MessageForExpiredProjects projects={props.projects} />}
      type="info"
    />
  );
}

function buildTitleForExpiredProjects(projects: Project[]): string {
  const n = projects.length;
  return `You have ${n} ongoing projects running past the end date.`;
}

function buildTitleForZeroStaffingProjects(projects: Project[]): string {
  const n = projects.length;
  return `You have ${n} active projects without ANY staffing in the future.`;
}

function MessageForExpiredProjects(props: { projects: Project[] }) {
  return (
    <div>
      <ul>
        {props.projects.map((p) => {
          const endDate = formatFriendlyDate(p.end_date);

          return (
            <li key={p.id}>
              <LinkToProject project={p} /> - Project ended {endDate}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function MessageForZeroStaffingProjects(props: { projects: Project[] }) {
  return (
    <div>
      <ul>
        {props.projects.map((p) => {
          const startDate = formatFriendlyDate(p.start_date);
          const endDate = formatFriendlyDate(p.end_date);

          return (
            <li key={p.id}>
              <LinkToProject project={p} /> - {startDate} - {endDate}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
