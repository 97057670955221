export * from "./user";
export * from "./team";
export * from "./date";
export * from "./sort";
export * from "./group";
export * from "./booking";

export function parseNumber(v: string | number): number {
  if (typeof v === "number") {
    return v;
  }

  return parseFloat(v);
}

export function normalizeString(s: string): string {
  return s
    .toLocaleLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}
