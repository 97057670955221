import {
  Button,
  Form,
  FormInstance,
  Input,
  Modal,
  Select,
  notification,
} from "antd";
import React from "react";
import { useAuth } from "../../Providers/AuthProvider";
import { canAddSkills } from "../../Providers/permissions";

type NotificationType = "success" | "error";

export default function CreateSkill() {
  const { api, roles } = useAuth();
  const [notificationApi, contextHolder] = notification.useNotification();

  // if user has permission to add skills:
  // fetch the skill categories
  const fetchSkillCategories = api.fetchSkillCategories();

  // React state management
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const openModal = () => setIsModalOpen(true);
  const [isSubmittable, setIsSubmittable] = React.useState<boolean>(false);
  const [form] = Form.useForm();

  const mut = api.createSkill();
  const handleOk = () => {
    if (mut.isPending) {
      return;
    }
    const { name, skill_category_id } = form.getFieldsValue();
    const skill = {
      name,
      skill_category_id,
      label: name,
      color: "",
    };

    let type: NotificationType,
      notificationMessage: string,
      notificationDescription: string;
    mut
      .mutateAsync(skill)
      .then((_res) => {
        type = "success";
        notificationMessage = "Skill successfully added";
        setIsModalOpen(false);
        form.resetFields();
      })
      .catch((err) => {
        type = "error";
        notificationMessage = "Couldn't add Skill";
        notificationDescription = "Please review your input and try again";
        console.log(err);
      })
      .finally(() => {
        notificationApi[type]({
          message: notificationMessage,
          description: notificationDescription,
        });
      });
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  // Constants for modal customization
  const modalTitle = "Add Skill";
  const buttonType = "primary";
  const okText = "Add Skill";

  if (fetchSkillCategories.isPending) {
    return <div>loading</div>;
  }

  if (fetchSkillCategories.isError) {
    return <div>error</div>;
  }

  // Ant Design Select takes in Array<{value:string|number, label:string}> so we map the result to that type
  const menuItems = fetchSkillCategories.data!.map((i) => {
    return { value: i.id, label: i.name };
  });

  // check if user if Admin, if not do not show the button to user
  const isButtonVisible = canAddSkills(roles);
  if (!isButtonVisible) {
    return <></>;
  }
  return (
    <>
      {contextHolder}
      <Button type={buttonType} onClick={openModal}>
        Create Skill
      </Button>

      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={okText}
        okButtonProps={{ disabled: isSubmittable }}
      >
        {!fetchSkillCategories.isPending && (
          <SkillAddForm
            form={form}
            menuItems={menuItems}
            setIsSubmittable={setIsSubmittable}
          />
        )}
      </Modal>
    </>
  );
}

interface SkillAddFormProps {
  readonly form: FormInstance;
  readonly menuItems: Array<{ value: number; label: string }>;
  readonly setIsSubmittable: Function;
}

function SkillAddForm(props: SkillAddFormProps) {
  const values = Form.useWatch([], props.form);
  React.useEffect(() => {
    props.form
      .validateFields({ validateOnly: true })
      .then(props.setIsSubmittable(false), () => {
        props.setIsSubmittable(true);
      });
  }, [values]);

  return (
    <Form layout="vertical" form={props.form}>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please enter a Skill name" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Category"
        name="skill_category_id"
        rules={[{ required: true, message: "Please select a Skill category" }]}
      >
        <Select
          placeholder="Select a category"
          options={props.menuItems}
        ></Select>
      </Form.Item>
    </Form>
  );
}
