import { Team } from "../models";
import { Link } from "react-router-dom";

interface LinkToTeamProps {
  team?: Team;
}

export function LinkToTeam(props: LinkToTeamProps) {
  const team = props.team;

  if (team === undefined || team === null) {
    return <span>No Team</span>;
  }

  return <Link to={`/teams/${team.id}`}>{team.name}</Link>;
}
