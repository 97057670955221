import React, { useState } from "react";
import {
  Project,
  Skill,
  Timeline,
  User,
  UserAndProjectUser,
  UserSkill,
} from "../../../models";
import { userName } from "../../../utils";
import {
  Col,
  Divider,
  Row,
  Select,
  Slider,
  Space,
  Tag,
  type SliderMarks,
} from "../../Antd";
import { CurrentUsers as UserSearchResults } from "./UserSearchResults";

// todo: extend addusertoproject props from addusetoproject.tsx
interface AddUserBySkillProps {
  skills: Array<Skill>;
  users: Array<User>;
  userSkills: Array<UserSkill>;
  projectUsersWithUsers: Array<UserAndProjectUser>;
  project: Project;
  onAddUser: (u: User) => void;
  timeline: Timeline;
}
const marks: SliderMarks = {
  1: "Basic",
  2: "Intermediate",
  3: "Advanced",
  4: "Pro",
  5: {
    style: {
      color: "#f50",
    },
    label: <strong>Evangelist</strong>,
  },
};

type SkillId = number;
type UserId = number;

export function AddUserBySkill(props: AddUserBySkillProps) {
  const skills = props.skills;
  const users = props.users;
  const userSkills = props.userSkills;
  const [selectedSkillIds, setSelectedSkillIds] = useState<
    Map<SkillId, number>
  >(new Map());
  const [filteredUsers, setFilteredUsers] = useState<Array<User>>([]);

  // -- bust out into its own func
  const usersWithSkills = new Map<UserId, Map<SkillId, number>>();

  userSkills.forEach((us) => {
    let skillsForUser = usersWithSkills.get(us.user_id);
    if (skillsForUser === undefined) {
      skillsForUser = new Map<SkillId, number>();
    }

    skillsForUser.set(us.skill_id, us.level);
    usersWithSkills.set(us.user_id, skillsForUser);
  });
  // --

  const skillOptions = skills.map((s) => {
    return {
      label: s.name,
      value: s.id,
    };
  });

  const handleChange = (newSkillIds: Array<number>) => {
    const m = new Map<SkillId, number>();
    newSkillIds.forEach((skillId) => {
      const existingValue = selectedSkillIds?.get(skillId) ?? 1;
      m.set(skillId, existingValue);
    });

    setSelectedSkillIds(m);
    evaluatePossibleUsers(m);
  };

  const evaluatePossibleUsers = (selectedSkillIds: Map<SkillId, number>) => {
    if (selectedSkillIds.size === 0) {
      setFilteredUsers([]);
      return;
    }

    let possibleUsers = new Array<User>();
    users.forEach((u) => {
      const skillsForUser = usersWithSkills.get(u.id);

      if (skillsForUser === undefined) {
        return;
      }

      const hasSkills = [...selectedSkillIds.keys()].every((skillId) => {
        const skillLevel = selectedSkillIds.get(skillId);
        const userSkillLevel = skillsForUser.get(skillId) ?? 0;
        return userSkillLevel >= (skillLevel ?? 0);
      });

      if (hasSkills) {
        possibleUsers.push(u);
      }
    });

    setFilteredUsers([...possibleUsers]);
  };

  const handleSkillLevelChange = (skill: Skill, skillLevel: number) => {
    const newSelectedSkillIds = selectedSkillIds?.set(skill.id, skillLevel);
    setSelectedSkillIds(newSelectedSkillIds);
    evaluatePossibleUsers(newSelectedSkillIds);
  };

  const usernameLabel = (
    <Username
      selectedSkillIds={selectedSkillIds}
      skills={skills}
      userSkills={usersWithSkills}
    />
  );
  return (
    <>
      <Select
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        placeholder="Please select"
        defaultValue={[...selectedSkillIds.keys()]}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input)
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        onChange={handleChange}
        options={skillOptions}
      />
      <Divider />

      <ListOfSkills
        skills={skills}
        selectedSkills={selectedSkillIds}
        handleSkillLevelChange={handleSkillLevelChange}
      />

      <UserSearchResults
        project={props.project}
        label={usernameLabel}
        projectUsersWithUsers={props.projectUsersWithUsers}
        filteredUsers={filteredUsers}
        onAddUser={props.onAddUser}
        timeline={props.timeline}
      />
    </>
  );
}

interface UsernameProps {
  user?: User;
  skills: Array<Skill>;
  selectedSkillIds: Map<SkillId, number>;
  userSkills: Map<number, Map<SkillId, number>>;
}

function Username(props: UsernameProps) {
  if (props.user === undefined) {
    return <span>no user defined</span>;
  }

  const user = props.user;
  const skills = props.skills;
  const selectedSkillIds = props.selectedSkillIds;
  const userSkills = props.userSkills.get(user.id);

  return (
    <>
      {userName(user)}
      <div>
        <Space wrap>
          {[...selectedSkillIds.keys()].map((skillId) => {
            const skill = skills.find((s) => s.id == skillId);
            if (skill === undefined || userSkills === undefined) {
              // todo: rewrite this whole func
              return null;
            }

            const level = userSkills.get(skillId);

            return (
              <Tag key={skill.id}>
                {skill.label}: {level}
              </Tag>
            );
          })}
        </Space>
      </div>
    </>
  );
}

interface ListOfSkillsProps {
  skills: Array<Skill>;
  selectedSkills: Map<SkillId, number>;
  handleSkillLevelChange: (id: Skill, lvl: number) => void;
}
function ListOfSkills(props: ListOfSkillsProps) {
  const skills = props.skills;
  const selectedSkills = props.selectedSkills;

  if (selectedSkills === undefined) {
    return <div>seleectedskillids undefined?!</div>;
  }
  const selectedSkillIds = [...selectedSkills.keys()];

  if (selectedSkillIds.length === 0) {
    return (
      <div>Please select one or more skills from above to see results.</div>
    );
  }

  return (
    <>
      {selectedSkillIds.map((sId) => {
        const skill = skills.find((s) => s.id === sId);

        if (skill === undefined) {
          return <div key={"yy" + sId}>skill undefined!?</div>;
        }

        const onChange = (value: number) => {
          props.handleSkillLevelChange(skill, value);
        };

        return (
          <React.Fragment key={"xx" + sId}>
            <Row key={`ski-${skill.id}`}>
              <Col span={6} style={{ display: "flex", alignItems: "center" }}>
                {skill.name}
              </Col>
              <Col span={17}>
                <Slider
                  marks={marks}
                  min={1}
                  max={5}
                  step={null}
                  defaultValue={0}
                  onChange={onChange}
                />
              </Col>
            </Row>
            <Divider />
          </React.Fragment>
        );
      })}
    </>
  );
}
