import { useAuth } from "../../Providers/AuthProvider";
import { ProjectTable } from "../../components/Projects/Table";
import { InternalError } from "../../components/Status/InternalError";
import { Loading } from "../../components/Status/Loading";
import { MainTitle } from "../../components/Typography";
import { addMonths, dateRangeToQueryParam, weekStart } from "../../utils";

export function ProjectMine() {
  const { api } = useAuth();
  const endDate = weekStart(new Date()).toDate();
  const startDate = addMonths(endDate, -2).toDate();
  const params = dateRangeToQueryParam(startDate, endDate);
  const fetchProjectUtilization = api.fetchProjectUtilization(params);
  const fetchProjects = api.fetchMyProjects();

  if (fetchProjects.isPending || fetchProjectUtilization.isPending) {
    return <Loading />;
  }

  if (fetchProjects.isError || fetchProjectUtilization.isError) {
    return <InternalError />;
  }

  const projects = fetchProjects.data.filter(
    (p) => p.status === "Active" || p.status === "Pre-Sales",
  );

  return (
    <>
      <MainTitle text="My Projects" />

      <ProjectTable
        utilization={fetchProjectUtilization.data}
        projects={projects}
      />
    </>
  );
}
