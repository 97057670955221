import { PropsWithChildren, useState } from "react";
import dayjs from "dayjs";
import React from "react";
import type { DatePickerProps, GetProps } from "antd";

import { DateSpan } from "../../models";
import { addMonths, addWeeks, dayjsRangeToDateSpan, weekEnd, weekStart } from "../../utils";
import { Layout, DatePicker, Divider, Space } from "../../components/Antd";
import { createContext } from "react";

const today = new Date();
const startDate = weekStart(addWeeks(today, -5).toDate());
const endDate = weekEnd(addMonths(today, 0).toDate());
const defaultDateSpan = dayjsRangeToDateSpan([startDate, endDate]);
export const ReportDateSelectionContext = createContext<DateSpan>(defaultDateSpan);

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;
const { RangePicker } = DatePicker;

interface ReportDateSelectorProps {
  title: React.ReactNode;
}

export function ReportContainer(props: PropsWithChildren<ReportDateSelectorProps>) {
  const [dateSpan, setDateSpan] = useState<DateSpan>(defaultDateSpan);

  const onChange = (_: DatePickerProps["value"] | RangePickerProps["value"], dateString: [string, string] | string) => {
    const start = dayjs(dateString[0]);
    const end = dayjs(dateString[1]);
    const ds = dayjsRangeToDateSpan([start, end]);
    setDateSpan(ds);
  };

  return (
    <Layout>
      <div className="flex justify-between">
        {props.title}
        <div className="flex">
          <RangePicker size="small" onChange={onChange} defaultValue={[dayjs(dateSpan.start), dayjs(dateSpan.end)]} />
        </div>
      </div>
      <Divider />
      <Space direction="vertical" size="middle">
        <ReportDateSelectionContext.Provider value={dateSpan}>{props.children}</ReportDateSelectionContext.Provider>
      </Space>
    </Layout>
  );
}
