import { MainTitle } from "../../components/Typography";
import { Divider, Card } from "../../components/Antd";
import { buildDefaultTimeline, dateSpanToQueryParam, sortUsersByGivenName, weekStart } from "../../utils";
import { useAuth } from "../../Providers/AuthProvider";
import { Loading } from "../../components/Status/Loading";
import { KindSelector } from "../../components/Bookings/KindSelector";
import { useState } from "react";
import { BookingKind } from "../../models";
import { UserStaffing } from "../../components/Users/UserStaffing";

export function UserOverbooked() {
  const timeline = buildDefaultTimeline();
  timeline.view.start = weekStart(new Date()).toDate();
  const params = dateSpanToQueryParam(timeline.view);
  const [visibleKinds, setVisibleKinds] = useState<BookingKind[]>([]);

  const { api } = useAuth();
  const { isPending, isError, data } = api.fetchOverbookedUsers(params);

  if (isPending) {
    return <Loading />;
  }

  if (isError) {
    return <div>error</div>;
  }

  const sortedUsers = sortUsersByGivenName(data);

  return (
    <div>
      <MainTitle text="Overbooked Users" />
      <p>All users with at least one week with over 40 hours booked.</p>
      <Divider />
      <Card title="Overbooked" extra={<KindSelector onChange={(ks) => setVisibleKinds(ks)} selected={visibleKinds} />}>
        <UserStaffing visibleKinds={visibleKinds} timeline={timeline} users={sortedUsers} />
      </Card>
    </div>
  );
}
