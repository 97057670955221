import { User } from "@/models";

export interface ReportRow<HeaderDataType, CellDataType, SummaryDataType> {
  header: HeaderDataType;
  columns: CellDataType[];
  summary: SummaryDataType;
}

export type Report<RawDataType, HeaderDataType, CellDataType, SummaryDataType> =
  {
    raw: RawDataType;
    rows: ReportRow<HeaderDataType, CellDataType, SummaryDataType>[] | [];
  };

export function collectUniqueLocationIds(users: User[]): number[] {
  const allLocationIds = users
    .map((u) => u.location_id)
    .filter((u) => u) as number[];

  return [...new Set(allLocationIds)];
}
