export function Card(props: {
  children: React.ReactNode;
  title?: string;
  extra?: React.ReactNode;
}) {
  return (
    <div className="border border-solid flex flex-col shadow-sm rounded-lg">
      <CardTitle title={props.title} extra={props.extra} />
      <div className="p-2">{props.children}</div>
    </div>
  );
}

function CardTitle(props: { title?: string; extra?: React.ReactNode }) {
  if (props.title === undefined) {
    return null;
  }

  return (
    <div className="px-2 py-1 font-bold font-sans">
      <div className="">{props.title}</div>
      {props.extra ? <div className="">{props.extra}</div> : null}
    </div>
  );
}
