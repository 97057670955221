import { isAdmin, isStaffingManager } from "@/Providers/permissions";
import { InternalError } from "@/components/Status/InternalError";
import { MainTitle } from "@/components/Typography";
import { ChevronDown, ChevronRight } from "lucide-react";
import { useState } from "react";
import { useAuth } from "../../Providers/AuthProvider";
import { Divider } from "../../components/Antd";
import { Loading } from "../../components/Status/Loading";
import { ProjectUserRequest } from "../../models";
import { ProjectUserRequestModal } from "./CreateModal";
import { ProjectUserRequestLine } from "./LineItem";

export function ProjectUserRequestIndex() {
  const { api, user, roles } = useAuth();

  let purData = null;
  if (isAdmin(roles) || isStaffingManager(roles)) {
    purData = api.fetchProjectUserRequests();
  } else {
    purData = api.fetchProjectUserRequests({ user_id: user.id });
  }

  if (purData.isPending) {
    return <Loading />;
  }

  if (purData.isError) {
    return <InternalError />;
  }

  const data = purData.data;
  const approvedRequests = data.filter((pur) => pur.approver_id);
  const rejectedRequests = data.filter((pur) => pur.rejecter_id);
  const openRequests = data.filter(
    (pur) => pur.approver_id === null && pur.rejecter_id === null,
  );

  const onFinish = () => {
    purData.refetch();
  };

  return (
    <div>
      <div className="flex justify-between">
        <MainTitle title="Staffing Requests">
          Staffing Request Overview
          <p className="text-sm font-medium">
            Request users for your projects.
          </p>
        </MainTitle>
        <ProjectUserRequestModal onFinish={onFinish} size="middle" />
      </div>

      <RequestList
        title="Open Requests"
        open={true}
        onFinish={onFinish}
        requests={openRequests}
      />
      <Divider />
      <RequestList
        title="Approved Requests"
        open={false}
        onFinish={onFinish}
        requests={approvedRequests}
      />
      <Divider />
      <RequestList
        title="Rejected Requests"
        open={false}
        onFinish={onFinish}
        requests={rejectedRequests}
      />
    </div>
  );
}

interface RequestListProps {
  requests: Array<ProjectUserRequest>;
  open: boolean;
  title: string;
  onFinish: () => void;
}
function RequestList(props: RequestListProps) {
  const requests = props.requests ?? [];
  const title = props.title;
  const [open, setOpen] = useState<boolean>(props.open);

  return (
    <div>
      <RequestListHeader
        count={requests.length}
        title={title}
        open={open}
        setOpen={setOpen}
      />
      {open && <RequestListItems {...props} />}
    </div>
  );
}

interface RequestListHeaderProps {
  title: string;
  count: number;
  setOpen: (open: boolean) => void;
  open: boolean;
}
function RequestListHeader(props: RequestListHeaderProps) {
  const title = props.title;
  const open = props.open;
  const setOpen = props.setOpen;

  return (
    <div
      className="flex flex-row text-2xl font-bold pb-4"
      onClick={() => setOpen(!open)}
    >
      {open ? <ChevronRight size={32} /> : <ChevronDown size={32} />} {title}
      {" - "}
      {props.count}
    </div>
  );
}

function RequestListItems(props: RequestListProps) {
  const requests = props.requests;

  if (requests.length === 0) {
    return <div>Nothing to display</div>;
  }

  return (
    <div className="flex flex-col gap-4">
      {requests.map((pur) => (
        <ProjectUserRequestLine
          onFinish={props.onFinish}
          key={pur.id}
          projectUserRequest={pur}
        />
      ))}
    </div>
  );
}
