import { Booking, BookingKind, StaffingSuggestionEntry } from "../models";

export function bookingsFromSuggestions(
  project_user_id: number,
  project_id: number,
  suggestions: Array<StaffingSuggestionEntry>,
) {
  return suggestions.map(
    (s) =>
      ({
        id: 0,
        date: new Date(s.date),
        kind: s.kind ?? BookingKind.Booked,
        amount: s.amount,
        project_id: project_id,
        project_user_id: project_user_id,
      }) as Booking,
  );
}

export function capitalize(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}
