import { ProjectPlannerContent } from "@/components/Planner/Content";
import { PlannerRoleName, ProjectPlan } from "@/components/Planner/types";
import { buildProjectPlan, buildRoles } from "@/components/Planner/util";
import { Forbidden } from "@/components/Status/Forbidden";
import { InternalError } from "@/components/Status/InternalError";
import { Loading } from "@/components/Status/Loading";
import { Client, Project, ProjectPlanSummary } from "@/models";
import { useAuth } from "@/Providers/AuthProvider";
import { isAdmin, isStaffingManager } from "@/Providers/permissions";
import { monthsBetweenDates, parseDate } from "@/utils";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ClientHeader } from "./Show";

export function ClientPlanner() {
  const { api, roles } = useAuth();
  const { clientId } = useParams();

  if (clientId === undefined) {
    return <div>error</div>;
  }

  if (!isAdmin(roles) && !isStaffingManager(roles)) {
    return <Forbidden />;
  }

  const fetchClient = api.fetchClientById(clientId);
  const fetchClientPlan = api.fetchClientPlanByClientId(clientId);
  const fetchProjects = api.fetchProjectsByIds(
    fetchClient.data?.project_ids ?? [],
  );

  if (
    fetchClient.isPending ||
    fetchClientPlan.isPending ||
    fetchProjects.some((r) => r.isPending)
  ) {
    return <Loading />;
  }

  if (
    fetchClient.isError ||
    fetchClientPlan.isError ||
    fetchProjects.some((r) => r.isError)
  ) {
    return <InternalError />;
  }

  return (
    <ClientPlannerContent
      projects={fetchProjects.map((r) => r.data!)}
      client={fetchClient.data}
      summary={fetchClientPlan.data}
    />
  );
}

interface hasMonth {
  month: string;
}
function latestDate(records: hasMonth[]): string | null {
  if (records.length === 0) {
    return null;
  }

  const e = records.reduce((min, v) =>
    parseDate(v.month) > parseDate(min.month) ? v : min,
  );

  return e.month;
}
function earliestDate(records: hasMonth[]): string | null {
  if (records.length === 0) {
    return null;
  }

  const e = records.reduce((min, v) =>
    parseDate(v.month) < parseDate(min.month) ? v : min,
  );

  return e.month;
}

function ClientPlannerContent(props: {
  projects: Project[];
  client: Client;
  summary: ProjectPlanSummary;
}) {
  const [roles, _setRoles] = useState<PlannerRoleName[]>(
    buildRoles(props.summary.records),
  );

  const preSalesProjects = new Set<number>();
  for (const p of props.projects) {
    if (p.status === "Pre-Sales") {
      preSalesProjects.add(p.id);
    }
  }

  const startDate = earliestDate(props.summary.records);
  const endDate = latestDate(props.summary.records);
  const months =
    startDate !== null && endDate !== null
      ? monthsBetweenDates(startDate, endDate)
      : [];

  const [projectPlan, _setProjectPlan] = useState<ProjectPlan>(
    buildProjectPlan(
      months.map((x) => x.date),
      props.summary.records ?? [],
    ),
  );

  const [preSalesProjectPlan, _setPresalesProjectPlan] = useState<ProjectPlan>(
    buildProjectPlan(
      months.map((x) => x.date),
      props.summary.records.filter((r) => preSalesProjects.has(r.project_id)) ??
        [],
    ),
  );

  return (
    <div className="flex flex-col gap-2">
      <ClientHeader clientId={props.client.id} />
      {props.summary.records.length > 0 ? (
        <div className="flex flex-col gap-2">
          <div className="text-2xl">All Projects</div>
          <ProjectPlannerContent
            single={false}
            summary={props.summary}
            projectPlan={projectPlan}
            roles={roles}
            months={months}
            editable={false}
          />

          <div className="text-2xl">All Pre-Sales</div>
          <ProjectPlannerContent
            single={false}
            summary={props.summary}
            projectPlan={preSalesProjectPlan}
            roles={roles}
            months={months}
            editable={false}
          />
        </div>
      ) : (
        <div>Error: No plans in any projects</div>
      )}
    </div>
  );
}
