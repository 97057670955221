import { Project } from "@/models";
import { LinkToProject } from "@/utils/project";
import { ICellRendererParams } from "@ag-grid-community/core";

export function ProjectRenderer(props: ICellRendererParams<Project, string>) {
  const p = props.data;
  return (
    <div>
      <LinkToProject project={p} />
    </div>
  );
}
