import { MainTitle } from "../../components/Typography";
import { Divider, Card } from "../../components/Antd";
import { buildDefaultTimeline, dateSpanToQueryParam, weekStart, addMonths, sortUsersByGivenName } from "../../utils";
import { useAuth } from "../../Providers/AuthProvider";
import { Loading } from "../../components/Status/Loading";
import { UserStaffing } from "../../components/Users/UserStaffing";

export function UserUnderbooked() {
  const timeline = buildDefaultTimeline();
  const start = weekStart(new Date()).toDate();
  timeline.view.start = start;
  timeline.view.end = weekStart(addMonths(start, 2).toDate()).toDate();
  const params = dateSpanToQueryParam(timeline.view);

  const { api } = useAuth();
  const { isPending, isError, data } = api.fetchUnderbookedUsers(params);

  if (isPending) {
    return <Loading />;
  }

  if (isError) {
    return <div>error</div>;
  }

  const sortedUsers = sortUsersByGivenName(data);

  return (
    <div>
      <MainTitle text="Underbooked Users" />
      <p>All users with at least one week with less than 10hrs booked.</p>
      <Divider />
      <Card>
        <UserStaffing users={sortedUsers} timeline={timeline} />
      </Card>
    </div>
  );
}
