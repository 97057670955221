import { useAuth } from "../../Providers/AuthProvider";
import {
  Absence,
  Holiday,
  Project,
  UserWorkDaySchedule,
  WeekSummary,
} from "../../models";

import { Popover } from "../../components/Antd";
import React from "react";
import { date2weekNum, dateWithinWeek, formatFriendlyDate } from "../../utils";
import { bookingColor, reservedColor, salesColor } from "../../models/colors";
import { hoursForWeek } from "../../utils/schedule";

function BookingPopoverInner(props: BookingPopoverProps) {
  const { api } = useAuth();
  const ws = props.ws;

  let projectIds = [];
  for (let projectId of ws.projects.keys()) {
    projectIds.push(projectId.toString());
  }

  if (ws.totalAmount === 0) {
    return <div>No bookings</div>;
  }

  let projectsGroupedByKind = new Map<string, Array<number>>();
  ws.bookings.forEach((b) => {
    let arr = projectsGroupedByKind.get(b.kind);
    if (arr === undefined) {
      arr = new Array<number>();
    }

    if (arr.indexOf(b.project_id) < 0) {
      arr.push(b.project_id);
    }
    projectsGroupedByKind.set(b.kind, arr);
  });

  let absenceHours = ws.kinds["absence"];
  let holidayHours = ws.kinds["holiday"];

  const records = api.fetchProjectsByIds(projectIds);
  const isPending = records.some((result) => result.isPending);
  const isError = records.some((result) => result.isError);

  if (isPending) {
    return <div>Loading</div>;
  }

  if (isError) {
    return <div>error</div>;
  }

  const projectsById = new Map<number, Project>();
  records.forEach((r) => {
    if (r.data !== undefined) {
      projectsById.set(r.data.id, r.data);
    }
  });

  const visibleHolidays = (props.holidays ?? [])
    .map((h) => {
      const weekDate = props.ws.date;
      const holidayDate = h.date;

      if (dateWithinWeek(holidayDate, weekDate)) {
        return h;
      }
    })
    .filter((a) => a !== undefined) as Array<Holiday>;

  const visibleAbsences = (props.absences ?? [])
    .map((a) => {
      const weekDate = props.ws.date;
      const absenceDate = a.date;

      if (dateWithinWeek(absenceDate, weekDate)) {
        return a;
      }
    })
    .filter((a) => a !== undefined);

  function hoursForProjects(projectIds: Array<number>): number {
    const amounts = projectIds
      .map((projectId) => {
        const project = projectsById.get(projectId);

        if (project === undefined) {
          return 0;
        }

        return ws.projects.get(project.id) ?? 0;
      })
      .reduce((a, amt) => a + amt, 0);

    return amounts;
  }

  return (
    <>
      <ScheduleSection date={props.ws.date} schedules={props.schedules} />
      {[...projectsGroupedByKind.keys()].map((kind) => {
        const projectIds = projectsGroupedByKind.get(kind);

        if (projectIds === undefined) {
          return <div key={kind}> no projects </div>;
        }

        const amount = hoursForProjects(projectIds);

        return (
          <div key={kind}>
            {BookingKindTitle(kind, amount)}
            <ul>
              {projectIds.map((projectId) => {
                const project = projectsById.get(projectId);
                if (project) {
                  const projectHours = ws.projects.get(project.id);
                  return (
                    <li key={project.id}>
                      {project.name}: {projectHours}h
                    </li>
                  );
                } else {
                  return <div key={"noprojs"}>No project</div>;
                }
              })}
            </ul>
          </div>
        );
      })}
      <HolidaySection hours={holidayHours} holidays={visibleHolidays} />
      <AbsenceSection hours={absenceHours} absences={visibleAbsences} />
    </>
  );
}

function ScheduleSection(props: {
  date: Date;
  schedules?: Array<UserWorkDaySchedule>;
}) {
  if (props.schedules === undefined) {
    return <div>user has no schedule</div>;
  }

  const schedules = props.schedules;
  const hours = hoursForWeek(props.date, schedules);
  return (
    <div>
      <b>Schedule:</b> {hours}h
    </div>
  );
}

function AbsenceSection(props: { absences: Array<Absence>; hours: number }) {
  if (props.hours === 0) {
    return <></>;
  }

  return (
    <div className="flex flex-col">
      <b>Absences ({props.hours} hours)</b>
      <ul>
        {props.absences.map((a) => {
          const dt = formatFriendlyDate(a.date);
          return <li key={dt}>{dt}</li>;
        })}
      </ul>
    </div>
  );
}
function HolidaySection(props: { holidays: Array<Holiday>; hours: number }) {
  if (props.hours === 0) {
    return <></>;
  }

  return (
    <div className="flex flex-col">
      <b>Holidays ({props.hours} hours)</b>
      <ul>
        {props.holidays.map((h) => {
          const dt = formatFriendlyDate(h?.date);
          return (
            <li key={h.name}>
              {h.name} - {dt}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function BookingKindTitle(title: string, amount: number) {
  let color = "#000";
  let text = "Error";
  if (title === "reserved") {
    color = reservedColor;
    text = "Reserved";
  }

  if (title === "sales") {
    color = salesColor;
    text = "Presales";
  }

  if (title === "booked") {
    color = bookingColor;
    text = "Booked";
  }

  return (
    <div>
      <span style={{ color: color, fontWeight: "bold" }}>{text}:</span> {amount}
      h
    </div>
  );
}

interface BookingPopoverProps extends React.PropsWithChildren {
  ws: WeekSummary;
  schedules?: Array<UserWorkDaySchedule>;
  absences?: Array<Absence>;
  holidays?: Array<Holiday>;
}

function BookingPopoverTitle(props: BookingPopoverProps) {
  let weekName = formatFriendlyDate(props.ws.date);
  let weekNum = date2weekNum(props.ws.date);
  let hours = props.ws.amount;
  return (
    <div className="flex justify-between">
      <span>Week of {weekName}</span>
      <span>
        {hours}h [CW{weekNum}]
      </span>
    </div>
  );
}
export function BookingPopover(props: BookingPopoverProps) {
  return (
    <Popover
      content={<BookingPopoverInner {...props} />}
      title={<BookingPopoverTitle {...props} />}
      trigger="click"
    >
      <>{props.children}</>
    </Popover>
  );
}
