import { useSearch } from "@/Providers/SearchProvider";
import {
  Baby,
  BookUser,
  Building2,
  FolderHeart,
  FolderKanban,
  Group,
  House,
  LayoutDashboardIcon,
  LucideProps,
  MapPin,
  Pickaxe,
  Search as SearchIcon,
  Sigma,
  Smile,
  Zap,
} from "lucide-react";
import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { MyInfoResponse } from "../../models";
import { useAuth } from "../../Providers/AuthProvider";
import {
  canViewLocations,
  canViewTenants,
  canViewWorkbook,
  isAdmin,
} from "../../Providers/permissions";
import { date2weekNum } from "../../utils";

type MenuItem = {
  label: string;
  path: string;
  icon: React.ComponentType;
};

function buildNav(info: MyInfoResponse): MenuItem[] {
  const roles = info.roles;
  const team = info.team;
  const reports = info.reports;

  const items = [] as MenuItem[];

  function addItem(label: string, path: string, icon: React.ComponentType) {
    const item = {
      label: label,
      path: path,
      icon: icon,
    } as MenuItem;

    items.push(item);
  }

  addItem("Dashboard", "/", LayoutDashboardIcon);

  if (isAdmin(roles)) {
    addItem("Users", "/users", BookUser);
    addItem("Clients", "/clients", Building2);
    addItem("Projects", "/projects", FolderKanban);
    addItem("Teams", "/teams", Group);
  }

  if (canViewLocations(roles)) {
    addItem("Locations", "/locations", MapPin);
  }
  if (canViewTenants(roles)) {
    addItem("Tenants", "/tenants", House);
  }
  addItem("Skills", "/skills", Pickaxe);

  addItem("My Projects", "/my-projects", FolderHeart);

  if (team !== undefined && team !== null) {
    addItem("My Team", `/teams/${team.id}`, Smile);
  }

  if (reports === true) {
    addItem("My Direct Reports", `/direct-reports`, Baby);
  }

  addItem("Request Staffing", "/project-user-requests", Zap);

  if (canViewWorkbook(roles)) {
    const today = new Date();
    const weekNo = date2weekNum(today);
    addItem("Workbook", `/workbook/${today.getFullYear()}/${weekNo}`, Sigma);
  }

  return items;
}
export function Navigation() {
  let { info } = useAuth();
  const items = useMemo(() => buildNav(info), [info]);
  return <RenderMenu items={items} />;
}

function RenderMenuItem(props: { item: MenuItem }) {
  const i = props.item;
  const Icon = i.icon as React.ComponentType<LucideProps>;

  return (
    <div className="relative pl-2.5 py-1 hover:bg-gray-300 rounded-full hover:shadow w-full text-gray-900">
      <NavLink to={i.path} className={navClass}>
        <div className="flex items-center flex-row h-5">
          <div className="w-7">
            <Icon size={16} />
          </div>
          <div className="text-gray-800">{i.label}</div>
        </div>
      </NavLink>
    </div>
  );
}

function SearchEntry() {
  const { open } = useSearch();

  return (
    <div className="relative pl-2.5 py-1 hover:bg-gray-300 rounded-full hover:shadow w-full text-gray-900">
      <div>
        <div
          className="cursor-pointer flex items-center flex-row h-5"
          onClick={() => open!()}
        >
          <div className="w-7">
            <SearchIcon size={16} />
          </div>
          <div className="text-gray-800">Search</div>
        </div>
      </div>
    </div>
  );
}

function RenderMenu(props: { items: MenuItem[] }) {
  return (
    <div className="flex flex-col font-sans font-normal select-none ">
      <SearchEntry />
      {props.items.map((i) => {
        return <RenderMenuItem key={i.path} item={i} />;
      })}
    </div>
  );
}

function navClass({ isActive }: { isActive: boolean }) {
  return isActive ? "font-bold text-blue-600" : "text-gray-400";
}
