import { Button, Result } from "../Antd";
import { Link } from "react-router-dom";

export function InternalError() {
  return (
    <Result
      status="500"
      title="Internal Error"
      subTitle="Something failed, dylan is on it."
      extra={
        <Link to="/">
          <Button type="primary">Back Home</Button>
        </Link>
      }
    />
  );
}
