import { User } from "./user";

export interface Skill {
  id: number;
  label: string;
  name: string;
  color: string;
  skill_category_id: number;
}

export interface NewSkill extends Omit<Skill, "id"> {}

export interface UserSkill {
  id: number;
  user_id: number;
  skill_id: number;
  level: number;
}

export interface SkillCategory {
  id: number;
  name: string;
}

export interface SkillsWithCategories {
  skills: Array<Skill>;
  categories: Array<SkillCategory>;
}

export interface SkillWithUsersAndSkills {
  skill: Skill;
  users_with_user_skills: Array<UserWithUserSkill>;
}

export interface UserWithUserSkill {
  user: User;
  user_skill: UserSkill;
}

export enum SkillLevel {
  None = 0,
  Basic = 1,
  Intermediate = 2,
  Advanced = 3,
  Pro = 4,
  Evangelist = 5,
}
