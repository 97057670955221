import { ProjectPlannerContent } from "@/components/Planner/Content";
import {
  PlannerRoleName,
  ProjectPlan,
  RoleAddHandler,
} from "@/components/Planner/types";
import { buildProjectPlan, buildRoles } from "@/components/Planner/util";
import { ProjectHeader } from "@/components/Projects/Header";
import { Button } from "antd";
import { useState } from "react";
import { useParams } from "react-router";
import { useAuth } from "../../../Providers/AuthProvider";
import { isProjectManager } from "../../../Providers/permissions";
import { MutateStatus } from "../../../components/Generic/MutateStatus";
import { Forbidden } from "../../../components/Status/Forbidden";
import { InternalError } from "../../../components/Status/InternalError";
import { Loading } from "../../../components/Status/Loading";
import {
  Project,
  ProjectPlanRecord,
  ProjectPlanSummary,
} from "../../../models";
import { monthsBetweenDates } from "../../../utils";
import { AddRoleButton } from "./AddRole";
import { HelpButton } from "./Help";
import { PlannerOverview } from "./Overview";

export function ProjectPlannerPage() {
  const { api, user, roles } = useAuth();
  const { projectId } = useParams();

  if (projectId === undefined) {
    return <div>error</div>;
  }

  const fetchProject = api.fetchProjectById(projectId);
  const fetchProjectPlan = api.fetchProjectPlanByProjectId(projectId);

  if (fetchProject.isPending || fetchProjectPlan.isPending) {
    return <Loading />;
  }

  if (fetchProject.isError || fetchProjectPlan.isError) {
    return <InternalError />;
  }

  const myUser = fetchProject.data.project_users_with_users.find(
    (pu) => pu.user.id === user.id,
  );

  const myProjectUser = myUser ? myUser.project_user : undefined;

  const isPM = isProjectManager(
    roles,
    fetchProject.data.project,
    myProjectUser,
  );

  if (!isPM) {
    return <Forbidden />;
  }

  const project = fetchProject.data.project;

  if (project.start_date === null) {
    return <div> project has no start date </div>;
  }

  if (project.end_date === null) {
    return <div> project has no end date </div>;
  }

  return (
    <ProjectPlannerPageContent
      project={fetchProject.data.project}
      summary={fetchProjectPlan.data}
    />
  );
}

function ProjectPlannerPageContent(props: {
  project: Project;
  summary: ProjectPlanSummary;
}) {
  const { api } = useAuth();
  const mut = api.setProjectPlan();

  const project = props.project;
  const months = monthsBetweenDates(project.start_date, project.end_date);

  const [roles, setRoles] = useState<PlannerRoleName[]>(
    buildRoles(props.summary.records),
  );

  const [projectPlan, setProjectPlan] = useState<ProjectPlan>(
    buildProjectPlan(
      months.map((x) => x.date),
      props.summary.records ?? [],
    ),
  );

  const removeRole = (role: PlannerRoleName) => {
    setProjectPlan((prev) => {
      const newM = new Map(prev);
      newM.delete(role);
      return newM;
    });

    setRoles((prev) => prev.filter((r) => r !== role));
  };

  const onRoleChange = (
    role: PlannerRoleName,
    month: string,
    amount: number,
  ) => {
    setProjectPlan((prev) => {
      const newM = new Map(prev);
      const roleM = new Map(newM.get(role));
      const monthM = new Map(roleM.get(project.id));

      monthM.set(month, amount);
      roleM.set(project.id, monthM);
      newM.set(role, roleM);
      return newM;
    });
  };

  const onSave = () => {
    const records: ProjectPlanRecord[] = [];

    for (const [role, projectMap] of projectPlan.entries()) {
      for (const [projectId, monthMap] of projectMap.entries()) {
        for (const [month, amount] of monthMap.entries()) {
          if (amount === 0) {
            continue;
          }

          const r = {
            role_id: role,
            role: role,
            month: month,
            amount: amount,
            project_id: projectId,
          };

          records.push(r);
        }
      }
    }

    mut.mutate({
      projectId: project.id.toString(),
      records: records,
    });
  };

  const onAddRole = (role: PlannerRoleName) => {
    setRoles((prev) => [...prev, role]);
  };

  return (
    <div className="flex flex-col gap-4">
      <ProjectHeader projectId={project.id.toString()}>
        <PlannerButtons roles={roles} onAddRole={onAddRole} />
      </ProjectHeader>
      <PlannerOverview
        project={project}
        months={months.map((m) => m.date)}
        plan={projectPlan}
        planSummary={props.summary}
      />
      <PlannerNoRoleInfo projectPlan={projectPlan} />
      <ProjectPlannerContent
        single={true}
        projectPlan={projectPlan}
        onRoleChange={onRoleChange}
        onRoleRemove={removeRole}
        months={months}
        roles={roles}
        summary={props.summary}
        editable={true}
      />
      <div className="flex flex-row justify-end pt-4 items-center gap-4">
        <div className="relative">
          <MutateStatus mutation={mut} />
        </div>
        <Button type="primary" onClick={onSave}>
          Save
        </Button>
      </div>
    </div>
  );
}

function PlannerButtons(props: {
  onAddRole: RoleAddHandler;
  roles: PlannerRoleName[];
}) {
  const roles = props.roles;
  const onAddRole = props.onAddRole;

  return (
    <>
      <AddRoleButton roles={roles} onAddRole={onAddRole} />
      <HelpButton />
    </>
  );
}

function PlannerNoRoleInfo(props: { projectPlan: ProjectPlan }) {
  const numRoles = props.projectPlan.size;

  if (numRoles > 0) {
    return null;
  }

  return (
    <div className="py-4 pb-0">
      You have no resources in your project plan. Add some to get started.
    </div>
  );
}
