import type { DropdownProps, MenuProps } from "antd";
import { Button, Checkbox, Dropdown, Menu, Space } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { useState } from "react";
import { BookingKind } from "../../../src/models/booking";
import { capitalize } from "../../utils";

interface KindSelectorProps {
  selected: BookingKind[];
  onChange: (selectedKinds: BookingKind[]) => void;
}

const allBookingKinds = [
  BookingKind.Sales,
  BookingKind.Booked,
  BookingKind.Reserved,
];

export function KindSelector(props: KindSelectorProps) {
  let visibleKinds = props.selected;
  const [open, setOpen] = useState(false);

  if (visibleKinds.length === 0) {
    visibleKinds = allBookingKinds;
  }

  const items: MenuProps["items"] = [
    {
      key: BookingKind.Booked,
    },
    {
      key: BookingKind.Sales,
    },
    {
      key: BookingKind.Reserved,
    },
  ];

  function onRemove(kind: BookingKind) {
    const filteredKinds = visibleKinds.filter((a) => a !== kind);
    emitKinds(filteredKinds);
  }

  function emitKinds(selection: BookingKind[]) {
    props.onChange(selection);
  }

  function onCheckboxChange(kind: BookingKind, e: CheckboxChangeEvent) {
    if (!visibleKinds.includes(kind) && e.target.checked) {
      emitKinds([...visibleKinds, kind]);
    }
    if (visibleKinds.includes(kind) && !e.target.checked) {
      onRemove(kind);
    }
  }

  const handleOpenChange: DropdownProps["onOpenChange"] = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setOpen(nextOpen);
    }
  };

  return (
    <Space direction="vertical">
      <Space wrap>
        <Dropdown
          onOpenChange={handleOpenChange}
          open={open}
          placement="bottom"
          dropdownRender={() => (
            <Menu>
              {items.map((item) => {
                if (item === null) {
                  return;
                }
                return (
                  <Menu.Item key={item.key}>
                    <Checkbox
                      checked={visibleKinds.includes(item.key as BookingKind)}
                      onChange={(e) =>
                        onCheckboxChange(item.key as BookingKind, e)
                      }
                    >
                      {capitalize(item.key as string)}
                    </Checkbox>
                  </Menu.Item>
                );
              })}
            </Menu>
          )}
        >
          <Button size="small" onClick={(e) => e.preventDefault()}>
            Booking Kind
          </Button>
        </Dropdown>
      </Space>
    </Space>
  );
}
