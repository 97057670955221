import { useSearch } from "@/Providers/SearchProvider";
import { debounce } from "lodash-es";
import { X } from "lucide-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Link } from "react-router-dom";
import { Button, Input, InputRef } from "../../components/Antd";
import { Project, SearchResults, Skill, User } from "../../models";
import { useAuth } from "../../Providers/AuthProvider";
import {
  sortProjectsByName,
  sortSkillsByName,
  sortUsersByGivenName,
  userName,
} from "../../utils";

interface WithID {
  id: string | number;
}

interface SearchResultProps<T> {
  record: T;
  clear: () => void;
}

export function Search() {
  return createPortal(<SearchComponent />, document.body);
}

export function SearchComponent() {
  const { api } = useAuth();
  const { visible, close } = useSearch();
  const [query, setQuery] = useState("");

  const debounceTimeout = 100;
  const [_fetching, setFetching] = useState(false);
  const [focus, setFocus] = useState(false);
  const [results, setResults] = useState({});
  const fetchRef = useRef(0);
  const searchRef = useRef<InputRef>(null);

  const clearSearch = () => {
    //setQuery("");
    //setResults({});
    close!();
  };

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current!.focus({
        cursor: "end",
      });
    }
  }, [visible]);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      //setResults({});
      setFetching(true);

      api.search(value).then((newResults) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setResults(newResults);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [api.search, debounceTimeout]);

  if (!visible) {
    return null;
  }

  const resultSize = totalResultSize(results);
  const handleClickOutside = (e: React.MouseEvent) => {
    // Only trigger if clicked element is the parent directly
    if (e.currentTarget === e.target) {
      close!();
    }
  };

  // we blur after 150ms so that the user can click the item before the search results are hidden.
  return (
    <div
      className="fixed inset-0 z-10 flex h-screen w-screen items-center justify-center"
      onClick={handleClickOutside}
    >
      <EscapeKeyHandler onEscape={() => close!()} />
      <div className="flex min-w-[70%] items-start justify-start min-h-[412px]">
        <div className="flex flex-col border w-full rounded-lg p-4 gap-4 shadow-2xl bg-white">
          <div className="text-3xl font-bold flex justify-between items-start">
            <span>
              Search {resultSize > 0 ? <span>{resultSize} results</span> : null}
            </span>
            <Button
              type="primary"
              size="small"
              className="p-0"
              onClick={() => close!()}
            >
              <X />
            </Button>
          </div>
          <div className="flex flex-col max-h-96 gap-4">
            <Input
              className="flex w-[100%]"
              value={query}
              ref={searchRef}
              onFocus={() => setFocus(true)}
              //onBlur={() => setTimeout(() => setFocus(false), 150)}
              onInput={(v: React.ChangeEvent<HTMLInputElement>) => {
                setQuery(v.target.value);
                debounceFetcher(v.target.value);
              }}
            />
            {Object.keys(results).length > 0 && focus && (
              <div className="py-3 overflow-y-auto">
                <ShowSearchResults
                  query={query}
                  results={results}
                  clear={clearSearch}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function totalResultSize(results: SearchResults) {
  const projects = results.projects ?? [];
  const users = results.users ?? [];
  const skills = results.skills ?? [];
  return projects.length + users.length + skills.length;
}

function ShowSearchResults(props: {
  query: string;
  results: SearchResults;
  clear: () => void;
}) {
  const results = props.results;
  const projects = results.projects ?? [];
  const users = results.users ?? [];
  const skills = results.skills ?? [];
  const resultSize = totalResultSize(results);

  if (resultSize === 0) {
    return <div>No Results</div>;
  }

  return (
    <div className="flex flex-col gap-3">
      <UserSearchResults users={users} clear={props.clear} />
      <ProjectSearchResults projects={projects} clear={props.clear} />
      <SkillSearchResults skills={skills} clear={props.clear} />
    </div>
  );
}

function SearchResult<T extends WithID>(props: {
  name: string;
  records: T[];
  clear: () => void;
  renderer: React.ComponentType<SearchResultProps<T>>;
}) {
  const SearchResultItem = props.renderer;

  return (
    <div className="flex flex-col gap-1">
      <div className="font-semibold">{props.name}</div>
      <div>
        {props.records.map((r) => (
          <SearchResultItem key={r.id} record={r} clear={props.clear} />
        ))}
      </div>
    </div>
  );
}

function ProjectItemRender(props: { record: Project; clear: () => void }) {
  const r = props.record;

  return (
    <div className="flex justify-between gap-2 text-sm" key={`project:${r.id}`}>
      <Link
        to={`/projects/${r.id}`}
        onClick={props.clear}
        className="truncate hover:underline font-normal"
      >
        {r.name}
      </Link>
      <div className="text-right w-28">{r.status}</div>
    </div>
  );
}

function ProjectSearchResults(props: {
  projects: Project[];
  clear: () => void;
}) {
  const projects = props.projects;

  if (projects.length === 0) {
    return null;
  }

  const sortedProjects = sortProjectsByName(projects);

  return (
    <SearchResult<Project>
      records={sortedProjects}
      name="Projects"
      clear={props.clear}
      renderer={ProjectItemRender}
    />
  );
}

function SkillItemRender(props: { record: Skill; clear: () => void }) {
  const r = props.record;

  return (
    <div className="flex justify-between gap-2 text-sm" key={`skill:${r.id}`}>
      <Link
        to={`/skills/${r.id}`}
        onClick={props.clear}
        className="truncate hover:underline font-normal"
      >
        {r.name}
      </Link>
    </div>
  );
}

function SkillSearchResults(props: { skills: Skill[]; clear: () => void }) {
  const skills = props.skills;

  if (skills.length === 0) {
    return;
  }

  const sortedSkills = sortSkillsByName(skills);

  return (
    <SearchResult<Skill>
      records={sortedSkills}
      name="Skills"
      clear={props.clear}
      renderer={SkillItemRender}
    />
  );
}

function UserItemRender(props: { record: User; clear: () => void }) {
  const r = props.record;
  return (
    <div className="flex justify-between gap-2 text-sm" key={`user:${r.id}`}>
      <div>
        <Link
          to={`/users/${r.id}`}
          onClick={props.clear}
          className="truncate hover:underline font-normal"
        >
          {userName(r)}
        </Link>
      </div>
      <div>{r.title ?? "no title defined"}</div>
    </div>
  );
}

function UserSearchResults(props: { users: User[]; clear: () => void }) {
  const users = props.users;

  if (users.length === 0) {
    return;
  }

  const sortedUsers = sortUsersByGivenName(users);

  return (
    <SearchResult<User>
      records={sortedUsers}
      name="Users"
      clear={props.clear}
      renderer={UserItemRender}
    />
  );
}

interface EscapeKeyHandlerProps {
  onEscape: () => void;
}

const EscapeKeyHandler = (props: EscapeKeyHandlerProps) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        props.onEscape();
      }
    };

    document.body.addEventListener("keydown", handleKeyDown);

    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, [props.onEscape]);

  return null;
};
