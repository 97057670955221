import { ProjectRole, TimeEntry } from "@/models";

export interface StatSummary {
  estimate: number;
  used: number;
  percent: string;
  over_budget: boolean;
  at_risk: boolean;
}

export function calcStats(estimate: number, time_entries: Array<TimeEntry>) {
  const hours = time_entries.reduce((sum, te) => sum + te.hours, 0);

  const percent = hours / estimate;
  const percentStr = percent.toFixed(2);
  const atRisk = percent > 0.8;
  const overBudget = hours > estimate;

  return {
    estimate: estimate,
    used: hours,
    percent: percentStr,
    over_budget: overBudget,
    at_risk: atRisk,
  };
}

export function calcEstimatedHours(project_roles: Array<ProjectRole>) {
  return project_roles.reduce((sum, pr) => sum + pr.budget_hours, 0);
}

export interface ProjectHourSummary {
  totalHours: number;
  totalBillable: number;
  totalNonBillable: number;
}

export function sumTotalHours(
  time_entries: Array<TimeEntry>,
): ProjectHourSummary {
  let hours = 0.0;
  let billable = 0;
  let nonBillable = 0;

  time_entries.forEach((t) => {
    const h = t.hours;
    billable += h;
    hours += h;
  });

  return {
    totalHours: hours,
    totalBillable: billable,
    totalNonBillable: nonBillable,
  };
}
