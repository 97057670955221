import { useParams } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";
import { canViewLocations } from "../../Providers/permissions";
import { Card } from "../../components/Antd";
import { Forbidden } from "../../components/Status/Forbidden";
import { Loading } from "../../components/Status/Loading";
import { MainTitle } from "../../components/Typography";
import type { Holiday } from "../../models";
import { dateRangeToQueryParam, endOfYear, startOfYear } from "../../utils";

export function LocationShow() {
  const { api, roles } = useAuth();
  const { locationId } = useParams();

  if (!canViewLocations(roles)) {
    return <Forbidden />;
  }

  if (locationId === undefined) {
    return <div>error</div>;
  }

  const today = new Date();
  const start = startOfYear(today).toDate();
  const end = endOfYear(today).toDate();
  const params = dateRangeToQueryParam(start, end);
  const fetchLocation = api.fetchLocationById(locationId);
  const fetchHolidays = api.fetchHolidaysByLocationId(
    Number(locationId),
    params,
  );

  if (fetchLocation.isPending || fetchHolidays.isPending) {
    return <Loading />;
  }

  if (fetchLocation.isError || fetchHolidays.isError) {
    return <div>error</div>;
  }

  const location = fetchLocation.data;
  const holidays = fetchHolidays.data;

  return (
    <div>
      <MainTitle text={location.name} />
      <Card size="small" title="Staffing Overview">
        <Holidays holidays={holidays} />
      </Card>
    </div>
  );
}

function Holidays(props: { holidays: Array<Holiday> }) {
  if (props.holidays.length === 0) {
    return <div>No holidays defined</div>;
  }
  return (
    <ul>
      {props.holidays.map((h) => (
        <li key={h.id}>
          {h.date} - {h.name}
        </li>
      ))}
    </ul>
  );
}
