import { Button, Result } from "../Antd";
import { Link } from "react-router-dom";

export function NotFound() {
  return (
    <Result
      status="404"
      title="Not Found"
      subTitle="Could not find what you were looking for!"
      extra={
        <Link to="/">
          <Button type="primary">Back Home</Button>
        </Link>
      }
    />
  );
}
