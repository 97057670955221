import { User } from "../../models";
import { buildDefaultTimeline, dateSpanToQueryParam } from "../../utils";
import { useAuth } from "../../Providers/AuthProvider";
import { Loading } from "../../components/Status/Loading";
import { Card, Checkbox, Space } from "antd";
import { ProjectList } from "../../components/Users/ProjectList";
import { ColorLegend } from "../../components/Generic/ColorLegend";
import { useState } from "react";

interface UserStaffingProps {
  user: User;
  showAllBookings?: boolean;
}

interface ToggleAllBookingsProps {
  setShowAllBookings: (v: boolean) => void;
  showAllBookings: boolean;
}

function ToggleAllBookings(props: ToggleAllBookingsProps) {
  const onChange = () => {
    props.setShowAllBookings(!props.showAllBookings);
    console.log("changing", props.showAllBookings);
  };

  return (
    <div>
      <Checkbox onChange={onChange} defaultChecked={props.showAllBookings}>
        <b>Show projects without staffing</b>
      </Checkbox>
    </div>
  );
}

export function UserStaffing(props: UserStaffingProps) {
  const { api } = useAuth();
  const user = props.user;
  const timeline = buildDefaultTimeline();
  const params = dateSpanToQueryParam(timeline.view);
  const userId = user.id.toString();

  const [showAllBookings, setShowAllBookings] = useState<boolean>(
    props.showAllBookings ?? false,
  );
  const fetchProjectData = api.fetchProjectUsersByUserId(userId);
  const fetchBookingData = api.fetchProjectBookingsByUserId(userId, params);
  const fetchAbsenceData = api.fetchAbsencesByUserId(userId, params);
  const fetchScheduleData = api.fetchUserWorkDayScheduleByUserId(userId);

  const fetchHolidayData = api.fetchHolidaysByLocationId(
    user.location_id ?? 0,
    params,
  );

  if (
    fetchProjectData.isPending ||
    fetchProjectData.isError ||
    fetchBookingData.isPending ||
    fetchBookingData.isError ||
    fetchAbsenceData.isPending ||
    fetchAbsenceData.isError ||
    fetchHolidayData.isPending ||
    fetchScheduleData.isPending ||
    fetchScheduleData.isError
  ) {
    return <Loading />;
  }

  return (
    <Space direction="vertical" size="middle" style={{ display: "flex" }}>
      <Card
        size="small"
        title="Staffing Overview"
        extra={
          <ToggleAllBookings
            setShowAllBookings={setShowAllBookings}
            showAllBookings={showAllBookings}
          />
        }
      >
        <ProjectList
          width="300px"
          timeline={timeline}
          user={user}
          projectAndProjectUsers={fetchProjectData.data}
          holidays={fetchHolidayData.data ?? []}
          bookings={fetchBookingData.data}
          absences={fetchAbsenceData.data}
          showProjectsWithoutBookings={showAllBookings}
          schedules={fetchScheduleData.data}
        />
        <ColorLegend />
      </Card>
    </Space>
  );
}
