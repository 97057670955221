import { Button, Modal } from "antd";
import { useState } from "react";

export function HelpButton() {
  const [modal2Open, setModal2Open] = useState(false);
  return (
    <>
      <Button type="primary" onClick={() => setModal2Open(true)}>
        Help
      </Button>
      <Modal
        title="Project Planner Help"
        centered
        open={modal2Open}
        onCancel={() => setModal2Open(false)}
        footer={null}
      >
        <div>TBD</div>
      </Modal>
    </>
  );
}
