import { Button, Modal } from "antd";
import { useState } from "react";
import {
  PlannerRoleName,
  Role,
  RoleAddHandler,
} from "@/components/Planner/types";
import { allRoles } from "@/components/Planner/util";

export function AddRoleButton(props: {
  roles: PlannerRoleName[];
  onAddRole: RoleAddHandler;
}) {
  const [modal2Open, setModal2Open] = useState(false);

  const setRoleAndClose = (role: string) => {
    props.onAddRole(role);
    setModal2Open(false);
  };

  const visibleRoles = allRoles
    .filter((r) => !props.roles.some((x) => x === r.value))
    .sort((a: Role, b: Role) => (a.value < b.value ? -1 : 1));

  return (
    <>
      <Button
        type="primary"
        onClick={() => setModal2Open(true)}
        disabled={visibleRoles.length === 0}
      >
        Add Role
      </Button>
      <Modal
        title="Select a role to add to your project plan"
        centered
        open={modal2Open}
        onCancel={() => setModal2Open(false)}
        footer={null}
      >
        <div>
          {visibleRoles.map((r) => {
            return (
              <p
                className="hover:font-bold cursor-pointer"
                key={r.value}
                onClick={() => setRoleAndClose(r.value)}
              >
                {r.label}
              </p>
            );
          })}
        </div>
      </Modal>
    </>
  );
}
