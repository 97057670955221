import toast from "react-hot-toast";
import {
  Alert,
  Card,
  Col,
  Collapse,
  Divider,
  Layout,
  Row,
  Select,
  Space,
} from "../../components/Antd";
import { Loading } from "../../components/Status/Loading";
import { MainTitle } from "../../components/Typography";
import { Skill, UserSkill } from "../../models";
import { useAuth } from "../../Providers/AuthProvider";
import { sortSkillsByName } from "../../utils";

const { Panel } = Collapse;

function buildSkillMap(userSkills: Array<UserSkill>): Map<number, number> {
  const m = new Map<number, number>();
  userSkills.forEach((us) => {
    m.set(us.skill_id, us.level);
  });
  return m;
}

function groupSkillsByCategory(
  skills: Array<Skill>,
): Map<number, Array<Skill>> {
  const m = new Map<number, Array<Skill>>();

  skills.forEach((s) => {
    const cid = s.skill_category_id;
    let skills = m.get(cid);
    if (skills === undefined) {
      skills = new Array<Skill>();
    }

    skills.push(s);
    m.set(cid, skills);
  });

  return m;
}

export function SkillSelection() {
  const { api, user } = useAuth();

  if (user === undefined) {
    return <>no user</>; // todo: rewrite useAuth to return a user, always:w
  }

  const fetchSkills = api.fetchSkills();
  const fetchUserSkills = api.fetchUserSkillsByUserId(user.id.toString());

  if (fetchSkills.isPending || fetchUserSkills.isPending) {
    return <Loading />;
  }

  if (fetchSkills.isError || fetchUserSkills.isError) {
    return <div>error</div>;
  }

  const skills = fetchSkills.data.skills;
  const categories = fetchSkills.data.categories;
  const userSkills = fetchUserSkills.data;
  const mappedUserSkills = buildSkillMap(userSkills);
  const skillsByCategory = groupSkillsByCategory(skills);

  return (
    <Layout>
      <MainTitle text="Select Skills" />
      <Divider />
      <Space direction="vertical" style={{ width: "100%" }}>
        <Alert
          message="A note about skills"
          description="This is not an exhaustive list of skills, we will add a more complete list after go live. For now this is more of a proof of concept, feel free to play with it"
          type="info"
          showIcon
        />
        <br />
      </Space>
      <Card size="small">
        <Collapse defaultActiveKey={[]}>
          {categories.map((c) => {
            const skills = skillsByCategory.get(c.id);

            if (skills === undefined || skills.length === 0) {
              return;
            }

            return (
              <Panel header={c.name} key={c.id}>
                {sortSkillsByName(skills).map((s) => {
                  const defaultValue = mappedUserSkills.get(s.id) ?? 0;
                  return (
                    <SkillSlider key={s.id} skill={s} value={defaultValue} />
                  );
                })}
              </Panel>
            );
          })}
        </Collapse>
      </Card>
    </Layout>
  );
}

interface SkillSliderProps {
  skill: Skill;
  value: number;
}

function SkillSlider(props: SkillSliderProps) {
  const skill = props.skill;

  const { api, user } = useAuth();
  const mut = api.upsertUserSkill();

  if (mut.isPending) {
    console.log("loading");
  } else if (mut.isError) {
    console.log("error");
    toast("error: There was an error updating the user");
  } else if (mut.isSuccess) {
    toast("success: User updated!");
  }

  function onChange(value: number) {
    console.log(skill.name, value);

    const us = {
      user_id: user?.id,
      skill_id: skill.id,
      level: value,
    } as UserSkill;

    mut.mutate(us);
  }

  return (
    <>
      <Row justify="center" align="middle">
        <Col span={12}>
          {props.value > 0 ? <b>{skill.name}</b> : <span>{skill.name}</span>}
        </Col>
        <Col span={12}>
          <Select
            style={{ width: "300px" }}
            onChange={onChange}
            defaultValue={props.value}
          >
            <Select.Option value={0}>None</Select.Option>
            <Select.Option value={1}>Basic</Select.Option>
            <Select.Option value={2}>Intermediate</Select.Option>
            <Select.Option value={3}>Advanced</Select.Option>
            <Select.Option value={4}>Pro</Select.Option>
            <Select.Option value={5}>Master</Select.Option>
          </Select>
        </Col>
      </Row>
      <hr />
    </>
  );
}
