import { Client, ClientId } from "../models";
import { Link } from "react-router-dom";

interface LinkToClientProps {
  id?: ClientId;
  name?: string;
  client?: Client;
}

export function LinkToClient(props: LinkToClientProps) {
  const client = props.client;
  if (client) {
    return <Link to={`/clients/${client.id}`}>{client.name}</Link>;
  }

  return <Link to={`/clients/${props.id}`}>{props.name}</Link>;
}
