import { Users } from "lucide-react";

export function Logo() {
  return (
    <div className="select-none text-lg font-sans text-neutral-600 h-16 flex items-center font-bold text-center align-middle">
      <div className="p-2 bg-gradient-to-br from-blue-400 to-blue-700 rounded-xl mr-2">
        <Users className="h-5 w-5 text-blue-200" />
      </div>
      Staffing Tool
    </div>
  );
}
