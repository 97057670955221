import { LocationRenderer } from "@/components/Table/Renderers/LocationRenderer";
import { Column, Table } from "@/components/Table/Table";
import { useAuth } from "../../Providers/AuthProvider";
import { canViewLocations } from "../../Providers/permissions";
import { Forbidden } from "../../components/Status/Forbidden";
import { Loading } from "../../components/Status/Loading";
import { MainTitle } from "../../components/Typography";
import { Location } from "../../models";
import { sortLocationsByName } from "../../utils";

export function LocationIndex() {
  const { api, roles } = useAuth();

  if (!canViewLocations(roles)) {
    return <Forbidden />;
  }

  const fetchLocations = api.fetchLocations();

  if (fetchLocations.isPending) {
    return <Loading />;
  }

  if (fetchLocations.isError) {
    return <div>error</div>;
  }

  const locations = sortLocationsByName(fetchLocations.data);

  return (
    <div>
      <MainTitle text="Locations" />
      <LocationTable locations={locations} />
    </div>
  );
}

const columns: Column<Location>[] = [
  {
    field: "name",
    flex: 1,
    cellRenderer: LocationRenderer,
  },
];

function LocationTable(props: { locations: Array<Location> }) {
  return <Table data={props.locations} columns={columns} autoHeight={true} />;
}
