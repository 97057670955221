import { Card, Divider } from "../../components/Antd";
import { Loading } from "../../components/Status/Loading";
import { MainTitle } from "../../components/Typography";
import { useAuth } from "../../Providers/AuthProvider";
import {
  formatFriendlyDate,
  groupProjectsById,
  sortProjectTrackingSummariesByDate,
} from "../../utils";
import { LinkToProject } from "../../utils/project";

export function ProjectInactive() {
  const { api } = useAuth();
  const fetchInactiveProjects = api.fetchInactiveProjectTrackingSummaries();
  let projectIds = [] as Array<number>;
  if (fetchInactiveProjects.isSuccess) {
    projectIds = fetchInactiveProjects.data.map((x) => x.project_id);
  }

  const fetchProjects = api.fetchProjectsByIds(
    projectIds.map((x) => x.toString()),
  );

  if (
    fetchInactiveProjects.isPending ||
    fetchProjects.some((x) => x.isPending)
  ) {
    return <Loading />;
  }

  if (fetchInactiveProjects.isError || fetchProjects.some((x) => x.isError)) {
    return <div>error</div>;
  }

  const projectInfo = fetchInactiveProjects.data;
  const sortedProjectInfo = sortProjectTrackingSummariesByDate(projectInfo);
  const allProjects = fetchProjects.map((d) => d.data!) ?? [];
  const projects = groupProjectsById(allProjects);

  return (
    <div>
      <MainTitle text="My Projects" />
      <Divider />
      <Card size="small">
        <table>
          <thead>
            <tr>
              <th>Project</th>
              <th>Stage</th>
              <th>Last Tracking</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody>
            {sortedProjectInfo.map((p) => {
              const px = projects.get(p.project_id);
              if (px === undefined) {
                return <div key={p.project_id}>no project</div>;
              }

              const lastTracking = formatFriendlyDate(p.last_tracking);
              const startDate = formatFriendlyDate(px.start_date);
              const endDate = formatFriendlyDate(px.end_date);

              return (
                <tr key={px.id}>
                  <td>
                    <LinkToProject project={px} />
                  </td>
                  <td>{px.status}</td>
                  <td>{lastTracking}</td>
                  <td>{startDate}</td>
                  <td>{endDate}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    </div>
  );
}
