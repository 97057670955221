import { Popover, Tooltip } from "antd";
import type {
  Absence,
  Booking,
  Holiday,
  Project,
  ProjectUser,
  TimeEntry,
  User,
} from "../../models";
import { date2weekNum, formatFriendlyDate, weekKeyFromDate } from "../../utils";
import { LinkToProject } from "../../utils/project";
import {
  ProjectCellData,
  ProjectCellSummary,
  UserRowHeaderData,
  buildUserReportData,
  type RawUserData,
  type UserReportType,
} from "../../utils/user_report";
import { AlertTwoTone } from "@ant-design/icons";
import { UserWeekPopover } from "./UserPopover";
import { colorForCellEntry } from "../../components/Bookings/Styling";
import { formatDecimal } from "../../utils/formatting";
import { Cell } from "./Cell";

export function UserReport(props: {
  user: User;
  projectUsers: Array<ProjectUser>;
  timeEntries: Array<TimeEntry>;
  projects: Array<Project>;
  bookings: Array<Booking>;
  absences: Array<Absence>;
  holidays: Array<Holiday>;
  weeks: Array<Date>;
}) {
  const raw = props;
  const data = buildUserReportData(raw);

  return (
    <div className="overflow-scroll">
      <UserReportContainer data={data} />
    </div>
  );
}

function UserReportContainer(props: { data: UserReportType }) {
  const data = props.data;

  return (
    <div className="report-table">
      <UserReportFirstRow raw={data.raw} />
      <UserReportRows rows={data.rows} />
    </div>
  );
}

const firstCellClasses = `flex max-w-[300px] min-w-[300px]`;

function UserReportFirstRow(props: { raw: RawUserData }) {
  const weeks = props.raw.weeks;

  return (
    <div className="table-row">
      <div className={firstCellClasses}>
        <b>Person</b>
      </div>
      {weeks.map((w) => {
        const weekKey = weekKeyFromDate(w);
        const weekText = formatFriendlyDate(w);
        const weekNo = date2weekNum(w);

        return (
          <div className="col" key={weekKey}>
            <Tooltip title={weekText}>
              <b>CW: {weekNo.toString()}</b>
            </Tooltip>
          </div>
        );
      })}
      <div className="col-info">Summary</div>
    </div>
  );
}

function UserReportRows(props: { rows: UserReportType["rows"] }) {
  const rows = props.rows;

  return rows.map((row) => {
    const summary = row.summary;
    if (summary.amountTracked === 0 && summary.amountBooked === 0) {
      return null;
    }

    const project = row.header.project;
    const rowKey = project ? project.id : "summary";

    return (
      <div key={rowKey} className="table-row">
        <div className={firstCellClasses}>
          <UserReportRowHeader header={row.header} />
        </div>

        {row.columns.map((column) => (
          <div key={column.week.toString()} className="col">
            <UserReportRowColumn column={column} />
          </div>
        ))}

        <UserReportRowSummary summary={row.summary} />
      </div>
    );
  });
}

function UserReportRowHeader(props: { header: UserRowHeaderData }) {
  const header = props.header;

  if (header.title) {
    return <span>{header.title}</span>;
  }

  if (header.project) {
    return (
      <div className="w-full items-center flex mr-2 justify-between">
        <LinkToProject project={header.project} />
        {!header.isProjectMember && (
          <Tooltip title="Not a project member" className="pl-2">
            <AlertTwoTone twoToneColor={"red"} />
          </Tooltip>
        )}
      </div>
    );
  }

  return <span>unknown</span>;
}

function UserReportRowColumn(props: { column: ProjectCellData }) {
  const data = props.column;

  return (
    <Popover
      content={
        <UserWeekPopover
          week={data.week}
          user={data.user}
          holidays={data.holidays}
          absences={data.absences}
          bookings={data.bookings}
          workSchedules={data.workSchedules}
          timeEntries={data.timeEntries}
        />
      }
      trigger="click"
    >
      <div className="cell-lines">
        <CellEntry summary={data.summary!} />
      </div>
    </Popover>
  );
}

function CellEntry(props: { summary: ProjectCellSummary }) {
  const tracked = props.summary.amountTracked;
  const acc = props.summary.accuracy;

  const color = colorForCellEntry(acc);

  const accStr = formatDecimal((Number.isNaN(acc) ? 1 : acc) * 100);
  const diffStr = `${accStr}%`;

  return (
    <div className="line cursor-pointer">
      <Cell value={tracked.toFixed(2)} color={color} />
      <Cell value={diffStr} color={color} />
    </div>
  );
}

function UserReportRowSummary(props: { summary: ProjectCellSummary }) {
  const tracked = props.summary.averageTracked;
  const acc = props.summary.accuracy;

  const color = colorForCellEntry(acc);

  const accStr = formatDecimal((Number.isNaN(acc) ? 1 : acc) * 100);
  const diffStr = `${accStr}%`;

  return (
    <div className="cell-lines">
      <div className="line cursor-pointer">
        <Cell value={tracked.toFixed(2)} color={color} />
        <Cell value={diffStr} color={color} />
      </div>
    </div>
  );
}
