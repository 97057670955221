import { Col, Collapse, Row, Select, Statistic } from "antd";
import { useParams } from "react-router-dom";
import { Card, Divider } from "../../components/Antd";
import { InternalError } from "../../components/Status/InternalError";
import { Loading } from "../../components/Status/Loading";
import { NotFound } from "../../components/Status/NotFound";
import { LinkToUser } from "../../components/Users/LinkToUser";
import { UserStaffing } from "../../components/Users/UserStaffing";
import { useAuth } from "../../Providers/AuthProvider";
import {
  addWeeks,
  buildDefaultTimeline,
  date2weekNum,
  dateRangeToQueryParam,
  formatFriendlyDate,
  nWeeksUntilThisWeek,
  sortTeamLeadReportProjectDetailsByTotalHours,
  sortTeamLeadReportProjectDetailUsersByTotalHours,
  sortTeamLeadReportUserDetailProjectsByTotalHours,
  sortTeamLeadReportUserDetailsByTotalHours,
  sortUsersByGivenName,
  weekEnd,
  weekStart,
} from "../../utils";
import { LinkToProject } from "../../utils/project";
import { ReportTeamContainer } from "../Reports/Team";

import { Tabs } from "antd";
import { useState } from "react";
import type {
  TeamLeadReport,
  TeamLeadReportProjectDetail,
  TeamLeadReportProjectDetailUser,
  TeamLeadReportUserDetail,
  TeamLeadReportUserDetailProject,
} from "../Reports/teamLeadTypes";
import { MainTitle } from "@/components/Typography";

const { TabPane } = Tabs;
const { Panel } = Collapse;

export function UserReports() {
  const { userId } = useParams();

  if (!userId) {
    return <NotFound />;
  }

  return <TeamLeadReportUI userId={userId} />;
}

function UserExtra(props: {
  user: TeamLeadReportUserDetail;
  projects: TeamLeadReportUserDetailProject[];
}) {
  const pu = props.user;
  const totalHours = pu.billable_hours + pu.non_billable_hours;
  const totalProjects = props.projects.length;

  return (
    <div className="flex align-middle place-items-center justify-end">
      <span className="w-[130px] text-right ml-3">
        {totalProjects} projects - {totalHours}h
      </span>
    </div>
  );
}

function ProjectExtra(props: {
  project: TeamLeadReportProjectDetail;
  users: TeamLeadReportProjectDetailUser[];
  totalHours: number;
}) {
  const pd = props.project;
  const totalHours = pd.billable_hours + pd.non_billable_hours;
  const ratioOverall =
    (props.totalHours > 0 ? totalHours / props.totalHours : 0) * 100;

  return (
    <div className="flex align-middle place-items-center justify-end">
      <span className="w-[130px] text-right ml-3">
        {totalHours}h - <b>{ratioOverall.toFixed(2)}%</b>
      </span>
    </div>
  );
}

function TeamLeadReportUsers(props: { report: TeamLeadReport }) {
  const sortedUsers = sortTeamLeadReportUserDetailsByTotalHours(
    props.report.user_details,
  );

  return (
    <div>
      <Collapse size="small">
        {sortedUsers.map((pu) => {
          const sortedProjects =
            sortTeamLeadReportUserDetailProjectsByTotalHours(pu.projects);

          return (
            <Panel
              key={pu.user_id}
              header={
                <LinkToUser id={pu.user_id.toString()} name={pu.user_name} />
              }
              extra={<UserExtra user={pu} projects={pu.projects} />}
            >
              <div className="border rounded-md overflow-hidden">
                {/* Header */}
                <div className="flex bg-gray-50 border-b">
                  <div className="min-w-[500px] flex-1 px-2 text-left font-bold">
                    Project
                  </div>
                  <div className="w-[90px] px-2 font-bold">Tracked</div>
                  <div className="w-[90px] px-2 font-bold">Billable</div>
                  <div className="w-[90px] px-2 font-bold whitespace-nowrap">
                    NonBillable
                  </div>
                  <div className="w-[90px] px-2 font-bold">Staffed</div>
                </div>

                {/* Rows */}
                {sortedProjects.map((p) => (
                  <div
                    key={p.project_id}
                    className="flex hover:bg-gray-100 border-b"
                  >
                    <div className="min-w-[500px] flex-1 px-2 truncate">
                      <LinkToProject
                        id={p.project_id.toString()}
                        name={p.project_name}
                      />
                    </div>
                    <div className="w-[90px] px-2 ">
                      {p.billable_hours + p.non_billable_hours}
                    </div>
                    <div className="w-[90px] px-2 ">{p.billable_hours}</div>
                    <div className="w-[90px] px-2 ">{p.non_billable_hours}</div>
                    <div className="w-[90px] px-2 ">{p.booked_hours}</div>
                  </div>
                ))}
              </div>
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
}

function TeamLeadReportProjects(props: { report: TeamLeadReport }) {
  const d = props.report;
  const totalHours =
    Number(d.total_non_billable_hours) + Number(d.total_billable_hours);

  const sortedProjectDetails = sortTeamLeadReportProjectDetailsByTotalHours(
    d.project_details,
  );
  return (
    <div>
      <Collapse size="small">
        {sortedProjectDetails.map((pd) => {
          const sortedUsers = sortTeamLeadReportProjectDetailUsersByTotalHours(
            pd.users,
          );

          return (
            <Panel
              key={pd.project_id}
              header={
                <LinkToProject
                  id={pd.project_id.toString()}
                  name={pd.project_name}
                />
              }
              extra={
                <ProjectExtra
                  totalHours={totalHours}
                  project={pd}
                  users={sortedUsers}
                />
              }
            >
              <div className="border rounded-md overflow-hidden">
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-50 border-b">
                      <th className="text-left px-2 py-0 min-w-[500px] flex-1 font-bold">
                        Person
                      </th>
                      <th className="text-left px-2 w-[90px] font-bold">
                        Hours
                      </th>
                      <th className="text-left px-2 w-[90px] font-bold">
                        Billable
                      </th>
                      <th className="text-left px-2 w-[90px] font-bold whitespace-nowrap">
                        NonBillable
                      </th>
                      <th className="text-left px-2 w-[90px] font-bold">
                        Staffed
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedUsers.map((u) => (
                      <tr
                        key={u.user_id}
                        className="hover:bg-gray-100 border-b"
                      >
                        <td className="p-0 pl-2 truncate">
                          <LinkToUser
                            name={u.user_name}
                            id={u.user_id.toString()}
                          />
                        </td>
                        <td className="px-2 w-[90px]">
                          {u.billable_hours + u.non_billable_hours}
                        </td>
                        <td className="px-2 w-[90px]">{u.billable_hours}</td>
                        <td className="px-2 w-[90px]">
                          {u.non_billable_hours}
                        </td>
                        <td className="px-2 w-[90px]">{u.booked_hours}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
}

export function TeamLeadReportUI(props: { userId: string }) {
  const { api } = useAuth();
  const today = weekStart(new Date());
  const [date, setDate] = useState<string>(today.toISOString());

  const s = weekStart(date).toDate();
  const e = weekEnd(date).toDate();

  const params = dateRangeToQueryParam(s, e);

  const fetchReport = api.fetchReportTeamLead(Number(props.userId), params);
  const fetchManager = api.fetchUserById(props.userId);
  const fetchDirectReportUsers = api.fetchDirectReportsByUserId(props.userId);

  if (
    fetchReport.isPending ||
    fetchManager.isPending ||
    fetchDirectReportUsers.isPending
  ) {
    return <Loading />;
  }

  if (
    fetchReport.isError ||
    fetchManager.isError ||
    fetchDirectReportUsers.isError
  ) {
    return <InternalError />;
  }

  const sortedUsers = sortUsersByGivenName(fetchDirectReportUsers.data);
  const d = fetchReport.data;

  const user = fetchManager.data;
  const timeline = buildDefaultTimeline();
  const historicTimeline = nWeeksUntilThisWeek(-6);

  const numWeeks = 10;

  const options = [...Array(numWeeks).keys()].map((offSet) => {
    const d = weekStart(addWeeks(today.toDate(), -offSet).toDate()).toDate();
    const cw = date2weekNum(d);
    const value = d;

    return {
      label: `CW${cw}`,
      value: value.toISOString(),
    };
  });

  return (
    <div>
      <div className="flex justify-between">
        <MainTitle title="Direct Reports">
          <LinkToUser user={user} />
          's Direct Reports
        </MainTitle>
        <Select
          options={options}
          value={date}
          onChange={(v) => setDate(v)}
          className="w-[300px]"
        />
      </div>

      <Overview report={d} />
      <Card size="small">
        <Tabs size="small" defaultActiveKey="2">
          <TabPane tab="Projects" key="1">
            <TeamLeadReportProjects report={d} />
          </TabPane>
          <TabPane tab="People" key="2">
            <TeamLeadReportUsers report={d} />
          </TabPane>
          <TabPane tab="Staffing" key="3">
            <UserStaffing users={sortedUsers} timeline={timeline} />
          </TabPane>
          <TabPane tab="Report" key="4">
            <ReportTeamContainer
              users={sortedUsers}
              timeline={historicTimeline}
            />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
}

function Overview(props: { report: TeamLeadReport }) {
  const d = props.report;

  return (
    <div>
      <p>
        For the time range between {formatFriendlyDate(d.start_date)} and{" "}
        {formatFriendlyDate(d.end_date)}
      </p>
      <Row gutter={22}>
        <Col span={6}>
          <Card size="small" bordered={false}>
            <Statistic
              title="Total Billable"
              value={d.total_billable_hours}
              precision={1}
              suffix="hours"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card size="small" bordered={false}>
            <Statistic
              title="Total Non Billable"
              value={d.total_non_billable_hours}
              precision={1}
              suffix="hours"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card size="small" bordered={false}>
            <Statistic
              title="Billable Ratio"
              value={d.overall_billable_ratio}
              precision={2}
              suffix="%"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card size="small" bordered={false}>
            <Statistic
              title="Total Unique Projects"
              value={d.project_details.length}
              precision={0}
            />
          </Card>
        </Col>
      </Row>
      <Divider />
    </div>
  );
}

export function MyUserReports() {
  const { user } = useAuth();

  const userId = user.id;

  if (!userId) {
    return <NotFound />;
  }

  return <TeamLeadReportUI userId={userId.toString()} />;
}
