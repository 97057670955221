import {
  Absence,
  Booking,
  Holiday,
  TimeEntry,
  User,
  UserWorkDaySchedule,
} from "../models";
import {
  getTotalAbsences,
  getTotalBookedHours,
  getTotalHolidayHours,
  getTotalTrackedHours,
  hoursForWeek,
} from "../routes/Reports/util";
import { weekKeyFromDate } from "./date";
import {
  groupAbsencesByUserId,
  groupAbsencesByWeek,
  groupBookingsByUserId,
  groupBookingsByWeek,
  groupHolidaysByWeek,
  groupTimeEntriesByUserId,
  groupTimeEntriesByWeek,
  groupUserWorkDaySchedulesByUserId,
} from "./group";
import { type Report, type ReportRow } from "./report";
import { sortUsersByGivenName } from "./sort";

export interface RawTeamData {
  users: Array<User>;
  bookings: Array<Booking>;
  timeEntries: Array<TimeEntry>;
  holidaysByLocation: Map<number, Array<Holiday>>;
  workSchedules: Array<UserWorkDaySchedule>;
  absences: Array<Absence>;
  weeks: Array<Date>;
}

export interface TeamRowHeaderData {
  title?: string;
  user?: User;
}

export interface TeamCellData {
  week: Date;
  user?: User;
  bookings: Array<Booking> | [];
  timeEntries: Array<TimeEntry> | [];
  holidays: Array<Holiday> | [];
  absences: Array<Absence> | [];
  userWorkDaySchedules: Array<UserWorkDaySchedule> | [];
  summary?: TeamCellSummary;
}

export interface TeamCellSummary {
  amountTracked: number;
  amountBooked: number;
  holidayHours: number;
  absenceHours: number;
  possibleHours: number;
  percentBooked: number;
}

export type TeamReport = Report<
  RawTeamData,
  TeamRowHeaderData,
  TeamCellData,
  TeamCellSummary
>;

function buildTeamCellSummary(data: TeamCellData): TeamCellSummary {
  const trackedHours = getTotalTrackedHours(data.timeEntries);
  const bookedHours = getTotalBookedHours(data.bookings);
  const absenceHours = getTotalAbsences(data.absences) * 8;
  const holidayHours = getTotalHolidayHours(data.holidays);
  const normalWeekHours = hoursForWeek(data.week, data.userWorkDaySchedules); //
  const availableHours = normalWeekHours - absenceHours - holidayHours;
  const percentBooked = availableHours > 0 ? trackedHours / availableHours : 1;

  return {
    amountTracked: trackedHours,
    amountBooked: bookedHours,
    absenceHours: absenceHours,
    holidayHours: holidayHours,
    possibleHours: availableHours,
    percentBooked: percentBooked,
  };
}

function buildTeamRowSummary(data: Array<TeamCellData>): TeamCellSummary {
  const trackedHours = data.reduce((t, v) => t + v.summary!.amountTracked, 0);
  const bookedHours = data.reduce((t, v) => t + v.summary!.amountBooked, 0);
  const absenceHours = data.reduce((t, v) => t + v.summary!.absenceHours, 0);
  const holidayHours = data.reduce((t, v) => t + v.summary!.holidayHours, 0);
  const availableHours = data.reduce((t, v) => t + v.summary!.possibleHours, 0);
  const percentBooked = availableHours > 0 ? trackedHours / availableHours : 1;

  return {
    amountTracked: trackedHours,
    amountBooked: bookedHours,
    absenceHours: absenceHours,
    holidayHours: holidayHours,
    possibleHours: availableHours,
    percentBooked: percentBooked,
  };
}

export function buildTeamReportData(data: RawTeamData): TeamReport {
  const sortedUsers = sortUsersByGivenName(data.users);
  const timeEntriesByUserId = groupTimeEntriesByUserId(data.timeEntries);
  const bookingsByUserId = groupBookingsByUserId(data.bookings);
  const absencesByUserId = groupAbsencesByUserId(data.absences);
  const workSchedulesByUserId = groupUserWorkDaySchedulesByUserId(
    data.workSchedules,
  );

  const rows = sortedUsers.map((user) => {
    const userTimeEntries = timeEntriesByUserId.get(user.id) ?? [];
    const userBookings = bookingsByUserId.get(user.id) ?? [];
    const userAbsences = absencesByUserId.get(user.id) ?? [];
    const userHolidays =
      data.holidaysByLocation.get(user.location_id ?? 0) ?? [];
    const userWorkSchedules = workSchedulesByUserId.get(user.id) ?? [];

    const bookingsByWeek = groupBookingsByWeek(userBookings);
    const timeEntriesByWeek = groupTimeEntriesByWeek(userTimeEntries);
    const holidaysByWeek = groupHolidaysByWeek(userHolidays);
    const absencesByWeek = groupAbsencesByWeek(userAbsences);

    const cols = data.weeks.map((week) => {
      const weekKey = weekKeyFromDate(week);
      const bookings = bookingsByWeek.get(weekKey) ?? [];
      const timeEntries = timeEntriesByWeek.get(weekKey) ?? [];
      const holidays = holidaysByWeek.get(weekKey) ?? [];
      const absences = absencesByWeek.get(weekKey) ?? [];

      const data = {
        week: week,
        user: user,
        bookings: bookings,
        timeEntries: timeEntries,
        holidays: holidays,
        absences: absences,
        userWorkDaySchedules: userWorkSchedules,
      } as TeamCellData;

      data.summary = buildTeamCellSummary(data);

      return data;
    });

    const headerData = {
      user: user,
    };

    return {
      header: headerData,
      columns: cols,
      summary: buildTeamRowSummary(cols),
    } as ReportRow<TeamRowHeaderData, TeamCellData, TeamCellSummary>;
  });

  const summaryRow = buildSummaryRow(data.weeks, rows);
  rows.push(summaryRow);

  return {
    raw: data,
    rows: rows,
  };
}

function buildSummaryRow(
  weeks: Array<Date>,
  rows: Array<ReportRow<TeamRowHeaderData, TeamCellData, TeamCellSummary>>,
) {
  const numWeeks = weeks.length;
  const numRows = rows.length;

  const weekSummary = [];
  for (let i = 0; i < numWeeks; i++) {
    const weekCells = [] as Array<TeamCellData>;
    for (let j = 0; j < numRows; j++) {
      const cell = rows[j].columns[i];
      weekCells.push(cell);
    }

    const sum = buildTeamRowSummary(weekCells);

    const info = {
      week: weeks[i],
      bookings: [],
      timeEntries: [],
      holidays: [],
      absences: [],
      summary: sum,
    } as TeamCellData;

    weekSummary.push(info);
  }

  const sumsum = buildTeamRowSummary(weekSummary);

  const row = {
    header: { title: "Summary" } as TeamRowHeaderData,
    columns: weekSummary,
    summary: sumsum,
  };

  return row;
}
