import { ProjectPlanSummary } from "@/models";
import { useAuth } from "@/Providers/AuthProvider";
import {
  MonthInfo,
  currentMonthStart,
  formatFriendlyMonth,
  formatFriendlyYear,
  sameWeek,
} from "@/utils";
import { Popconfirm } from "antd";
import { ChevronRight, ChevronUp } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  CellChangeHandler,
  DateString,
  PlannerRoleName,
  ProjectPlan,
  RoleChangeHandler,
  RoleRemoveHandler,
} from "./types";
import {
  allRoles,
  dateToDateString,
  isValidNumber,
  removeLeadingZeros,
  sumForecast,
  summarizeMap,
} from "./util";
import { DragableDualColumnView } from "../DragableDualColumnView/View";

export function PlannerNoRoleInfo(props: { projectPlan: ProjectPlan }) {
  const numRoles = props.projectPlan.size;

  if (numRoles > 0) {
    return null;
  }

  return (
    <div className="py-4 pb-0">
      You have no resources in your project plan. Add some to get started.
    </div>
  );
}

export function PlannerRowContainer(props: { children: React.ReactNode }) {
  return (
    <div className="flex flex-row flex-grow min-w-fit h-10 items-center m-0 border-b">
      {props.children}
    </div>
  );
}

function PlannerRemoveRole(props: {
  editable: boolean;
  role: PlannerRoleName;
  onRemove?: RoleRemoveHandler;
}) {
  if (props.editable === false) {
    return <></>;
  }

  if (props.onRemove === undefined) {
    return <></>;
  }

  return (
    <div className="pl-2">
      <Popconfirm
        okText="Remove"
        cancelText="Nevermind"
        title="Remove role"
        description="Are you sure you want to remove the role from the project plan?"
        onConfirm={() => props.onRemove!(props.role)}
      >
        <div className="cursor-pointer hover:font-bold">Remove</div>
      </Popconfirm>
    </div>
  );
}

export function PlannerRowStart(props: {
  editable: boolean;
  sum: number;
  role: PlannerRoleName;
  months: MonthInfo[];
  projectMonths: Map<number, Map<DateString, number>>;
  onRemove?: RoleRemoveHandler;
  onExpandToggle: () => void;
  expanded: boolean;
  single: boolean;
}) {
  const role = props.role;
  const editable = props.editable ?? false;
  const project_ids: number[] = [...new Set(props.projectMonths.keys())];

  return (
    <>
      <PlannerRowContainer key={role}>
        <PlannerFirstColumn>
          <div className="flex justify-between">
            {props.single === false && (
              <div className="cursor-pointer" onClick={props.onExpandToggle}>
                {props.expanded ? (
                  <ChevronUp size={18} />
                ) : (
                  <ChevronRight size={18} />
                )}
              </div>
            )}
            <PlannerRoleComponent role={role} />
            <PlannerRemoveRole
              onRemove={props.onRemove}
              role={role}
              editable={editable}
            />
          </div>
        </PlannerFirstColumn>
      </PlannerRowContainer>
      {props.expanded && (
        <>
          {project_ids.map((projectId) => {
            return (
              <PlannerRowContainer key={role}>
                <PlannerFirstColumn>
                  <PlannerProjectComponent projectId={projectId} />
                </PlannerFirstColumn>
              </PlannerRowContainer>
            );
          })}
        </>
      )}
    </>
  );
}

export function PlannerRow(props: {
  editable: boolean;
  sum: number;
  role: PlannerRoleName;
  months: MonthInfo[];
  projectMonths: Map<number, Map<DateString, number>>;
  onChange: RoleChangeHandler;
  expanded: boolean;
}) {
  const role = props.role;
  const months = props.months;
  const projectMonths = props.projectMonths;
  const editable = props.editable ?? false;

  const onCellChange = (
    m: MonthInfo,
    role: PlannerRoleName,
  ): CellChangeHandler => {
    return (value: number) => {
      if (editable === false) {
        return;
      }
      const monthDateString = dateToDateString(m.date);
      props.onChange(role, monthDateString, value);
    };
  };

  const project_ids: number[] = [...new Set(props.projectMonths.keys())];
  const roleMap = sumProjectMonths(projectMonths);

  return (
    <>
      <PlannerRowContainer key={"summary|" + role}>
        <div className="flex flex-row h-full">
          {months.map((m) => {
            const monthDateString = dateToDateString(m.date);
            const value = roleMap.get(monthDateString) ?? 0;
            const k = `${m.year}*|*${m.month}`;
            return (
              <Cell
                editable={editable}
                key={k}
                value={value}
                onChange={onCellChange(m, role)}
              />
            );
          })}
        </div>
      </PlannerRowContainer>

      {props.expanded && (
        <>
          {project_ids.map((p) => {
            const roleMap =
              projectMonths.get(p) ?? new Map<DateString, number>();

            return (
              <PlannerRowContainer key={"child" + role}>
                <div className="flex flex-row h-full bg-gray-100">
                  {months.map((m) => {
                    const monthDateString = dateToDateString(m.date);
                    const value = roleMap.get(monthDateString) ?? 0;
                    const k = `${m.year}-|-${m.month}`;
                    return (
                      <Cell
                        editable={editable}
                        key={k}
                        value={value}
                        onChange={onCellChange(m, role)}
                      />
                    );
                  })}
                </div>
              </PlannerRowContainer>
            );
          })}
        </>
      )}
    </>
  );
}

export function PlannerHeaderRow(props: { months: MonthInfo[] }) {
  const targetRef = useRef<HTMLElement>(null);
  const currentMonthDate = currentMonthStart();

  useEffect(() => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: "smooth",
        inline: "start",
      });
    }
  }, []);
  return (
    <PlannerRowContainer>
      {props.months.map((m) => {
        const currentMonth = sameWeek(m.date, currentMonthDate);
        const k = `${m.year}|${m.month}`;

        if (currentMonth) {
          return (
            <span key={k} ref={targetRef}>
              <HeaderMonth highlight={true} month={m.date} />
            </span>
          );
        } else {
          return <HeaderMonth key={k} month={m.date} highlight={false} />;
        }
      })}
    </PlannerRowContainer>
  );
}

function HeaderMonth(props: { month: Date; highlight: boolean }) {
  const month = formatFriendlyMonth(props.month);
  const year = formatFriendlyYear(props.month);
  const className = props.highlight ? "bg-blue-200 rounded-md" : "";
  return (
    <CellSpacing className={className}>
      <div className="font-sans">
        <div className="font-bold text-md">{month}</div>
        <div className="font-light text-xs">{year}</div>
      </div>
    </CellSpacing>
  );
}

export function PlannerTotalCell(props: {
  sum: number;
  projectMonths: Map<number, Map<string, number>>;
  projectId?: number;
}) {
  let mapToUse = props.projectMonths;

  if (props.projectId !== undefined) {
    const m = new Map<number, Map<string, number>>();
    m.set(props.projectId, props.projectMonths.get(props.projectId)!);
    mapToUse = m;
  }

  const total = summarizeMap(mapToUse);
  const sum = props.sum ?? 0;
  const percent = sum > 0 ? (total / sum) * 100 : 0;

  return (
    <div className="w-24 p-2">
      {total.toFixed(2)}{" "}
      <span className="font-light text-xs">({percent.toFixed(0)}%)</span>
    </div>
  );
}

export function PlannerFirstColumn(props: {
  className?: string;
  children: React.ReactNode;
}) {
  const classes = `min-w-72 p-2 select-none h-full flex items-center justify-between ${props.className ?? ""}`;
  return <div className={classes}>{props.children}</div>;
}

export function PlannerRoleComponent(props: { role: PlannerRoleName }) {
  const role = allRoles.find((r) => r.value == props.role);
  if (role === undefined) {
    throw `unknown role error: ${props.role}`;
  }

  return <div className="font-bold">{role.label}</div>;
}

function PlannerProjectComponent(props: { projectId: number }) {
  const { api } = useAuth();
  const fetchProject = api.fetchProjectById(props.projectId.toString());
  if (fetchProject.isLoading) {
    return <span>loading</span>;
  }
  if (fetchProject.isError) {
    return <span>error</span>;
  }

  const project = fetchProject.data!.project;

  return (
    <div className="font-normal truncate">
      <Link to={`/projects/${project.id}/planner`}>{project.name}</Link>
      <br />
      <span className="text-xs font-light">{project.status}</span>
    </div>
  );
}

function CellSpacing(props: { className?: string; children: React.ReactNode }) {
  return (
    <div className={`w-16 h-full text-center ${props.className ?? ""}`}>
      {props.children}
    </div>
  );
}

function Cell(props: {
  editable: boolean;
  value: number;
  onChange: CellChangeHandler;
}) {
  const [value, setValue] = useState<string>(props.value.toString());

  useEffect(() => {
    if (isValidNumber(value)) {
      props.onChange(Number(value));
    }
  }, [value]);

  return (
    <CellSpacing className="border-l last:border-r">
      {props.editable ? (
        <input
          value={value}
          step=".1"
          className="w-full p-0 m-0 h-full bg-white text-center font-sans border-0 outline-none"
          onChange={(e) => {
            const v = e.target.value === "" ? "0" : e.target.value;
            setValue(removeLeadingZeros(v));
          }}
        />
      ) : (
        <span className="flex items-center justify-center w-full h-full">
          {value}
        </span>
      )}
    </CellSpacing>
  );
}

function PlannerRowSummary(props: {
  sum: number;
  projectMonths: Map<number, Map<DateString, number>>;
  expanded: boolean;
}) {
  const project_ids: number[] = [...new Set(props.projectMonths.keys())];
  return (
    <>
      <PlannerRowContainer>
        <PlannerTotalCell sum={props.sum} projectMonths={props.projectMonths} />
      </PlannerRowContainer>
      {props.expanded && (
        <>
          {project_ids.map((projectId) => {
            return (
              <PlannerRowContainer key={"a" + projectId}>
                <PlannerTotalCell
                  sum={props.sum}
                  projectMonths={props.projectMonths}
                  projectId={projectId}
                />
              </PlannerRowContainer>
            );
          })}
        </>
      )}
    </>
  );
}

export function PlannerFooterSumCell(props: {
  months: Date[];
  projectPlan: ProjectPlan;
}) {
  const allSum = sumForecast(props.projectPlan);

  return (
    <PlannerRowContainer>
      <div className="w-24 p-2">{allSum.toFixed(2)}</div>
    </PlannerRowContainer>
  );
}

function sumProjectMonths(
  projectMap: Map<number, Map<DateString, number>>,
): Map<DateString, number> {
  const sum = new Map<DateString, number>();

  for (const [_, monthMap] of projectMap.entries()) {
    for (const [month, amount] of monthMap.entries()) {
      if (amount === 0) {
        continue;
      }

      const monthString = dateToDateString(month);
      let v = sum.get(monthString) ?? 0;
      sum.set(monthString, v + amount);
    }
  }

  return sum;
}

function roleHasData(role: PlannerRoleName, projectPlan: ProjectPlan): boolean {
  const projectMap = projectPlan.get(role);
  if (projectMap === undefined) {
    return false;
  }

  for (const [_, monthMap] of projectMap.entries()) {
    for (const [_, amount] of monthMap.entries()) {
      if (amount > 0) {
        return true;
      }
    }
  }

  return false;
}

function sumMonths(projectPlan: ProjectPlan): Map<DateString, number> {
  const sum = new Map<DateString, number>();

  for (const [_, projectMap] of projectPlan.entries()) {
    for (const [_, monthMap] of projectMap.entries()) {
      for (const [month, amount] of monthMap.entries()) {
        if (amount === 0) {
          continue;
        }

        const monthString = dateToDateString(month);
        let v = sum.get(monthString);
        if (v === undefined) {
          v = 0;
        }
        sum.set(monthString, v + amount);
      }
    }
  }

  return sum;
}

export function PlannerFooterRow(props: {
  months: Date[];
  projectPlan: ProjectPlan;
}) {
  const sum = sumMonths(props.projectPlan);

  return (
    <PlannerRowContainer>
      <div className="flex flex-row h-full">
        {props.months.map((m) => {
          const monthString = dateToDateString(m);
          const v = sum.get(monthString) ?? 0;
          return (
            <CellSpacing
              className="flex h-full items-center border-l last:border-r"
              key={monthString}
            >
              <div className="w-full">{v.toFixed(2)}</div>
            </CellSpacing>
          );
        })}
      </div>
    </PlannerRowContainer>
  );
}

export function ProjectPlannerContent(props: {
  projectPlan: ProjectPlan;
  roles: PlannerRoleName[];
  onRoleChange?: RoleChangeHandler;
  onRoleRemove?: RoleRemoveHandler;
  months: Array<MonthInfo>;
  editable: boolean;
  summary: ProjectPlanSummary;
  single: boolean;
}) {
  const months = props.months;
  const projectPlan = props.projectPlan;
  const roles = props.editable
    ? props.roles
    : props.roles.filter((r) => roleHasData(r, props.projectPlan));
  const sum = sumForecast(props.projectPlan);
  const [expandedRoles, setExpandedRoles] = useState<Set<string>>(
    new Set<string>(),
  );
  const single = props.single ?? true;

  return (
    <div className="flex flex-col gap-2">
      <div className="border shadow-md rounded-lg bg-white">
        <DragableDualColumnView
          left={
            <>
              <PlannerRowContainer>
                <PlannerFirstColumn>
                  <div className="font-bold">Months</div>
                </PlannerFirstColumn>
              </PlannerRowContainer>
              {roles.map((role) => {
                const projectMonths =
                  projectPlan.get(role) ??
                  new Map<number, Map<DateString, number>>();

                return (
                  <PlannerRowStart
                    editable={props.editable}
                    sum={sum}
                    key={role}
                    role={role}
                    months={months}
                    projectMonths={projectMonths}
                    onRemove={props.onRoleRemove}
                    expanded={expandedRoles.has(role)}
                    single={single}
                    onExpandToggle={() => {
                      setExpandedRoles((prevItems) => {
                        const newItems = new Set(prevItems);
                        if (newItems.has(role)) {
                          newItems.delete(role);
                        } else {
                          newItems.add(role);
                        }
                        return newItems;
                      });
                    }}
                  />
                );
              })}
              <PlannerRowContainer>
                <PlannerFirstColumn>
                  <div className="font-bold">Summary</div>
                </PlannerFirstColumn>
              </PlannerRowContainer>
            </>
          }
          right={
            <>
              <PlannerHeaderRow months={months} />
              {roles.map((role) => {
                const projectMonths =
                  projectPlan.get(role) ??
                  new Map<number, Map<DateString, number>>();

                return (
                  <PlannerRow
                    editable={props.editable}
                    sum={sum}
                    key={role}
                    role={role}
                    months={months}
                    projectMonths={projectMonths}
                    onChange={props.onRoleChange!}
                    expanded={expandedRoles.has(role)}
                  />
                );
              })}
              <PlannerFooterRow
                months={months.map((m) => m.date)}
                projectPlan={projectPlan}
              />
            </>
          }
          end={
            <>
              <PlannerRowContainer>
                <div className="w-24 p-2 font-bold">Summary</div>
              </PlannerRowContainer>

              {roles.map((role) => {
                const projectMonths =
                  projectPlan.get(role) ??
                  new Map<number, Map<DateString, number>>();

                return (
                  <PlannerRowSummary
                    key={role}
                    projectMonths={projectMonths}
                    expanded={expandedRoles.has(role)}
                    sum={sum}
                  />
                );
              })}

              <PlannerFooterSumCell
                months={months.map((m) => m.date)}
                projectPlan={projectPlan}
              />
            </>
          }
        />
      </div>
    </div>
  );
}
