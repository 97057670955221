import { useState } from "react";
import { Input } from "../../Antd";
import { User } from "../../../models/user";
import { normalizeString } from "../../../utils";
import { debounce } from "lodash-es";

interface UserSearchProps {
  users: Array<any>;
  onFilter: Function;
}

export function UserSearch(props: UserSearchProps) {
  const [filter, setFilter] = useState<string>("");
  const users = props.users;

  const debouncedSearch = debounce((filter: string) => {
    const filteredUsers = filterUsers(filter, users);
    props.onFilter(filteredUsers);
  }, 300);

  function onChange(e: any) {
    const value = e.target.value;
    setFilter(value);

    if (value.length < 3) {
      props.onFilter([]);
      return;
    }

    debouncedSearch(value);
  }

  return <Input onChange={onChange} value={filter} />;
}

function filterUsers(
  filter: string,
  unfilteredUsers: Array<User>,
): Array<User> {
  return unfilteredUsers.filter((u) => {
    const actualFilter = normalizeString(filter);
    const searchableString = normalizeString(
      `${u.given_name} ${u.family_name}`,
    );
    const index = searchableString.indexOf(actualFilter);
    return index >= 0;
  });
}
