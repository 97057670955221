import { Link } from "react-router-dom";
import {
  Absence,
  Booking,
  BookingOptions,
  Holiday,
  Project,
  ProjectAndProjectUser,
  ProjectUser,
  Timeline,
  User,
  UserWorkDaySchedule,
} from "../../models";
import {
  buildTimelineForProject,
  sortProjectUsersByProjectName,
} from "../../utils";
import {
  BookingLine,
  Bookings,
  BookingsHeader,
  BookingTable,
} from "../Bookings/Bookings";

interface ProjectListProps {
  projectAndProjectUsers: Array<ProjectAndProjectUser>;
  timeline: Timeline;
  user: User;
  bookings: Array<Booking>;
  holidays?: Array<Holiday>;
  absences?: Array<Absence>;
  width?: string;
  showProjectsWithoutBookings: boolean;
  schedules: Array<UserWorkDaySchedule>;
}

export function ProjectList(props: ProjectListProps) {
  const showProjectsWithoutBookings = props.showProjectsWithoutBookings ?? true;
  const projectAndProjectUsers = props.projectAndProjectUsers;
  const timeline = props.timeline;
  const sortedProjectUsers = sortProjectUsersByProjectName(
    projectAndProjectUsers,
  );
  const groupedBookings = groupBookingsByProject(props.bookings);
  const width = props.width ?? "300px";

  if (projectAndProjectUsers.length === 0) {
    return <div>no data</div>;
  }

  const summaryOptions = {
    timeline: timeline,
    today: new Date(),
    bookings: props.bookings,
    showPopover: true,
    summary: true,
    absences: props.absences,
    holidays: props.holidays,
    schedules: props.schedules,
  } as BookingOptions;

  return (
    <BookingTable width={width}>
      <BookingLine>
        <BookingsHeader timeline={timeline} />
      </BookingLine>
      {sortedProjectUsers.map((pu) => {
        const bookings = groupedBookings[pu.project.id];

        const projectTimeline = buildTimelineForProject(pu.project);
        const adjustedTimeline = {
          today: new Date(),
          view: timeline.view,
          project: projectTimeline.project,
        };

        const options = {
          project: pu.project,
          schedules: props.schedules,
          bookings: bookings,
          user: props.user,
          userAndProjectUser: {
            user: props.user,
            project_user: pu.project_user,
          },
          timeline: adjustedTimeline,
        };

        if (
          !showProjectsWithoutBookings &&
          (bookings === undefined || bookings.length === 0)
        ) {
          return;
        }

        return (
          <BookingLine
            key={pu.project_user.id}
            lineContent={
              <LineInfo project={pu.project} project_user={pu.project_user} />
            }
          >
            <Bookings options={options} />
          </BookingLine>
        );
      })}
      <BookingLine lineContent={<LineSummary />}>
        <Bookings options={summaryOptions} />
      </BookingLine>
    </BookingTable>
  );
}

function groupBookingsByProject(bookings: Array<Booking>): {
  [index: number]: Array<Booking>;
} {
  let grouped: { [index: number]: Array<Booking> } = {};

  bookings.forEach((b) => {
    const projectId = b.project_id;
    let g = grouped[projectId];
    if (g === undefined) {
      g = [];
    }

    g.push(b);
    grouped[projectId] = g;
  });

  return grouped;
}

interface LineInfoProps {
  project: Project;
  project_user: ProjectUser;
}

function LineInfo(props: LineInfoProps) {
  const project = props.project;
  const project_user = props.project_user;

  return (
    <div>
      <Link to={`/projects/${project.id}`}>{project.name}</Link>
      <div className="font-light">
        {project.status} - {project_user.role}
      </div>
    </div>
  );
}

function LineSummary() {
  return (
    <div className="justify-center">
      <b>Summary</b>
    </div>
  );
}
