import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Button, Card, Popconfirm } from "../../components/Antd";
import { Loading } from "../../components/Status/Loading";
import { LinkToUser } from "../../components/Users/LinkToUser";
import { ProjectUserRequest } from "../../models";
import { useAuth } from "../../Providers/AuthProvider";
import { canManageProjectUserRequests } from "../../Providers/permissions";
import { formatFriendlyDate } from "../../utils";
import { LinkToProject } from "../../utils/project";
import { ProjectUserRequestModal } from "./CreateModal";

interface ProjectUserRequestLineProps {
  projectUserRequest: ProjectUserRequest;
  onFinish: () => void;
}

function ProjectUserRequestLineContent(props: ProjectUserRequestLineProps) {
  const pur = props.projectUserRequest;
  const requesterInfo = renderUser(pur.requester_id);
  const rejecterInfo = renderUser(pur.rejecter_id);
  const approverInfo = renderUser(pur.approver_id);
  const userInfo = renderUser(pur.user_id);

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="col-span-1">
        <b>Requester</b>: {requesterInfo}
        <br />
        {pur.approver_id && (
          <>
            <b>Approved By:</b> {approverInfo}
            <br />
          </>
        )}
        {pur.rejecter_id && (
          <>
            <b>Rejected By:</b> {rejecterInfo}
            <br />
          </>
        )}
        {pur.user_id && (
          <>
            <b>Selected Person:</b> {userInfo}
            <br />
          </>
        )}
        <b>Skill Level</b>: {pur.skill} - {marks.get(pur.skill_level)}
        <br />
        <b>Language</b>: {language(pur.language)}
        <br />
        <b>Kind</b>:
        <span style={{ textTransform: "capitalize" }}>{pur.kind}</span>
        <br />
        <b>Estimated Effort</b>: {pur.estimated_effort}%
        <br />
        <b>Created:</b> {formatFriendlyDate(pur.created_at)}
        <br />
        <b>Expected Start Date</b>: {formatFriendlyDate(pur.start)}
        <br />
        {pur.end && (
          <>
            <b>Expected End Date</b>: {formatFriendlyDate(pur.end)}
          </>
        )}
      </div>

      <div className="col-span-1">
        {pur.notes && (
          <>
            <b>Notes</b>:<br />
            <p className="whitespace-pre-line">{pur.notes}</p>
          </>
        )}
      </div>
    </div>
  );
}

export function ProjectUserRequestLine(props: ProjectUserRequestLineProps) {
  const { api } = useAuth();

  const pur = props.projectUserRequest;
  const projectData = api.fetchProjectById(pur.project_id);

  const acceptMut = api.acceptProjectUserRequest();
  const rejectMut = api.rejectProjectUserRequest();

  if (projectData.isPending) {
    return <Loading />;
  }

  if (projectData.isError) {
    return <div> error </div>;
  }

  const onReject = (pur: ProjectUserRequest) => {
    rejectMut
      .mutateAsync(pur)
      .then(() => {
        toast("Request was rejected!");
        props.onFinish();
      })
      .catch(() => toast("Error rejecting request"));
  };

  const onAccept = (pur: ProjectUserRequest) => {
    console.log("accepting pur", pur);

    acceptMut
      .mutateAsync(pur)
      .then(() => {
        toast("Request was accepted!");
        props.onFinish();
      })
      .catch(() => toast("Error accepting request"));
  };

  const project = projectData.data;

  return (
    <>
      <Card
        size="small"
        title={<LinkToProject project={project.project} />}
        extra={
          <OptionsForProjectUserRequest
            onAccept={onAccept}
            onReject={onReject}
            onFinish={props.onFinish}
            projectUserRequest={props.projectUserRequest}
          />
        }
      >
        <ProjectUserRequestLineContent {...props} />
      </Card>
    </>
  );
}

interface OptionsForProjectUserRequestProps {
  projectUserRequest: ProjectUserRequest;
  onReject: (pur: ProjectUserRequest) => void;
  onAccept: (pur: ProjectUserRequest) => void;
  onFinish: () => void;
}

function OptionsForProjectUserRequest(
  props: OptionsForProjectUserRequestProps,
) {
  const { roles, user } = useAuth();

  const canEdit =
    Number(props.projectUserRequest.requester_id) === Number(user.id);
  const canApprove = canManageProjectUserRequests(roles);

  if (!canEdit && !canApprove) {
    return <></>;
  }

  const [rejectionReason, setRejectionReason] = useState<string>("");
  const [staffingRequest, setStaffingRequest] = useState(
    props.projectUserRequest,
  );

  useEffect(() => {
    const sr = staffingRequest;
    sr.reason = rejectionReason;
    setStaffingRequest(sr);
  }, [rejectionReason]);

  const isOpen =
    staffingRequest.approver_id === null &&
    staffingRequest.rejecter_id === null;

  const approvable = isApprovable(staffingRequest);

  return (
    <div>
      <div className="flex gap-2">
        {(canEdit || canApprove) && (
          <ProjectUserRequestModal
            size="small"
            onFinish={(pur: ProjectUserRequest) => {
              setStaffingRequest(pur);
              props.onFinish();
            }}
            projectUserRequest={staffingRequest}
          />
        )}
        {isOpen && canApprove && (
          <>
            <Popconfirm
              placement="top"
              title="Approve Project Staffing Request"
              description={
                <div>
                  Are you sure you want to approve this? Have you selected a
                  user?
                </div>
              }
              onConfirm={() => props.onAccept(staffingRequest)}
              disabled={!approvable}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" disabled={!approvable} type="primary">
                Accept
              </Button>
            </Popconfirm>
            <Popconfirm
              placement="right"
              title="Reject Project Staffing Request"
              description={
                <div className="flex flex-col gap-4">
                  <div>
                    Are you sure you want to reject this? Please leave a reason.
                  </div>
                  <textarea
                    value={rejectionReason}
                    className="border p-2 outline-none focus:outline-none focus:ring-0"
                    onChange={(e) => setRejectionReason(e.target.value)}
                  />
                </div>
              }
              okButtonProps={{ disabled: rejectionReason.length === 0 }}
              onConfirm={() => props.onReject(staffingRequest)}
              okText="Reject"
              cancelText="Cancel"
            >
              <Button size="small" type="primary" danger>
                Reject
              </Button>
            </Popconfirm>
          </>
        )}
      </div>
    </div>
  );
}

function renderUser(id: string) {
  const { api } = useAuth();

  const { data, isPending, isError } = api.fetchUserById(id);
  if (isPending) {
    return "loading";
  }

  if (isError) {
    return "error";
  }

  return <LinkToUser user={data} />;
}

const isApprovable = (pur: ProjectUserRequest) => {
  if (pur.user_id === null) {
    return false;
  }

  if (pur.project_id === null) {
    return false;
  }

  if (pur.language === null) {
    return false;
  }

  return true;
};

const marks = new Map<number, string>([
  [1, "Basic"],
  [2, "Intermediate"],
  [3, "Advanced"],
  [4, "Pro"],
]);

const language = (code: string) => {
  if (code === "de") {
    return "German";
  }

  if (code === "en") {
    return "English";
  }

  if (code === "none") {
    return "Not required";
  }

  return "Not specified";
};
