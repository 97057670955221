import { useAuth } from "../../Providers/AuthProvider";
import { UserWorkDaySchedule } from "../../models";
import { activeSchedule } from "../../utils/schedule";


function ScheduleDay(props: { schedule: UserWorkDaySchedule | undefined }) {
  const s = props.schedule;
  if (s === undefined) {
    return <span>0</span>
  }

  const hours = s.minutes / 60.0;

  return (
    <span>
      {hours}hrs
    </span>
  )

}

export function UserSchedule(props: { userId: number }) {
  const { api } = useAuth();
  const userId = props.userId;

  const fetchSchedule = api.fetchUserWorkDayScheduleByUserId(userId.toString());

  if (fetchSchedule.isLoading) {
    return <div> loading </div>
  }
  if (fetchSchedule.isError) {
    return <div>error</div>
  }

  const today = new Date();
  const schedules = (fetchSchedule.data ?? []).filter((x) => activeSchedule(today, x));
  const scheduleMap = new Map<number, UserWorkDaySchedule>();
  schedules.forEach(s => {
    scheduleMap.set(s.day, s);
  });

  if (schedules === undefined || schedules?.length === 0) {
    return <div>No working schedule</div>
  }

  return (
    <div>
      <b>Work Schedule</b><br/>
      Monday: <ScheduleDay schedule={scheduleMap.get(0)}/><br/>
      Tuesday: <ScheduleDay schedule={scheduleMap.get(1)}/><br/>
      Wednesday: <ScheduleDay schedule={scheduleMap.get(2)}/><br/>
      Thursday: <ScheduleDay schedule={scheduleMap.get(3)}/><br/>
      Friday: <ScheduleDay schedule={scheduleMap.get(4)}/><br/>
    </div>
  )
}
