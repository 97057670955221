const colors = [
  {
    name: "Booking",
    class: "booking-color",
  },
  {
    name: "Sales",
    class: "sales-color",
  },
  {
    name: "Reserved",
    class: "reserved-color",
  },
  {
    name: "Overbooked",
    class: "overbooked-color",
  },
];

export function ColorLegend() {
  return (
    <div className="flex justify-end gap-4">
      {colors.map((color, _index) => (
        <div className="flex gap-1 align-center" key={color.name}>
          <div className={`square rounded gradient-${color.class}`} />
          <div className="text-xs">{color.name}</div>
        </div>
      ))}
    </div>
  );
}
