import { SkillLevel, type User } from "../models";
import { baseURL } from "../Api";

export function userName(user: User): string {
  return `${user.given_name} ${user.family_name}`;
}

export function userInitials(user: User): string {
  return user.given_name[0] + user.family_name[0];
}

export function userImageUrl(id: string): string {
  return baseURL + "/avatars/" + id;
}

export function userSkillToString(level: SkillLevel): string {
  switch (level) {
    case SkillLevel.None:
      return "None";
    case SkillLevel.Basic:
      return "Basic";
    case SkillLevel.Intermediate:
      return "Intermedate";
    case SkillLevel.Pro:
      return "Pro";
    case SkillLevel.Advanced:
      return "Advanced";
    case SkillLevel.Evangelist:
      return "Evangelist";
  }

  return `Unknown skill level ${level}`;
}
