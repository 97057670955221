import { isAdmin } from "@/Providers/permissions";
import { Forbidden } from "@/components/Status/Forbidden";
import { ClientRenderer } from "@/components/Table/Renderers/ClientRenderer";
import { Column, Table } from "@/components/Table/Table";
import { useAuth } from "../../Providers/AuthProvider";
import { InternalError } from "../../components/Status/InternalError";
import { Loading } from "../../components/Status/Loading";
import { MainTitle } from "../../components/Typography";
import { Client } from "../../models/client";

export function ClientIndex() {
  const { api, roles } = useAuth();

  if (!isAdmin(roles)) {
    return <Forbidden />;
  }

  const fetchClients = api.fetchClients();

  if (fetchClients.isPending) {
    return <Loading />;
  }

  if (fetchClients.isError) {
    return <InternalError />;
  }

  return (
    <div>
      <MainTitle text="Clients" />
      <ClientTable clients={fetchClients.data} />
    </div>
  );
}

const columns: Column<Client>[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    cellRenderer: ClientRenderer,
    initialSort: "asc",
    filter: true,
  },
  {
    field: "total_projects",
    headerName: "Num Projects",
  },
  // {
  //   field: "total_budget_remaining",
  //   headerName: "Budget",
  //   valueParser: numberValueParser,
  //   comparator: (valueA, valueB) => valueA - valueB,
  // },
];

function ClientTable(props: { clients: Client[] }) {
  return (
    <Table
      data={props.clients}
      columns={columns}
      paginate={15}
      autoHeight={true}
    />
  );
}
