import {
  TeamAvatarsRenderer,
  TeamTableRow,
} from "@/components/Table/Renderers/TeamAvatarsRenderer";
import { TeamRenderer } from "@/components/Table/Renderers/TeamRenderer";
import { Column, Table } from "@/components/Table/Table";
import { InternalError } from "../../components/Status/InternalError";
import { Loading } from "../../components/Status/Loading";
import { MainTitle } from "../../components/Typography";
import type { TeamWithUsers } from "../../models";
import { useAuth } from "../../Providers/AuthProvider";
import { sortTeamsByName as sortTeamsWithUsersByName } from "../../utils/sort";

const columns: Column<TeamTableRow>[] = [
  {
    field: "name",
    width: 300,
    cellRenderer: TeamRenderer,
  },
  {
    field: "numMembers",
    headerName: "# Members",
    width: 130,
  },
  {
    field: "users",
    flex: 1,
    sortable: false,
    cellRenderer: TeamAvatarsRenderer,
  },
];

export function TeamIndex() {
  const { api } = useAuth();
  const { isPending, isError, data } = api.fetchTeams();

  if (isPending) {
    return <Loading />;
  }

  if (isError) {
    return <InternalError />;
  }

  return (
    <div>
      <MainTitle text="Teams" />
      <TeamTable teamsWithUsers={data} />
    </div>
  );
}

function TeamTable(props: { teamsWithUsers: TeamWithUsers[] }) {
  const sortedTeams = sortTeamsWithUsersByName(props.teamsWithUsers);
  const rowData = sortedTeams.map((twu) => ({
    name: twu.team.name,
    numMembers: twu.users.length,
    users: twu.users,
    data: twu,
  }));

  return <Table data={rowData} columns={columns} autoHeight={true} />;
}
