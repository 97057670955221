import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";
import { Navigation } from "../Layout/Navigation";
import { UserInfo } from "../Layout/UserInfo";
import { Logo } from "./Logo";
import { useSearch } from "@/Providers/SearchProvider";
import { Search } from "../Layout/Search";

export default function App() {
  const { authed } = useAuth();

  if (!authed) {
    return <Navigate to="/login" replace />;
  }

  const { visible } = useSearch();

  return (
    <div
      className={`${visible ? "blur-md" : ""} bg-gray-200 text-md w-full min-h-svh flex flex-row`}
    >
      <div className="flex flex-col justify-start w-52 h-svh fixed p-4">
        <Search />
        <Logo />
        <Navigation />
        <div className="flex-grow"></div>
        <div className="py-2 border-0 border-t">
          <UserInfo />
        </div>
      </div>
      <div className="w-full ml-52 min-h-svh py-4 pr-4 overflow-x-hidden">
        <div className="flex flex-col w-full drop-shadow h-full rounded-xl bg-white p-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
