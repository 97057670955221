import { Location, Team, TeamWithUsers, User } from "../../models";
import {
  WorkbookData,
  WorkbookDay,
  WorkbookForecast,
} from "../../models/workbook";
import {
  addWeeks,
  parseDate,
  sortLocationsByName,
  sortTeamsByName,
  sortUsersByGivenName,
  userName,
  weekEnd,
  weekStart,
} from "../../utils";
import { average } from "../../utils/math";

export type WBDateMap = [WBDateKey: WorkbookForecast];
export type SelectOption = { label: any; value: any };
export interface WorkbookFilterOptions {
  visibleColumns: {
    manager_id?: boolean;
    team_id?: boolean;
    location_id?: boolean;
    past?: boolean;
    future?: boolean;
  };
  filters: {
    manager_id?: number;
    team_ids: number[];
    location_id?: number;
    staffing_range?: number[];
    only_billable: boolean;
  };
  sortMode: string;
}

export function workbookStaffingRatioColor(ratio: number): string {
  if (ratio > 1) {
    return "bg-black text-white";
  }

  if (ratio === 1) {
    return "bg-green-500";
  }

  if (ratio >= 0.9) {
    return "bg-green-300";
  }

  if (ratio >= 0.7) {
    return "bg-orange-300";
  }

  if (ratio >= 0.5) {
    return "bg-yellow-300";
  }

  if (ratio > 0.3) {
    return "bg-red-300";
  }

  return "bg-red-500";
}

export function workbookRatioColor(ratio: number): string {
  if (ratio > 1) {
    return "bg-green-500";
  }

  if (ratio === 1) {
    return "bg-green-300";
  }

  if (ratio >= 0.8) {
    return "bg-orange-300";
  }

  if (ratio >= 0.5) {
    return "bg-yellow-300";
  }

  if (ratio > 0) {
    return "bg-red-300";
  }

  return "bg-red-500";
}

export function userManagerMap(users: Array<User>): Map<number, Array<number>> {
  const m = new Map<number, Array<number>>();
  for (const u of users) {
    if (u.manager_id === null) {
      continue;
    }

    let us = m.get(u.manager_id);

    if (us === undefined) {
      us = [];
    }

    us.push(u.id);
    m.set(u.manager_id, us);
  }

  return m;
}

export function teamHeirarchy(
  manager_id: number,
  managedUsers: Map<number, Array<number>>,
): Array<number> {
  const userIds = managedUsers.get(manager_id);

  if (userIds === undefined) {
    return [];
  }

  return [
    manager_id,
    ...userIds.flatMap((uId) => teamHeirarchy(uId, managedUsers)),
  ];
}

function selectBetweenDays(
  data: Array<WorkbookDay>,
  start_date: Date,
  end_date: Date,
): Array<WorkbookDay> {
  const out = [] as Array<WorkbookDay>;

  data.forEach((d) => {
    const dt = parseDate(d.date);
    if (dt >= start_date && dt <= end_date) {
      out.push(d);
    }
  });

  return out;
}

function isWorkDay(d: WorkbookDay): boolean {
  return !d.absence && !d.holiday;
}

function averageTrackedBillable(data: Array<WorkbookDay>): number {
  const sum = data.reduce((acc, d) => acc + d.hours_billable, 0.0);
  const days = data.reduce((acc, d) => acc + (isWorkDay(d) ? 1 : 0), 0);

  if (days === 0) {
    return 0;
  }

  return sum / days;
}

function averageTracked(data: Array<WorkbookDay>): number {
  const sum = data.reduce((acc, d) => acc + d.hours_worked, 0.0);
  const days = data.reduce((acc, d) => acc + (isWorkDay(d) ? 1 : 0), 0);

  if (days === 0) {
    return 0;
  }

  return sum / days;
}

function totalTrackedBillable(data: Array<WorkbookDay>): number {
  const sum = data.reduce((acc, d) => acc + d.hours_billable, 0.0);

  return sum;
}

function totalTracked(data: Array<WorkbookDay>): number {
  const sum = data.reduce((acc, d) => acc + d.hours_worked, 0.0);

  return sum;
}

export interface WorkbookUserStats {
  four_weeks_prior_start: Date;
  two_weeks_prior_start: Date;
  end_date: Date;
  // past 4 weeks tracking (avg)
  // past w weeks tracking (avg)
  // past 4 weeks tracking (total)
  // past 2 weeks tracking (total)
  // next 2 weeks staffing
  average_last_two_weeks: number;
  average_last_four_weeks: number;
  total_last_two_weeks: number;
  total_last_four_weeks: number;
  average_billable_last_two_weeks: number;
  average_billable_last_four_weeks: number;
  total_billable_last_two_weeks: number;
  total_billable_last_four_weeks: number;
  staffing_summary: number;
  billable_staffing_summary: number;
}

export function buildStatsForUsers(
  users: Array<User>,
  date: Date,
  wb: WorkbookData,
): Map<number, WorkbookUserStats> {
  const m = new Map<number, WorkbookUserStats>();
  users.forEach((u) => {
    const data = wb.data[u.id];
    const future = wb.future[u.id];
    const s = buildStatsForUser(date, future, data);
    m.set(u.id, s);
  });

  return m;
}

function buildBillableStaffingSummary(futureData: WBDateMap) {
  const ratios = Object.keys(futureData).map((d) => {
    const v = futureData[d as keyof WBDateMap] as WorkbookForecast;

    const p = v.possible;
    const s = v.staffed_billable;

    if (p === 0) {
      return 1;
    }

    return s / p;
  });

  return average(ratios);
}

function buildStaffingSummary(futureData: WBDateMap) {
  const ratios = Object.keys(futureData).map((d) => {
    const v = futureData[d as keyof WBDateMap] as WorkbookForecast;

    const p = v.possible;
    const s = v.staffed;

    if (p === 0) {
      return 1;
    }

    return s / p;
  });

  return average(ratios);
}

function buildStatsForUser(
  date: Date,
  futureData: WBDateMap,
  data: Array<WorkbookDay>,
): WorkbookUserStats {
  const four_weeks_prior_start = weekStart(
    addWeeks(date, -4).toDate(),
  ).toDate();
  const two_weeks_prior_start = weekEnd(addWeeks(date, -2).toDate()).toDate();

  const daysLastTwoWeeks = selectBetweenDays(data, two_weeks_prior_start, date);
  const daysLastFourWeeks = selectBetweenDays(
    data,
    four_weeks_prior_start,
    date,
  );
  const staffingSummary = buildStaffingSummary(futureData);
  const billableStaffingSummary = buildBillableStaffingSummary(futureData);

  return {
    four_weeks_prior_start: four_weeks_prior_start,
    two_weeks_prior_start: two_weeks_prior_start,
    end_date: date,
    // past 4 weeks tracking (avg)
    // past w weeks tracking (avg)
    // past 4 weeks tracking (total)
    // past 2 weeks tracking (total)
    // next 2 weeks staffing
    average_last_two_weeks: averageTracked(daysLastTwoWeeks),
    average_last_four_weeks: averageTracked(daysLastFourWeeks),
    total_last_two_weeks: totalTracked(daysLastTwoWeeks),
    total_last_four_weeks: totalTracked(daysLastFourWeeks),
    average_billable_last_two_weeks: averageTrackedBillable(daysLastTwoWeeks),
    average_billable_last_four_weeks: averageTrackedBillable(daysLastFourWeeks),

    total_billable_last_two_weeks: totalTrackedBillable(daysLastTwoWeeks),
    total_billable_last_four_weeks: totalTrackedBillable(daysLastFourWeeks),
    staffing_summary: staffingSummary,
    billable_staffing_summary: billableStaffingSummary,
  };
}

export function buildLocationOptions(
  locations: Array<Location>,
  users: Array<User>,
): Array<SelectOption> {
  const relevantLocations = locations.filter((l) =>
    users.find((u) => u.location_id === l.id),
  );
  return sortLocationsByName(relevantLocations).map((l) => ({
    value: l.id,
    label: l.name,
  }));
}

export function buildTeamOptions(
  teamsWithUsers: Array<TeamWithUsers>,
): Array<SelectOption> {
  const teams = sortTeamsByName(teamsWithUsers).map((tu) => tu.team);
  return teams.map((t) => ({ value: t.id, label: t.name }));
}

export function buildManagerOptions(users: Array<User>): Array<SelectOption> {
  const managedUsers = userManagerMap(users);
  const managers = users.filter(
    (u) => (managedUsers.get(u.id) ?? []).length > 1,
  );
  return sortUsersByGivenName(managers).map((u) => ({
    value: u.id,
    label: userName(u),
  }));
}

export function filterUsers(
  users: Array<User>,
  ignoredUsers: Array<User>,
  userTeams: Map<number, Team>,
  userStats: Map<number, WorkbookUserStats>,
  future: [number: WBDateMap],
  options: WorkbookFilterOptions,
): Array<User> {
  const managedUsers = userManagerMap(users);

  const filteredManagerId = options.filters.manager_id;
  const filteredTeamIds = options.filters.team_ids ?? [];
  const filteredLocationId = options.filters.location_id;
  const filteredStaffingRange = options.filters.staffing_range;

  return users.filter((u) => {
    if (filteredLocationId) {
      if (filteredLocationId !== u.location_id) {
        return false;
      }
    }

    if (filteredManagerId) {
      const userIds = teamHeirarchy(filteredManagerId, managedUsers);
      if (u.manager_id === null) {
        return false;
      }

      if (userIds.indexOf(u.manager_id) < 0) {
        return false;
      }
    }

    if (filteredTeamIds.length > 0) {
      const team = userTeams.get(u.id);
      if (team === undefined) {
        return false;
      }

      if (filteredTeamIds.indexOf(team.id) < 0) {
        return false;
      }
    }

    if (filteredStaffingRange) {
      const r = options.filters.staffing_range!;
      const start = r[0] / 100.0;
      const end = r[1] === 100 ? 600 : r[1] / 100.0; // if it's maxed, show things over

      const billable = options.filters.only_billable!;
      const s = userStats.get(u.id);
      const util =
        (billable ? s?.billable_staffing_summary : s?.staffing_summary) ?? 0;

      if (util < start || util > end) {
        return false;
      }
    }

    if (ignoredUsers.find((iu) => iu.id === u.id)) {
      return false;
    }

    return true;
  });
}

export function buildUserTeams(
  teamsWithUsers: Array<TeamWithUsers>,
): Map<number, Team> {
  const userTeams = new Map<number, Team>();
  teamsWithUsers.forEach((tu) => {
    const t = tu.team;
    tu.users.forEach((u) => {
      userTeams.set(u.id, t);
    });
  });
  return userTeams;
}

export function sortUsers(
  users: Array<User>,
  stats: Map<number, WorkbookUserStats>,
  options: WorkbookFilterOptions,
) {
  const sortMode = options.sortMode;

  if (sortMode === "users") {
    return sortUsersByGivenName(users);
  }

  if (sortMode === "staffing|desc") {
    return sortUsersByStaffing(users, stats, options).reverse();
  }

  if (sortMode === "staffing|asc") {
    return sortUsersByStaffing(users, stats, options);
  }

  return [];
}

function sortUsersByStaffing(
  users: Array<User>,
  stats: Map<number, WorkbookUserStats>,
  options: WorkbookFilterOptions,
) {
  // Sort by given_name + family_name. Otherwise results are inconsistent when names are repeated
  return [...users].sort((a, b) => {
    const billable = options.filters?.only_billable;
    const staffingField = billable
      ? "billable_staffing_summary"
      : "staffing_summary";

    const sa = stats.get(a.id)!;
    const sb = stats.get(b.id)!;

    const aStaff = sa[staffingField] ?? 0;
    const bStaff = sb[staffingField] ?? 0;

    if (aStaff < bStaff) {
      return -1;
    }
    if (aStaff > bStaff) {
      return 1;
    }
    return 0;
  });
}

function bytesToBase64(bytes: any) {
  const binString = Array.from(bytes, (byte) =>
    String.fromCodePoint(byte as any),
  ).join("");
  return btoa(binString);
}
function base64ToBytes(base64: any) {
  const binString = atob(base64);
  return Uint8Array.from<string>(
    binString,
    (v: string, _: number) => v.codePointAt(0) ?? 0,
  );
}

export function paramsToOptions(
  searchParams: URLSearchParams,
): WorkbookFilterOptions | undefined {
  const d = searchParams.get("q");
  if (d === undefined) {
    return undefined;
  }

  try {
    const js = new TextDecoder().decode(base64ToBytes(d));
    return JSON.parse(js) as WorkbookFilterOptions;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export function options2params(options: WorkbookFilterOptions): { q: string } {
  const d = JSON.stringify(options);
  const b = bytesToBase64(new TextEncoder().encode(d));
  return { q: b };
}
