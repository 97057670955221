import { useParams } from "react-router-dom";
import { Forbidden } from "../../../components/Status/Forbidden";
import { InternalError } from "../../../components/Status/InternalError";
import { Loading } from "../../../components/Status/Loading";
import { NotFound } from "../../../components/Status/NotFound";
import { LinkToUser } from "../../../components/Users/LinkToUser";
import { User } from "../../../models";
import {
  StaffingIssues,
  StaffingIssuesReport,
  StaffingIssueUser,
} from "../../../models/reports/staffing_issues";
import { useAuth } from "../../../Providers/AuthProvider";
import { canViewWorkbook } from "../../../Providers/permissions";
import {
  createDateFromYearCW,
  formatFriendlyDate,
  groupUsersById,
  parseNumber,
} from "../../../utils";
import { LinkToProject } from "../../../utils/project";

export function StaffingIssuesIndex() {
  const { api, roles } = useAuth();
  const { year, cw } = useParams();

  if (year === undefined || cw === undefined) {
    return <NotFound />;
  }

  if (!canViewWorkbook(roles)) {
    return <Forbidden />;
  }

  const weekDate = createDateFromYearCW(year, cw);

  const fetchReport = api.fetchReportStaffingIssues(weekDate);

  if (fetchReport.isPending) {
    return <Loading />;
  }

  if (fetchReport.isError) {
    return <InternalError />;
  }

  return <StaffingIssuesReportX date={weekDate} data={fetchReport.data} />;
}

function sumTotalIssues(d: StaffingIssues): {
  tracked: number;
  staffed: number;
} {
  let tracked = 0;
  let staffed = 0;

  d.report.forEach((reportData) => {
    const userIds = Object.keys(reportData.data);

    userIds.forEach((uId) => {
      const key = uId as string;
      const data = reportData.data[key as any];
      const weeks = Object.keys(data);

      weeks.forEach((w) => {
        const x = data[w];
        staffed += parseNumber(x.staffed);
        tracked += parseNumber(x.tracked);
      });
    });
  });

  return {
    tracked: tracked,
    staffed: staffed,
  };
}

function StaffingIssuesReportX(props: { date: Date; data: StaffingIssues }) {
  const projects = props.data.projects;
  const users = props.data.users;

  const groupedUsers = groupUsersById(users);

  const projectReportData = new Map<number, StaffingIssuesReport>();
  props.data.report.forEach((p) => {
    const projectId = p.project_id;
    projectReportData.set(projectId, p);
  });

  const date = formatFriendlyDate(props.date);
  const totalIssues = sumTotalIssues(props.data);

  return (
    <div>
      <b>Sum of staffing where nobody tracked:</b> {totalIssues.staffed} hours
      <br />
      <b>Date: {date}</b>
      <br />
      <b>Sum of tracking on projects where user was not staffed:</b>{" "}
      {totalIssues.tracked} hours
      <br />
      {projects.map((project) => {
        const data = projectReportData.get(project.id)!;
        const userIds = [
          ...new Set([data.not_staffed, data.not_tracked].flat()),
        ];
        return (
          <div key={project.id}>
            <b>
              <LinkToProject project={project} />
            </b>
            <br />
            <RenderUsers
              userIds={userIds}
              data={data.data}
              groupedUsers={groupedUsers}
            />
            <br />
          </div>
        );
      })}
    </div>
  );
}

function RenderUsers(props: {
  userIds: Array<string>;
  groupedUsers: Map<number, User>;
  data: [string: StaffingIssueUser];
}) {
  return (
    <div>
      {props.userIds.map((uId) => {
        const user = props.groupedUsers.get(parseInt(uId));
        const userData = props.data[uId as any];

        return (
          <div key={uId}>
            {Object.keys(userData).map((week) => {
              const staffed = userData[week].staffed;
              const tracked = userData[week].tracked;

              return (
                <div key={week}>
                  <LinkToUser user={user!} />
                  Staffed: {staffed}, Tracked: {tracked}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
