import { Checkbox, Popover, Slider } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Card, Form, Select } from "../../components/Antd";
import { LinkToManager, LinkToUser } from "../../components/Users/LinkToUser";
import {
  buildLocationOptions,
  buildManagerOptions,
  buildStatsForUsers,
  buildTeamOptions,
  buildUserTeams,
  filterUsers,
  options2params,
  SelectOption,
  sortUsers,
  WBDateMap,
  WorkbookFilterOptions,
  workbookRatioColor,
  workbookStaffingRatioColor,
  WorkbookUserStats,
} from "../../components/Workbook/util";
import { Location, Team, TeamWithUsers, User } from "../../models";
import {
  WBDateKey,
  WorkbookData,
  WorkbookDay,
  WorkbookForecast,
} from "../../models/workbook";
import {
  addWeeks,
  date2weekNum,
  daysBetweenDates,
  formatFriendlyDateWithoutYear,
  groupLocationsById,
  groupUsersById,
  isoWeekday,
  LinkToTeam,
  parseDate,
  sortUsersByGivenName,
} from "../../utils";
import { LinkToLocation } from "../../utils/location";
import { average } from "../../utils/math";

interface WorkbookTableProps {
  options: WorkbookFilterOptions;
  locations: Array<Location>;
  days: Array<WBDateKey>;
  futureDays: Array<WBDateKey>;
  usersById: Map<number, User>;
  userTeams: Map<number, Team>;
  userStats: Map<number, WorkbookUserStats>;
  users: Array<User>;
  ignoredUsers: Array<User>;
  workbook: WorkbookData;
}

function isVisible(v: boolean | undefined): boolean {
  return v === true;
}

function WorkbookTableHeaderCell(props: {
  text: string;
  popoverText?: string;
  visible: boolean | undefined;
}) {
  if (!isVisible(props.visible)) {
    return <></>;
  }

  if (props.popoverText) {
    return (
      <td className="text-nowrap sticky top-0 bg-white font-bold">
        <Popover content={props.popoverText}>{props.text}</Popover>
      </td>
    );
  }

  return (
    <td className="text-nowrap z-index-100 sticky top-0 bg-white font-bold">
      {props.text}
    </td>
  );
}

function WorkbookTableUserManager(props: {
  manager: User | undefined;
  visible: boolean | undefined;
}) {
  if (!isVisible(props.visible)) {
    return <></>;
  }
  const user = props.manager ?? null;
  return (
    <td>
      <LinkToManager user={user} />
    </td>
  );
}

function WorkbookTableFutureDays(props: {
  days: Array<WBDateKey>;
  visible: boolean | undefined;
}) {
  if (!isVisible(props.visible)) {
    return <></>;
  }

  const days = props.days;
  return (
    <>
      {days.map((d) => {
        const dt = parseDate(d);
        const text = `CW${date2weekNum(dt)}`;
        return (
          <WorkbookTableHeaderCell key={text} text={text} visible={true} />
        );
      })}
      <WorkbookTableHeaderCell
        text="Util"
        popoverText="Average Staffing Utilization for next 7 weeks"
        visible={true}
      />
    </>
  );
}

function WorkbookTablePastDays(props: {
  days: Array<WBDateKey>;
  visible: boolean | undefined;
}) {
  if (!isVisible(props.visible)) {
    return <></>;
  }

  const days = props.days;
  return (
    <>
      {days.map((d) => {
        const dt = parseDate(d);
        const text = formatFriendlyDateWithoutYear(dt);
        return (
          <WorkbookTableHeaderCell
            key={d.toString()}
            text={text}
            visible={true}
          />
        );
      })}
    </>
  );
}
function WorkbookFutureDaysSummary(props: {
  users: Array<User>;
  workbook: WorkbookData;
  days: Array<WBDateKey>;
  userStats: Map<number, WorkbookUserStats>;
  options: WorkbookFilterOptions;
  visible: boolean | undefined;
}) {
  if (!isVisible(props.visible)) {
    return <></>;
  }

  const days = props.days;
  const billable = props.options?.filters?.only_billable || false;

  const staffingValues = props.users.map((u) => {
    const s = props.userStats.get(u.id);
    return (billable ? s?.billable_staffing_summary : s?.staffing_summary) ?? 0;
  });

  const staffingAverage = average(staffingValues);
  const staffingAverageText = `${(staffingAverage * 100).toFixed(0)}%`;

  const future = props.workbook.future;

  return (
    <>
      {days.map((d) => {
        let staffed = 0;
        let possible = 0;

        props.users.forEach((u) => {
          const uf = future[u.id];
          const f = uf[d as keyof WBDateMap] as WorkbookForecast;

          staffed += billable ? f.staffed_billable : f.staffed;
          possible += f.possible;
        });

        const numEntries = props.users.length;
        const avgStaffed = numEntries > 0 ? staffed / numEntries : 0;
        const avgPossible = numEntries > 0 ? possible / numEntries : 0;
        const ratio = avgPossible > 0 ? avgStaffed / avgPossible : 1;

        let className = "text-center ";
        className += workbookStaffingRatioColor(ratio);

        return (
          <td key={d} className={className}>
            <b>{avgStaffed.toFixed(1)}</b> | {avgPossible.toFixed(1)}
          </td>
        );
      })}

      <td className="text-right">{staffingAverageText}</td>
    </>
  );
}

function WorkbookFutureDays(props: {
  stats: WorkbookUserStats;
  days: Array<WBDateKey>;
  futureDays: WBDateMap;
  options: WorkbookFilterOptions;
  visible: boolean | undefined;
}) {
  if (!isVisible(props.visible)) {
    return <></>;
  }

  const days = props.days;
  const data = props.futureDays;

  const billable = props.options?.filters?.only_billable || false;
  const staffingUtil = billable
    ? props.stats.billable_staffing_summary
    : props.stats.staffing_summary;
  const staffingUtilText = (staffingUtil * 100).toFixed(0);

  return (
    <>
      {days.map((d) => {
        if (data === undefined) {
          return <td key={d}>??</td>;
        }

        const dt = data[d as keyof WBDateMap] as WorkbookForecast;

        if (dt === undefined) {
          return <td key={d}>!!</td>;
        }
        return (
          <WorkfookFutureCell
            key={d}
            data={dt}
            billable={props.options.filters.only_billable}
          />
        );
      })}
      <td className="text-right">{staffingUtilText}%</td>
    </>
  );
}

function WorkfookFutureCell(props: {
  data: WorkbookForecast;
  billable: boolean;
}) {
  const dt = props.data;

  const hours = props.billable ? dt.staffed_billable : dt.staffed;

  let ratio = 0;
  if (dt.possible === 0) {
    ratio = 1;
  } else {
    ratio = hours / dt.possible;
  }

  let className = "text-center ";
  className += workbookStaffingRatioColor(ratio);

  const staffed = hours.toFixed(1);
  const possible = dt.possible.toFixed(1);

  return (
    <td className={className}>
      <b>{staffed}</b> | {possible}{" "}
    </td>
  );
}

function WorkbookPastDaysSummary(props: {
  users: Array<User>;
  days: Array<WBDateKey>;
  workbook: WorkbookData;
  options: WorkbookFilterOptions;
  visible: boolean | undefined;
}) {
  if (!isVisible(props.visible)) {
    return <></>;
  }
  const days = props.days;

  return (
    <>
      {days.map((d) => {
        return <td key={d}>past</td>;
      })}
    </>
  );
}
function WorkbookPastDays(props: {
  days: Array<WBDateKey>;
  data: Array<WorkbookDay>;
  options: WorkbookFilterOptions;
  visible: boolean | undefined;
}) {
  if (!isVisible(props.visible)) {
    return <></>;
  }
  const days = props.days;
  const data = props.data;

  return (
    <>
      {days.map((d) => {
        const key = `wbpd-${d}`;
        const dt = data.find((x) => x.date === d);
        return (
          <WorkbookDayCell
            key={key}
            data={dt}
            billable={props.options.filters?.only_billable}
          />
        );
      })}
    </>
  );
}

function WorkbookStatColumnHeaders(props: { options: WorkbookFilterOptions }) {
  const billable = props.options.filters.only_billable;

  if (billable) {
    return (
      <>
        <WorkbookTableHeaderCell
          text="BLast4Wks"
          popoverText="Average billable tracked last 4 weeks"
          visible={true}
        />
        <WorkbookTableHeaderCell
          text="BLast2Wks"
          popoverText="Average billable tracked last 2 weeks"
          visible={true}
        />
        <WorkbookTableHeaderCell
          text="BT4Wks"
          popoverText="Total billable tracked last 4 weeks"
          visible={true}
        />
        <WorkbookTableHeaderCell
          text="BT2Wks"
          popoverText="Total billable tracked last 2 weeks"
          visible={true}
        />
      </>
    );
  }

  return (
    <>
      <WorkbookTableHeaderCell
        text="Last4Wks"
        popoverText="Average tracked last 4 weeks"
        visible={true}
      />
      <WorkbookTableHeaderCell
        text="Last2Wks"
        popoverText="Average tracked last 2 weeks"
        visible={true}
      />
      <WorkbookTableHeaderCell
        text="T4Wks"
        popoverText="Total tracked last 4 weeks"
        visible={true}
      />
      <WorkbookTableHeaderCell
        text="T2Wks"
        popoverText="Total tracked last 2 weeks"
        visible={true}
      />
    </>
  );
}

function WorkbookStatColumns(props: {
  stats: WorkbookUserStats;
  options: WorkbookFilterOptions;
}) {
  const billable = props.options.filters.only_billable;
  const stats = props.stats;

  if (billable) {
    return (
      <>
        <WorkbookDayStatAverageCell
          target={8}
          value={stats.average_billable_last_four_weeks}
        />
        <WorkbookDayStatAverageCell
          target={8}
          value={stats.average_billable_last_two_weeks}
        />
        <WorkbookDayStatAverageCell
          target={8 * 20}
          value={stats.total_billable_last_four_weeks}
        />
        <WorkbookDayStatAverageCell
          target={8 * 10}
          value={stats.total_billable_last_two_weeks}
        />
      </>
    );
  }

  return (
    <>
      <WorkbookDayStatAverageCell
        target={8}
        value={stats.average_last_four_weeks}
      />
      <WorkbookDayStatAverageCell
        target={8}
        value={stats.average_last_two_weeks}
      />
      <WorkbookDayStatAverageCell
        target={8 * 20}
        value={stats.total_last_four_weeks}
      />
      <WorkbookDayStatAverageCell
        target={8 * 10}
        value={stats.total_last_two_weeks}
      />
    </>
  );
}

function WorkbookStatColumnsSummary(props: {
  users: Array<User>;
  userStats: Map<number, WorkbookUserStats>;
  options: WorkbookFilterOptions;
}) {
  const billable = props.options.filters.only_billable;
  const userStats = props.userStats;

  const averageLastFourWeeks = average(
    props.users.map((u) => {
      const s = userStats.get(u.id);
      return (
        (billable
          ? s?.average_billable_last_four_weeks
          : s?.average_last_four_weeks) ?? 0
      );
    }),
  );
  const averageLastTwoWeeks = average(
    props.users.map((u) => {
      const s = userStats.get(u.id);
      return (
        (billable
          ? s?.average_billable_last_two_weeks
          : s?.average_last_two_weeks) ?? 0
      );
    }),
  );
  const averageTrackedLastFourWeeks = average(
    props.users.map((u) => {
      const s = userStats.get(u.id);
      return (
        (billable
          ? s?.total_billable_last_four_weeks
          : s?.total_last_four_weeks) ?? 0
      );
    }),
  );
  const averageTrackedLastTwoWeeks = average(
    props.users.map((u) => {
      const s = userStats.get(u.id);
      return (
        (billable
          ? s?.total_billable_last_two_weeks
          : s?.total_last_two_weeks) ?? 0
      );
    }),
  );

  return (
    <>
      <WorkbookDayStatAverageCell target={8} value={averageLastFourWeeks} />
      <WorkbookDayStatAverageCell target={8} value={averageLastTwoWeeks} />
      <WorkbookDayStatAverageCell
        target={8}
        value={averageTrackedLastFourWeeks}
      />
      <WorkbookDayStatAverageCell
        target={8}
        value={averageTrackedLastTwoWeeks}
      />
    </>
  );
}

function WorkbookTable(props: WorkbookTableProps) {
  const locations = props.locations;
  const days = props.days;
  const futureDays = props.futureDays;
  const userTeams = props.userTeams;
  const userStats = props.userStats;
  const users = props.users;
  const usersById = props.usersById;
  const wb = props.workbook;
  const options = props.options;

  const locationsById = groupLocationsById(locations);
  const filteredUsers = filterUsers(
    users,
    props.ignoredUsers,
    userTeams,
    userStats,
    wb.future,
    props.options,
  );
  const sortedUsers = sortUsers(filteredUsers, userStats, props.options);
  const debugMode = false;

  return (
    <>
      {debugMode && (
        <>
          <pre>{JSON.stringify(options)}</pre>
        </>
      )}
      <table border={1}>
        <thead>
          <tr>
            <WorkbookTableHeaderCell text="Person" visible={true} />
            <WorkbookTableHeaderCell
              text="Manager"
              visible={options.visibleColumns.manager_id}
            />
            <WorkbookTableHeaderCell
              text="Team"
              visible={options.visibleColumns.team_id}
            />
            <WorkbookTableHeaderCell
              text="Location"
              visible={options.visibleColumns.location_id}
            />
            <WorkbookTableHeaderCell text="Target" visible={false} />
            <WorkbookStatColumnHeaders options={options} />
            <WorkbookTablePastDays
              days={days}
              visible={options.visibleColumns.past}
            />
            <WorkbookTableFutureDays
              days={futureDays}
              visible={options.visibleColumns.future}
            />
          </tr>
        </thead>
        <tbody>
          {sortedUsers.map((u) => {
            const data = wb.data[u.id];
            const future = wb.future[u.id];
            const manager = usersById.get(u.manager_id ?? 0);
            const stats = userStats.get(u.id)!;
            const team = userTeams.get(u.id);
            const location = locationsById.get(u.location_id ?? 0);

            if (stats === undefined) {
              throw "user has no stats";
            }

            return (
              <tr key={u.id}>
                <td>
                  <LinkToUser user={u} />
                </td>
                <WorkbookTableUserManager
                  manager={manager}
                  visible={options.visibleColumns.manager_id}
                />
                <WorkbookTeamColumn
                  team={team}
                  visible={options.visibleColumns.team_id}
                />
                <WorkbookLocationColumn
                  location={location}
                  visible={options.visibleColumns.location_id}
                />
                <WorkbookUserTargetColumn user={u} visible={false} />
                <WorkbookStatColumns stats={stats} options={options} />
                <WorkbookPastDays
                  days={days}
                  data={data}
                  options={options}
                  visible={options.visibleColumns["past"]}
                />
                <WorkbookFutureDays
                  stats={stats}
                  days={futureDays}
                  options={options}
                  futureDays={future}
                  visible={options.visibleColumns.future}
                />
              </tr>
            );
          })}
          <tr>
            <WorkbookTableHeaderCell text="Person" visible={true} />
            <WorkbookTableHeaderCell
              text="Manager"
              visible={options.visibleColumns.manager_id}
            />
            <WorkbookTableHeaderCell
              text="Team"
              visible={options.visibleColumns.team_id}
            />
            <WorkbookTableHeaderCell
              text="Location"
              visible={options.visibleColumns.location_id}
            />
            <WorkbookTableHeaderCell text="Target" visible={false} />
            <WorkbookStatColumnsSummary
              users={sortedUsers}
              userStats={userStats}
              options={options}
            />
            <WorkbookPastDaysSummary
              users={sortedUsers}
              days={days}
              workbook={wb}
              options={options}
              visible={options.visibleColumns.past}
            />
            <WorkbookFutureDaysSummary
              users={sortedUsers}
              userStats={userStats}
              days={futureDays}
              workbook={wb}
              options={options}
              visible={options.visibleColumns.future}
            />
          </tr>
        </tbody>
      </table>
    </>
  );
}

function WorkbookUserTargetColumn(props: {
  user: User | undefined;
  visible: boolean | undefined;
}) {
  if (!isVisible(props.visible)) {
    return <></>;
  }

  const u = props.user;

  return <td>{u?.working_hours}</td>;
}

function WorkbookTeamColumn(props: {
  team: Team | undefined;
  visible: boolean | undefined;
}) {
  if (!isVisible(props.visible)) {
    return <></>;
  }

  const l = props.team;

  return (
    <td>
      <LinkToTeam team={l} />
    </td>
  );
}

function WorkbookLocationColumn(props: {
  location: Location | undefined;
  visible: boolean | undefined;
}) {
  if (!isVisible(props.visible)) {
    return <></>;
  }

  const l = props.location;

  return (
    <td>
      <LinkToLocation location={l} />
    </td>
  );
}

function WorkbookDayStatAverageCell(props: { target: number; value: number }) {
  const target = props.target;
  const value = props.value;
  const ratio = target > 0 ? value / target : 0;

  let className = "text-center ";

  className += workbookRatioColor(ratio);

  return <td className={className}>{props.value.toFixed(1)}</td>;
}

function WorkbookDayCell(props: {
  data: WorkbookDay | undefined;
  billable: boolean;
}) {
  const d = props.data;

  if (d === undefined) {
    return <td className="bg-red-500 text-center">E</td>;
  }

  if (d.holiday) {
    return <td className="bg-gray-400 text-center font-bold">H</td>;
  }

  if (d.absence) {
    return <td className="bg-gray-300 text-center font-bold">A</td>;
  }

  const hours = props.billable ? d.hours_billable : d.hours_worked;

  const ratio = hours / d.possible;

  let className = "text-center ";
  className += workbookRatioColor(ratio);

  return <td className={className}>{hours}</td>;
}

function WorkbookOptionInput(props: {
  options?: WorkbookFilterOptions;
  onChange: (x: WorkbookFilterOptions) => void;
  locationOptions: Array<SelectOption>;
  teamOptions: Array<SelectOption>;
  managerOptions: Array<SelectOption>;
}) {
  const onChange = props.onChange;
  const o = props.options;

  const visible = o?.visibleColumns;

  const [showLocation, setShowLocation] = useState(
    visible?.location_id ?? defaultFilterOptions.visibleColumns.location_id,
  );
  const [showTeam, setShowTeam] = useState(
    visible?.team_id ?? defaultFilterOptions.visibleColumns.team_id,
  );
  const [showManager, setShowManager] = useState(
    visible?.manager_id ?? defaultFilterOptions.visibleColumns.manager_id,
  );
  const [showPast, setShowPast] = useState(
    visible?.past ?? defaultFilterOptions.visibleColumns.past,
  );
  const [showFuture, setShowFuture] = useState(
    visible?.future ?? defaultFilterOptions.visibleColumns.future,
  );
  const [showOnlyBillable, setShowOnlyBillable] = useState(
    visible?.future ?? defaultFilterOptions.filters.only_billable,
  );
  const [selectedManagerId, setSelectedManagerId] = useState(
    o?.filters?.manager_id ?? defaultFilterOptions.filters.manager_id,
  );
  const [selectedTeamIds, setSelectedTeamIds] = useState<number[]>(
    o?.filters?.team_ids ?? defaultFilterOptions.filters.team_ids,
  );
  const [selectedLocationId, setSelectedLocationId] = useState(
    o?.filters?.location_id ?? defaultFilterOptions.filters.location_id,
  );
  const [staffingRange, setStaffingRange] = useState<number[]>(
    o?.filters?.staffing_range ??
      (defaultFilterOptions.filters.staffing_range as number[]),
  );
  const [sortMode, setSortMode] = useState(
    o?.sortMode ?? defaultFilterOptions.sortMode,
  );

  const [_, setSearchParams] = useSearchParams();
  const sortModeOptions = [
    { label: "Names - Alphabetically", value: "users" },
    { label: "Staffing - Worst to Best", value: "staffing|asc" },
    { label: "Staffing - Best to Worst", value: "staffing|desc" },
  ];

  useEffect(() => {
    const opts = {
      sortMode: sortMode,
      filters: {
        manager_id: selectedManagerId,
        team_ids: selectedTeamIds,
        location_id: selectedLocationId,
        staffing_range: staffingRange,
        only_billable: showOnlyBillable,
      },
      visibleColumns: {
        location_id: showLocation,
        team_id: showTeam,
        manager_id: showManager,
        past: showPast,
        future: showFuture,
      },
    };

    const params = options2params(opts);
    setSearchParams(params);

    onChange(opts);
  }, [
    selectedManagerId,
    selectedTeamIds,
    selectedLocationId,
    staffingRange,
    showLocation,
    showTeam,
    showManager,
    showPast,
    showFuture,
    showOnlyBillable,
    sortMode,
  ]);

  return (
    <Form layout="vertical" size="small">
      <Form.Item
        label="Manager"
        style={{ display: "inline-block", width: "180px" }}
      >
        <Select
          optionFilterProp="label"
          showSearch
          allowClear={true}
          defaultValue={selectedManagerId}
          style={{ width: "170px" }}
          onChange={setSelectedManagerId}
          options={props.managerOptions}
        />
      </Form.Item>
      <Form.Item
        label="Team"
        style={{ display: "inline-block", width: "210px" }}
      >
        <Select
          defaultValue={selectedTeamIds}
          mode="multiple"
          optionFilterProp="label"
          showSearch
          allowClear={true}
          style={{ width: "200px" }}
          onChange={setSelectedTeamIds}
          options={props.teamOptions}
        />
      </Form.Item>
      <Form.Item
        label="Location"
        style={{ display: "inline-block", width: "180px" }}
      >
        <Select
          defaultValue={selectedLocationId}
          optionFilterProp="label"
          showSearch
          allowClear={true}
          style={{ width: "170px" }}
          onChange={setSelectedLocationId}
          options={props.locationOptions}
        />
      </Form.Item>
      <Form.Item
        label="Options"
        style={{ display: "inline-block", width: "310px" }}
      >
        <Checkbox
          checked={showLocation}
          onChange={() => setShowLocation(!showLocation)}
        >
          Location
        </Checkbox>
        <Checkbox
          checked={showManager}
          onChange={() => setShowManager(!showManager)}
        >
          Manager
        </Checkbox>
        <Checkbox checked={showTeam} onChange={() => setShowTeam(!showTeam)}>
          Team
        </Checkbox>
        <Checkbox checked={showPast} onChange={() => setShowPast(!showPast)}>
          Past
        </Checkbox>
        <Checkbox
          checked={showFuture}
          onChange={() => setShowFuture(!showFuture)}
        >
          Future
        </Checkbox>
        <Checkbox
          checked={showOnlyBillable}
          onChange={() => setShowOnlyBillable(!showOnlyBillable)}
        >
          OnlyBillable
        </Checkbox>
      </Form.Item>
      <Form.Item
        label="Sort"
        style={{ display: "inline-block", width: "210px" }}
      >
        <Select
          defaultValue={sortMode}
          style={{ width: "200px" }}
          onChange={setSortMode}
          options={sortModeOptions}
        />
      </Form.Item>
      <Form.Item
        label="Filter"
        style={{ display: "inline-block", width: "210px" }}
      >
        <Slider
          range
          defaultValue={staffingRange}
          onChange={setStaffingRange}
        />
      </Form.Item>
    </Form>
  );
}

interface WorkbookInnerProps {
  locationOptions: Array<SelectOption>;
  teamOptions: Array<SelectOption>;
  managerOptions: Array<SelectOption>;
  options?: WorkbookFilterOptions;

  locations: Array<Location>;
  workbook: WorkbookData;
  days: Array<WBDateKey>;
  futureDays: Array<WBDateKey>;
  usersById: Map<number, User>;
  users: Array<User>;
  ignoredUsers: Array<User>;
  userTeams: Map<number, Team>;
  userStats: Map<number, WorkbookUserStats>;
}

const defaultFilterOptions = {
  filters: {
    team_ids: [] as number[],
    staffing_range: [0, 100],
  },
  visibleColumns: {
    future: true,
    past: false,
    location_id: true,
    manager_id: true,
    team_id: true,
  },
  sortMode: "users",
} as WorkbookFilterOptions;

function WorkbookInner(props: WorkbookInnerProps) {
  const [options, setOptions] = useState<WorkbookFilterOptions>(
    props.options ?? defaultFilterOptions,
  );

  return (
    <>
      <WorkbookOptionInput
        options={options}
        locationOptions={props.locationOptions}
        teamOptions={props.teamOptions}
        managerOptions={props.managerOptions}
        onChange={setOptions}
      />

      <WorkbookTable
        options={options}
        locations={props.locations}
        workbook={props.workbook}
        days={props.days}
        futureDays={props.futureDays}
        usersById={props.usersById}
        users={props.users}
        ignoredUsers={props.ignoredUsers}
        userTeams={props.userTeams}
        userStats={props.userStats}
      />
    </>
  );
}

export function Workbook(props: {
  options?: WorkbookFilterOptions;
  ignoredUsers: Array<User>;
  locations: Array<Location>;
  teamsWithUsers: Array<TeamWithUsers>;
  workbook: WorkbookData;
}) {
  const wb = props.workbook;
  const startDate = parseDate(wb.start_date);
  const endDate = parseDate(wb.end_date);
  const days = daysBetweenDates(startDate, endDate).filter(
    (d) => isoWeekday(d) <= 5,
  ) as Array<WBDateKey>;
  const futureDays = daysBetweenDates(
    endDate,
    addWeeks(endDate, 6).toDate(),
  ).filter((d) => isoWeekday(d) === 1) as Array<WBDateKey>;
  const users = sortUsersByGivenName(wb.users);
  const usersById = groupUsersById(users);
  const userStats = buildStatsForUsers(users, endDate, wb);

  console.log("render workbook");

  const locationOptions = buildLocationOptions(props.locations, users);
  const teamOptions = buildTeamOptions(props.teamsWithUsers);
  const userTeams = buildUserTeams(props.teamsWithUsers);
  const managerOptions = buildManagerOptions(users);

  return (
    <Card title={`${wb.start_date} - ${wb.end_date}`} size="small">
      <WorkbookInner
        options={props.options}
        locationOptions={locationOptions}
        teamOptions={teamOptions}
        managerOptions={managerOptions}
        workbook={wb}
        locations={props.locations}
        days={days}
        futureDays={futureDays}
        usersById={usersById}
        users={users}
        ignoredUsers={props.ignoredUsers}
        userTeams={userTeams}
        userStats={userStats}
      />
    </Card>
  );
}
