import chroma from "chroma-js";
import type { WeekMetadata, WeekSummary } from "../../models";
import { BookingMode } from "../../models";
import {
  bookingColor,
  salesColor,
  reservedColor,
  overbookedColor,
} from "../../models/colors";

const transparent = "white";
const bookingColorScale = chroma.scale([transparent, bookingColor]);
const salesColorScale = chroma.scale([transparent, salesColor]);
const reservedColorScale = chroma.scale([transparent, reservedColor]);

function getBookingColor(v: number) {
  return bookingColorScale(v).toString();
}

function getSalesColor(v: number) {
  return salesColorScale(v).toString();
}

function getReservedColor(v: number) {
  return reservedColorScale(v).toString();
}

function boost(v: number): number {
  return v * 1.4;
}

export function colorForCellEntry(ratio: number): string {
  if (ratio >= 1) {
    return bookingColor;
  }

  if (ratio <= 0.35) {
    return overbookedColor;
  }

  return getBookingColor(ratio);
}

export function colorForCell(amount: number, expectation: number): string {
  const ratio = amount / expectation;

  if (ratio >= 1) {
    return bookingColor;
  }

  if (ratio <= 0.35) {
    return overbookedColor;
  }

  return getBookingColor(ratio);
  //   const c = chroma.scale([overbookedColor, 'yellow']);
  //   return c(normalize(0.35, 0, ratio)).toString();
  // }
  //
  // if (ratio <=0.65) {
  //   const c = chroma.scale([overbookedColor, 'yellow']);
  //   return c(normalize(0.65, 0.35, ratio)).toString();
  // }
  //
  // const c = chroma.scale(['yellow', bookingColor]);
  // return c(normalize(1, 0.65, ratio)).toString();
}

// function normalize(max:number, min:number, num : number) {
//   return (num - min) / (max - min)
// }

export function colorForSummary(ws: WeekSummary): string {
  const totalHours = 40; // this should probably be the booking amount
  const percentBooking = ws.kinds.booked / totalHours;
  const percentSales = ws.kinds.sales / totalHours;
  const percentReserved = ws.kinds.reserved / totalHours;

  const colorBooked = getBookingColor(boost(percentBooking));
  const colorSales = getSalesColor(boost(percentSales));
  const colorReserved = getReservedColor(boost(percentReserved));

  // for entries with zero hours, make the text for the amount less visible
  // so that the user doesn't draw their eyes to it so quickly
  if (percentBooking === 0 && percentSales === 0 && percentReserved === 0) {
    return "#fff";
  }

  // helper for debugging
  if (percentBooking > 0 || percentSales > 0 || percentReserved > 0) {
    //console.log([percentBooking, percentSales, percentReserved])
  }

  const col = chroma.average(
    ["black", colorBooked, colorSales, colorReserved],
    "lch",
    [0, percentBooking, percentSales, percentReserved],
  );

  return col.toString();
}

export function bookingStyle(
  ws: WeekSummary,
  mode?: BookingMode,
): CSSStyleDeclaration {
  const col = colorForSummary(ws);

  let style = allocationStyle({
    borderColor: col,
    text: "black",
  });

  // handle overbooking
  if (ws.totalAmount > ws.maxHours) {
    style = allocationStyle({
      borderColor: overbookedColor,
      weight: "bold",
      text: overbookedColor,
    });
  }

  if (mode === BookingMode.Edit) {
    style.borderStyle = "solid";
    style.borderWidth = "1px";
  } else {
    style.borderBottomWidth = "5px";
  }

  return style;
}

interface AllocationArgs {
  background?: string;
  text?: string;
  borderColor: string;
  weight?: string;
}
export function allocationStyle(props: AllocationArgs): CSSStyleDeclaration {
  return {
    backgroundColor: props.background ?? "white",
    color: props.text,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: props.borderColor,
    borderBottomColor: props.borderColor,
    borderBottomStyle: "solid",
    fontWeight: props.weight ?? "normal",
  } as CSSStyleDeclaration;
}

export function calcExtraStyle(ws: WeekSummary) {
  const col = colorForSummary(ws);
  const style = {} as CSSStyleDeclaration;
  style.backgroundColor = col;

  if (ws.totalAmount > 40) {
    style.backgroundColor = overbookedColor;
    style.color = "white";
    style.fontWeight = "bold";
  }

  return style;
}

export function classesForBooking(weekMetadata: WeekMetadata) {
  const classNames = [];

  if (weekMetadata.beforeProject) {
    classNames.push("before-project-start");
  }
  if (weekMetadata.projectStart) {
    //classNames.push("project-start");
  }
  if (weekMetadata.afterProject) {
    classNames.push("after-project-end");
  }
  if (weekMetadata.beforeCurrentWeek) {
    classNames.push("before-current-week");
  }
  if (weekMetadata.currentWeek) {
    classNames.push("current-week");
  }
  if (weekMetadata.projectRunning) {
    classNames.push("project-running");
  }
  return classNames;
}
