import type { User } from "../../models";
import { LinkToUser, UserImage } from "../Users/LinkToUser";

interface UserBookingNameInterface {
  user: User;
  subText?: string;
}

export function UserBookingName(props: UserBookingNameInterface) {
  let subText = props.user.title;

  if (props.subText && props.subText.length > 0) {
    subText = props.subText;
  }

  return (
    <div className="flex w-full justify-start items-center">
      <UserImage id={props.user.id.toString()} size="medium" />
      <div className="ml-2">
        <b>
          <LinkToUser user={props.user} />
        </b>
        <div className="font-light">{subText}</div>
      </div>
    </div>
  );
}
