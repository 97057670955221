import { Space } from "../../components/Antd";
import type { Skill } from "../../models";

interface SkillBadgesProps {
  skills: Array<Skill>;
}

export function SkillBadges(props: SkillBadgesProps) {
  const skills = props.skills;

  if (skills.length === 0) {
    return <span>no skills, sad :(</span>;
  }

  return <Space wrap>{skills.map((s) => s.name).join(", ")}</Space>;
}
