import { useAuth } from "../../Providers/AuthProvider";
import { LinkToUser } from "../../components/Users/LinkToUser";
import {
  Absence,
  Booking,
  Holiday,
  TimeEntry,
  User,
  UserWorkDaySchedule,
} from "../../models";
import { date2weekNum } from "../../utils";
import {
  groupBookingsByProjectId,
  groupTimeEntriesByProjectId,
} from "../../utils/group";
import { LinkToProject } from "../../utils/project";
import {
  getTotalAbsences,
  getTotalBookedHours,
  getTotalHolidayHours,
  getTotalTrackedHours,
  hoursForWeek,
} from "./util";

interface UserPopoverProps {
  timeEntries: Array<TimeEntry>;
  bookings: Array<Booking>;
}

export function UserPopover(props: UserPopoverProps) {
  const projectIds = [
    ...new Set([
      ...props.timeEntries.map((t) => t.project_id),
      ...props.bookings.map((b) => b.project_id),
    ]),
  ];

  const { api } = useAuth();
  const fetchProjects = api.fetchProjectsByIds(
    projectIds.map((id) => id.toString()),
  );

  const isPending = fetchProjects.some((fp) => fp.isPending);
  const isError = fetchProjects.some((fp) => fp.isError);

  if (isPending) {
    return <div>Loading</div>;
  }

  if (isError) {
    return <div>Error</div>;
  }

  const bookingsByProjectId = groupBookingsByProjectId(props.bookings);
  const timeEntriesByProjectId = groupTimeEntriesByProjectId(props.timeEntries);

  return (
    <div>
      {fetchProjects.map((p) => {
        const project = p.data!;
        const bookings = bookingsByProjectId.get(project.id) ?? [];
        const timeEntries = timeEntriesByProjectId.get(project.id) ?? [];

        return (
          <div key={p.data?.id}>
            {p.data!.name}: {bookings.length} {timeEntries.length}
          </div>
        );
      })}
    </div>
  );
}

export function UserWeekPopover(props: {
  absences: Array<Absence>;
  holidays: Array<Holiday>;
  timeEntries: Array<TimeEntry>;
  workSchedules?: Array<UserWorkDaySchedule>;
  bookings: Array<Booking>;
  week: Date;
  user?: User;
}) {
  const { api } = useAuth();
  const trackedProjects = groupTimeEntriesByProjectId(props.timeEntries);
  const bookedProjects = groupBookingsByProjectId(props.bookings);
  const projectIds = [
    ...new Set([...trackedProjects.keys(), ...bookedProjects.keys()]),
  ];

  const fetchProjects = api.fetchProjectsByIds(
    projectIds.map((id) => id.toString()),
  );

  const isPending = fetchProjects.some((fp) => fp.isPending);
  const isError = fetchProjects.some((fp) => fp.isError);

  if (isPending) {
    return <div>Loading</div>;
  }

  if (isError) {
    return <div>Error</div>;
  }

  const totalHours = getTotalTrackedHours(props.timeEntries);
  const totalBooked = getTotalBookedHours(props.bookings);
  const bookingsByProjectId = groupBookingsByProjectId(props.bookings);
  const timeEntriesByProjectId = groupTimeEntriesByProjectId(props.timeEntries);
  const totalAbsences = getTotalAbsences(props.absences);
  const totalHolidayHours = getTotalHolidayHours(props.holidays);

  const data = fetchProjects
    .map((p) => {
      const project = p.data!;
      const ths = timeEntriesByProjectId.get(project.id) ?? [];
      const hours = getTotalTrackedHours(ths);
      const bhs = bookingsByProjectId.get(project.id) ?? [];
      const booked = getTotalBookedHours(bhs);

      const percent = totalHours > 0 ? hours / totalHours : 0;
      const trackedPercent = (percent * 100).toFixed(2);
      const trackedBooked = totalBooked > 0 ? hours / totalBooked : 0;

      return {
        project: project,
        hours: hours,
        booked: booked,
        percent: percent,
        trackedPercent: trackedPercent,
        trackedBooked: trackedBooked,
      };
    })
    .sort((a, b) => b.percent - a.percent);

  let hourInfo = "";
  if (props.user) {
    const workingHours = props.workSchedules
      ? hoursForWeek(props.week, props.workSchedules)
      : props.user.working_hours;
    const absenceHours = totalAbsences * 8;
    const holidayHours = totalHolidayHours;
    const possibleHours = workingHours - absenceHours - holidayHours;
    hourInfo = `${totalHours}h of ${possibleHours}h tracked`;
  }

  return (
    <div className="max-h-64 overflow-y-auto">
      {props.user && (
        <>
          <LinkToUser user={props.user} /> - {hourInfo} - CW:{" "}
          {date2weekNum(props.week)}
          <br />
        </>
      )}
      Total Hours Booked: {totalBooked}h<br />
      Total Absences: {totalAbsences}
      <br />
      {totalHolidayHours > 0 && (
        <>
          <span>Holidays: {props.holidays.map((h) => h.name)}</span>
          <br />
        </>
      )}
      <table>
        <thead>
          <tr>
            <td>
              <b>Projects ({data.length})</b>
            </td>
            <td>
              <b>Booked</b>
            </td>
            <td>
              <b>Tracked</b>
            </td>
            <td>
              <b>%</b>
            </td>
          </tr>
        </thead>
        <tbody>
          {data.map((d) => {
            return (
              <tr key={d.project.id}>
                <td>
                  <LinkToProject project={d.project} />
                </td>
                <td>{d.booked}h</td>
                <td>{d.hours}h</td>
                <td>{d.trackedPercent}%</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
