import { Tenant } from "@/models";
import { LinkToTenant } from "@/utils/tenant";
import { ICellRendererParams } from "@ag-grid-community/core";

export function TenantRenderer(props: ICellRendererParams<Tenant, string>) {
  const t = props.data!;
  return (
    <div>
      <LinkToTenant tenant={t} />
    </div>
  );
}
