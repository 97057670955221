import { ProjectTable } from "../../components/Projects/Table";
import { InternalError } from "../../components/Status/InternalError";
import { Loading } from "../../components/Status/Loading";
import { MainTitle } from "../../components/Typography";
import { useAuth } from "../../Providers/AuthProvider";
import { addMonths, dateRangeToQueryParam, weekStart } from "../../utils";

export function ProjectIndex() {
  const { api } = useAuth();

  const endDate = weekStart(new Date()).toDate();
  const startDate = addMonths(endDate, -2).toDate();
  const params = dateRangeToQueryParam(startDate, endDate);

  const fetchProjectUtilization = api.fetchProjectUtilization(params);
  const fetchProjects = api.fetchProjects();

  if (fetchProjects.isPending || fetchProjectUtilization.isPending) {
    return <Loading />;
  }

  if (fetchProjects.isError || fetchProjectUtilization.isError) {
    return <InternalError />;
  }

  return (
    <div>
      <MainTitle text="Projects" />
      <ProjectTable
        utilization={fetchProjectUtilization.data}
        projects={fetchProjects.data}
      />
    </div>
  );
}
