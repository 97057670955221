import React from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
} from "../../components/Antd";
import { BookingKind, Project, ProjectUserRequest, User } from "../../models";
import {
  sortProjectsByName,
  sortUsersByGivenName,
  userName,
} from "../../utils";

import dayjs from "dayjs";

interface ProjectUserRequestFormProps {
  mode: "create" | "edit" | "view";
  form: FormInstance;
  onFinish: (pur: ProjectUserRequest) => void;
  projectUserRequest?: ProjectUserRequest;
  projects: Array<Project>;
  users: Array<User>;
}

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

export function ProjectUserRequestForm(props: ProjectUserRequestFormProps) {
  const mode = props.mode;
  const form = props.form;
  const projectOptions = sortProjectsByName(props.projects)
    .filter(
      (p) =>
        p.status !== "Completed" &&
        p.start_date !== null &&
        p.end_date !== null,
    )
    .map((p) => {
      return {
        label: p.name,
        value: p.id,
      };
    });

  const userOptions = sortUsersByGivenName(props.users).map((u) => {
    return {
      label: userName(u),
      value: u.id,
    };
  });

  const skillOptions = [
    { label: "Basic", value: 1 },
    { label: "Intermediate", value: 2 },
    { label: "Advanced", value: 3 },
    { label: "Pro", value: 4 },
  ];

  const kindOptions = [
    { label: "Booked", value: BookingKind.Booked },
    { label: "Sales", value: BookingKind.Sales },
    { label: "Reserved", value: BookingKind.Reserved },
  ];

  const effortOptions = [
    { label: "10%", value: 10 },
    { label: "20%", value: 20 },
    { label: "30%", value: 30 },
    { label: "40%", value: 40 },
    { label: "50%", value: 50 },
    { label: "60%", value: 60 },
    { label: "70%", value: 70 },
    { label: "80%", value: 80 },
    { label: "90%", value: 90 },
    { label: "100%", value: 100 },
  ];

  const languageOptions = [
    { label: "No Requirement", value: "none" },
    { label: "English", value: "en" },
    { label: "German", value: "de" },
  ];

  const defaultNotes = `Please include the following information in your request:
Preferred role: (Example: Consultant, PM, Architect)
Industry Experience:
Specific Cloud:
Additional skills needed:
Technical skills (1-5):
Communication skills (1-5):`;

  const projectUserRequest =
    props.projectUserRequest ?? ({ notes: defaultNotes } as ProjectUserRequest);
  // huge code smell, find a better solution here.
  if (projectUserRequest.start) {
    // @ts-ignore
    projectUserRequest.start = dayjs(projectUserRequest.start);
  }

  if (projectUserRequest.end) {
    // @ts-ignore
    projectUserRequest.end = dayjs(projectUserRequest.end);
  }

  return (
    <Form
      form={form}
      name={`edit_user_request_${projectUserRequest.id}`}
      layout="vertical"
      onFinish={props.onFinish}
      initialValues={projectUserRequest}
      style={{ maxWidth: 800 }}
      validateMessages={validateMessages}
    >
      <Form.Item name="id" style={{ display: "none" }}>
        <Input />
      </Form.Item>
      {mode === "edit" && (
        <Form.Item label="Selected User" name="user_id">
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select"
            options={userOptions}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
      )}

      <Form.Item label="Project" name="project_id" rules={[{ required: true }]}>
        <Select
          allowClear
          style={{ width: "100%" }}
          placeholder="Please select"
          options={projectOptions}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>

      <Row>
        <Col span="12">
          <Form.Item
            label="Start Date"
            name="start"
            rules={[{ required: true }]}
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item label="End Date" name="end">
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="Kind" name="kind" rules={[{ required: true }]}>
        <Select
          style={{ width: "100%" }}
          placeholder="Please select"
          options={kindOptions}
        />
      </Form.Item>
      <Form.Item
        label="Estimated Effort"
        name="estimated_effort"
        rules={[{ required: true }]}
      >
        <Select
          style={{ width: "100%" }}
          placeholder="Please select"
          options={effortOptions}
        />
      </Form.Item>
      <Form.Item
        label="Skills Needed"
        name="skill"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Language" name="language" rules={[{ required: true }]}>
        <Select
          style={{ width: "100%" }}
          placeholder="Please select"
          options={languageOptions}
        />
      </Form.Item>
      <Form.Item
        label="Skill Level"
        name="skill_level"
        rules={[{ required: true }]}
      >
        <Select
          style={{ width: "100%" }}
          placeholder="Please select"
          options={skillOptions}
        />
      </Form.Item>
      <Form.Item
        help="Please include as much information as possible"
        label="Notes"
        name="notes"
      >
        <Input.TextArea rows={6} />
      </Form.Item>
      {mode !== "view" && (
        <Form.Item style={{ marginBottom: "4px" }}>
          <SubmitButton form={form} />
        </Form.Item>
      )}
    </Form>
  );
}

const SubmitButton = ({ form }: { form: FormInstance }) => {
  const [submittable, setSubmittable] = React.useState(false);

  // Watch all values
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
    );
  }, [values]);

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable}>
      Save
    </Button>
  );
};
