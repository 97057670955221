import { Client } from "@/models";
import { LinkToClient } from "@/utils/client";
import { ICellRendererParams } from "@ag-grid-community/core";

export function ClientRenderer(props: ICellRendererParams<Client, string>) {
  const d = props.data;
  return (
    <div>
      <LinkToClient client={d} />
    </div>
  );
}
