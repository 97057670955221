import {
  allocationStyle,
  colorForCellEntry,
} from "../../components/Bookings/Styling";

export function ReportContainerCellInfo() {
  return (
    <div className="cell-lines no-wrap">
      <div className="line">
        <div className="col">
          <b>Booked</b>
        </div>
      </div>
    </div>
  );
}

export function thresholdColor(value: number, gt: number, bt: number): string {
  if (value <= bt) {
    return "red";
  }

  if (value >= gt) {
    return "green";
  }

  return "orange";
}

interface ThresholdColumnProps {
  value: number;
  display?: string;
}

export function ThresholdColumn(props: ThresholdColumnProps) {
  const display = props.display ?? props.value;
  const color = thresholdColor(props.value, 0.8, 0.3);
  const computedStyle = {
    borderLeft: `solid 5px ${color}`,
    color: "black",
  };

  return (
    <div className="col" style={computedStyle}>
      {display}
    </div>
  );
}

interface HighlightedLineProps {
  a: number;
  b: number;
  c: number;
}

export function HighlightedLine(props: HighlightedLineProps) {
  const c = props.a / props.b;
  const d = (c * 100).toFixed(0) + "%";

  return (
    <div className="line">
      <ThresholdColumn value={props.a} />
      <ThresholdColumn value={c} display={d} />
    </div>
  );
}

export function Cell(props: { value: string; color: string }) {
  const style = allocationStyle({
    borderColor: props.color,
  }) as React.CSSProperties;

  return (
    <div className="allocation">
      <div className="entry" style={style}>
        {props.value}
      </div>
    </div>
  );
}
export function CellEntry(props: { percent: number; hours: number }) {
  const value = props.hours.toString();
  const percentStr = (props.percent * 100).toFixed(0) + "%";
  const color = colorForCellEntry(props.percent);

  return (
    <div className="line cursor-pointer">
      <Cell value={percentStr} color={color} />
      <Cell value={value} color={color} />
    </div>
  );
}
