import { Project, DateSpan, Timeline } from "../models";
import isoWeek from "dayjs/plugin/isoWeek";
import localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";

import { config } from "../utils/config";

dayjs.extend(isoWeek);
dayjs.extend(localizedFormat);

export function currentMonthStart(): Date {
  const d = dayjs(new Date());
  return d.startOf("month").toDate();
}

export function lastWeekStart(): Date {
  const w = addWeeks(dayjs(new Date()).toDate(), -1).toDate();
  return weekStart(w).toDate();
}

export function createDateFromYearCW(year: string, cw: string): Date {
  const cwN = parseInt(cw);
  return dayjs(`${year}-1-1`).isoWeek(cwN).toDate();
}

export function parseDate(d: string): Date {
  return dayjs(d).toDate();
}

export function formatDate(d: Date | string): string {
  return dayjs(d).format("YYYY-MM-DD");
}

export function formatFriendlyDate(d: Date | string): string {
  return dayjs(d).format("LL");
}

export function formatFriendlyMonth(d: Date | string): string {
  return dayjs(d).format("MMM");
}

export function formatFriendlyYear(d: Date | string): string {
  return dayjs(d).format("YYYY");
}

export function formatFriendlyDateWithoutYear(d: Date | string): string {
  return dayjs(d).format("MMM D");
}

export function isoWeekday(d: Date | string): number {
  return dayjs(d).isoWeekday();
}

export function dateRangeToQueryParam(start: Date, end: Date): any {
  return {
    start: formatDate(start),
    end: formatDate(end),
  };
}

export function dateSpanToQueryParam(ds: DateSpan): any {
  return {
    start: formatDate(ds.start),
    end: formatDate(ds.end),
  };
}

export function extractStartAndEndWeekFromProject(project: Project): DateSpan {
  let projectStart = project.start_date ? dayjs(project.start_date) : null;
  let projectEnd = project.end_date ? dayjs(project.end_date) : null;

  if (projectStart === null) {
    projectStart = dayjs().add(config.timeline.before, "weeks");
  }

  if (projectEnd === null) {
    projectEnd = dayjs().add(config.timeline.after, "weeks");
  }

  projectStart = projectStart.startOf("day");
  projectEnd = projectEnd.startOf("day");

  if (projectEnd < projectStart || projectStart.isSame(projectEnd)) {
    projectEnd = dayjs(projectStart).add(config.timeline.after, "weeks");
  }

  return {
    start: projectStart.toDate(),
    end: projectEnd.toDate(),
  };
}

export function startOfYear(d: Date | string) {
  return dayjs(d).startOf("year");
}

export function endOfYear(d: Date | string) {
  return dayjs(d).endOf("year");
}

export function dayjsRangeToDateSpan(r: [dayjs.Dayjs, dayjs.Dayjs]): DateSpan {
  return {
    start: r[0].toDate(),
    end: r[1].toDate(),
  };
}

export function dateRangeToDateSpan(r: [Date, Date]): DateSpan {
  return {
    start: r[0],
    end: r[1],
  };
}

export function defaultViewStartAndEnd(
  currentDate: Date,
  startDate: Date,
  endDate: Date,
): DateSpan {
  // parse the dates using dayjs
  const currentDay = weekStart(currentDate);
  const startDay = weekStart(startDate);
  const endDay = endDate ? dayjs(endDate) : null;

  // calculate start date
  let start;
  if (startDay.isBefore(currentDay) || startDay.isSame(currentDay)) {
    start = currentDay.add(config.timeline.before, "weeks");
  } else {
    start = startDay;
  }

  // calculate end date
  let end;
  if (endDay && endDay.isBefore(currentDay)) {
    end = endDay;
    start = startDay;
  } else if (startDay.isAfter(currentDay)) {
    end = startDay.add(config.timeline.after, "weeks");
  } else {
    end = currentDay.add(config.timeline.after, "weeks");
  }

  if (endDate !== null && end.isAfter(endDate)) {
    end = dayjs(endDate);
  }

  // return the start and end dates as strings in ISO format
  return {
    start: weekStart(start.toDate()).toDate(),
    end: weekEnd(end.toDate()).toDate(),
  };
}

export function nWeeksUntilThisWeek(n: number): DateSpan {
  const viewEnd = dayjs().endOf("isoWeek").toDate();
  const viewStart = dayjs()
    .startOf("isoWeek")
    .add(n, "weeks")
    .startOf("isoWeek")
    .toDate();

  return {
    start: viewStart,
    end: viewEnd,
  };
}

export function buildDefaultTimeline(): Timeline {
  const currentDate = new Date();
  const viewStart = dayjs().startOf("isoWeek").toDate();
  const viewEnd = dayjs()
    .startOf("isoWeek")
    .add(config.timeline.after, "weeks")
    .endOf("isoWeek")
    .toDate();

  return {
    view: {
      start: viewStart,
      end: viewEnd,
    },
    project: {
      start: viewStart,
      end: viewEnd,
    },
    today: currentDate,
  };
}

const today = new Date();
export function beforeToday(d: Date): boolean {
  return dayjs(d).toDate() < today;
}
export function afterOrOnToday(d: Date): boolean {
  return dayjs(d).toDate() >= today;
}

export function weekKeyFromDate(d: Date): string {
  const dd = dayjs(d);
  return `${dd.year()}-${dd.isoWeek()}`;
}

export function buildTimelineForProject(project: Project) {
  const currentDate = new Date();

  const pt = extractStartAndEndWeekFromProject(project);
  const vt = defaultViewStartAndEnd(currentDate, pt.start, pt.end);

  return {
    project: pt,
    view: vt,
    today: currentDate,
  };
}

export function date2string(d: Date): string {
  const dt = dayjs(d).format("YYYY-MM-DD");
  return dt;
}

export function addHours(d: Date, hours: number) {
  return dayjs(d).add(hours, "hours");
}

export function addDays(d: Date, days: number) {
  return dayjs(d).add(days, "days");
}

export function addMonths(d: Date, months: number) {
  return dayjs(d).add(months, "months");
}

export function addWeeks(d: Date, weeks: number) {
  return dayjs(d).add(weeks, "weeks");
}

export function weekStart(d: Date | string) {
  return dayjs(d).startOf("isoWeek");
}

export function weekEnd(d: Date | string) {
  return dayjs(d).endOf("isoWeek");
}

export function date2weekNum(d: Date) {
  return dayjs(d).isoWeek();
}

export function weekBefore(a: Date, b: Date) {
  return weekStart(a).isBefore(weekStart(b));
}

export function weekAfter(a: Date, b: Date) {
  return weekStart(a).isAfter(weekStart(b));
}

export function dateWithinWeek(dt: Date | string, wk: Date | string) {
  const start = weekStart(wk);
  const d = weekStart(dt);
  return d.isSame(start);
}

export function weekWithinSpan(dt: Date, span: DateSpan) {
  const d = weekStart(dt);
  const s = weekStart(span.start);
  const e = weekEnd(span.end);

  return d.isSame(s) || d.isSame(e) || (d.isAfter(s) && d.isBefore(e));
}

export function sameDay(a: Date, b: Date) {
  if (a === b) {
    return true;
  }

  return dayjs(a).isSame(b, "day");
}

export function sameWeek(a: Date, b: Date) {
  if (a === b) {
    return true;
  }

  return weekStart(a).isSame(weekStart(b));
}

export function daysSince(a: Date, b: Date) {
  return dayjs(b).diff(a, "days");
}

export interface WeekInfo {
  week: number;
  year: number;
  month: number;
  date: Date;
  info: string;
}

export function extractWeekSpan(d: Date): DateSpan {
  const start = weekStart(d);
  const end = weekEnd(d);

  return {
    start: start.toDate(),
    end: end.toDate(),
  };
}

export function date2WeekInfo(rd: Date): WeekInfo {
  const d = dayjs(rd);
  return {
    week: date2weekNum(d.toDate()),
    year: d.year(),
    month: d.month(),
    date: d.toDate(),
    info: d.format("YYYY-MM-DD"),
  };
}
export function daysBetweenDates(a: Date, b: Date): Array<string> {
  if (a === null || b === null) {
    return [];
  }

  if (b < a) {
    throw new Error(`weeks invalid, ${a} not before ${b}`);
  }

  let current = dayjs(a);
  let finish = dayjs(b);

  let days = [] as Array<string>;

  while (current <= finish) {
    const d = current.format("YYYY-MM-DD");
    days.push(d);
    current = dayjs(current).add(1, "day");
  }

  return days;
}

export function weeksBetweenDates(a: Date, b: Date): Array<WeekInfo> {
  if (a === null || b === null) {
    return [];
  }

  if (b < a) {
    throw new Error(`weeks invalid, ${a} not before ${b}`);
  }

  let current = weekStart(a);
  const finish = weekStart(b);

  let weeks = [] as Array<WeekInfo>;

  while (current <= finish) {
    const w = {
      week: date2weekNum(current.toDate()),
      year: current.year(),
      month: current.month(),
      date: current.toDate(),
      info: current.format("YYYY-MM-DD"),
    } as WeekInfo;

    weeks.push(w);
    current = weekStart(dayjs(current).add(1, "week").toDate());
  }

  return weeks;
}

export function monthsBetweenDates(
  a: Date | string,
  b: Date | string,
): Array<MonthInfo> {
  if (a === null || b === null) return [];
  if (b < a) throw new Error(`months invalid, ${a} not before ${b}`);

  let current = dayjs(a).startOf("month");
  const finish = dayjs(b).startOf("month");
  const months: Array<MonthInfo> = [];

  while (current <= finish) {
    months.push({
      year: current.year(),
      month: current.month(),
      date: current.toDate(),
      info: current.format("YYYY-MM"),
    });

    current = current.add(1, "month");
  }

  return months;
}

export interface MonthInfo {
  year: number;
  month: number;
  date: Date;
  info: string;
}

export function dateFromWeekKey(k: string): Date {
  const [year, isoWeek] = k.split("-");
  return dayjs().year(parseInt(year)).isoWeek(parseInt(isoWeek)).toDate();
}
