import { ProjectPlan } from "@/components/Planner/types";
import { sumForecast } from "@/components/Planner/util";
import { Card } from "antd";
import { Project, ProjectPlanSummary } from "../../../models";

export function PlannerOverview(props: {
  project: Project;
  months: Date[];
  plan: ProjectPlan;
  planSummary: ProjectPlanSummary;
}) {
  const totalFTEs = sumForecast(props.plan);
  const totalDays = totalFTEs * 18;
  const totalHours = totalDays * 8;

  const summary = props.planSummary;
  const totalTrackedHours = summary.total_hours_tracked;
  const totalBookedHours = summary.total_hours_booked;
  const totalTrackedDays = totalTrackedHours / 8.0;
  const totalEstimated = Number(summary.total_hours_estimated) / 8.0;
  const totalBudgetDaysRemaining = totalEstimated - totalTrackedDays;
  const budgetUsed = (totalTrackedDays / totalEstimated) * 100;

  const forecast = (totalHours / (totalEstimated * 8.0)) * 100;

  return (
    <div>
      <div className="flex gap-2 justify-items-stretch">
        <Card size="small" className="flex-1">
          <div>
            <div className="text-sm font-medium">Overview</div>
            <div className="text-2xl font-bold">{forecast.toFixed(2)}%</div>
            <div className="text-xs text-muted-foreground">
              Percentage of project planned
            </div>
            <div className="text-2xl font-bold">
              {totalBookedHours.toFixed(2)}
            </div>
            <div className="text-xs text-muted-foreground">
              Total hours staffed
            </div>
          </div>
        </Card>

        <Card size="small" className="flex-1">
          <div>
            <div className="text-sm font-medium">Time spent</div>
            <div className="space-y-2">
              <div>
                <div className="text-2xl font-bold">
                  {totalTrackedDays.toFixed(2)} days
                </div>
                <div className="text-xs text-muted-foreground">
                  Days worked so far
                </div>
              </div>
              <div>
                <div className="text-2xl font-bold">
                  {budgetUsed.toFixed(2)}%
                </div>
                <div className="text-xs text-muted-foreground">
                  Percentage of budget used
                </div>
              </div>
            </div>
          </div>
        </Card>

        <Card size="small" className="flex-1">
          <div>
            <div className="text-sm font-medium">Budget</div>
            <div className="space-y-2">
              <div>
                <div className="text-2xl font-bold">
                  {totalEstimated.toFixed(2)}
                </div>
                <div className="text-xs text-muted-foreground">
                  Total Budgeted Days
                </div>
              </div>
              <div>
                <div className="text-2xl font-bold">
                  {totalBudgetDaysRemaining.toFixed(2)} days
                </div>
                <div className="text-xs text-muted-foreground">
                  Total project budget remaining
                </div>
              </div>
            </div>
          </div>
        </Card>

        <Card size="small" className="flex-1">
          <div>
            <div className="text-sm font-medium">Planning</div>
            <div className="space-y-2">
              <div>
                <div className="text-2xl font-bold">{totalFTEs.toFixed(1)}</div>
                <div className="text-xs text-muted-foreground">
                  FTE Months planned
                </div>
              </div>
              <div>
                <div className="text-2xl font-bold">{totalDays.toFixed(1)}</div>
                <div className="text-xs text-muted-foreground">
                  Days planned
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
