import { useAuth } from "../../Providers/AuthProvider";
import { Dropdown } from "../Antd";
import { Link } from "react-router-dom";
import { userName } from "../../utils";
import { useMsal } from "@azure/msal-react";
import { UserAvatar } from "../Generic/UserAvatar";
import type { IPublicClientApplication } from "@azure/msal-browser";

export function UserInfo() {
  const { user, authed } = useAuth();
  if (authed === false) {
    return null;
  }

  const items = [
    { key: 1, label: <Link to={`/users/${user.id}`}>View Profile</Link> },
    { key: 2, label: <Link to={`/skill-selection`}>My Skills</Link> },
    { key: 3, label: <Logout /> },
  ];

  return (
    <div className="flex flex-row items-center cursor-pointer">
      <UserAvatar />
      <Dropdown menu={{ items }} placement="bottom">
        <div className="flex justify-between items-center">
          <div className="pl-2 font-sans flex flex-col pointer">
            <div className="truncate">{userName(user)}</div>
            <div className="truncate font-light text-xs">{user.title}</div>
          </div>
        </div>
      </Dropdown>
    </div>
  );
}

function signOutClickHandler(
  instance: IPublicClientApplication,
  logout: () => void,
) {
  instance.logoutRedirect({
    onRedirectNavigate: () => {
      // Return false if you would like to stop navigation after local logout
      logout();
      return false;
    },
  });
}

function Logout() {
  const { logout } = useAuth();
  const { instance } = useMsal();

  return (
    <span onClick={() => signOutClickHandler(instance, logout)}>Sign Out</span>
  );
}
