import { Button, Popconfirm, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";

interface DeleteSkillProps {
  readonly skillId: string;
}
type NotificationType = "success" | "error";

export function DeleteSkill(props: DeleteSkillProps) {
  const { api } = useAuth();
  const navigate = useNavigate();

  const [notificationApi, contextHolder] = notification.useNotification();

  const confirm = () => {
    console.log("ran confirm");
    let type: NotificationType,
      notificationMessage: string,
      notificationDescription: string;
    api
      .deleteSkill(props.skillId)
      .then(() => {
        type = "success";
        notificationMessage = "Skill successfully deleted";
        notificationDescription =
          "You will be redirected back to the skills page shortly";
        setTimeout(() => {
          navigate("/skills");
        }, 3000);
      })
      .catch((err) => {
        console.error(err);
        type = "error";
        notificationMessage = "Couldn't delete skill";
        notificationDescription = "Please try again later";
      })
      .finally(() => {
        notificationApi[type]({
          message: notificationMessage,
          description: notificationDescription,
        });
      });
  };
  return (
    <>
      {contextHolder}
      <Popconfirm
        title="Delete skill"
        description="Are you certain you want to delete this skill?"
        onConfirm={confirm}
      >
        <Button type="primary">Delete Skill</Button>
      </Popconfirm>
    </>
  );
}
