import { Link } from "react-router-dom";
import { Skill } from "../../models";

interface SkillLinkProps {
  skill: Skill;
}

export function nameForSkill(skill: Skill) {
  return skill.name;
}

export function LinkToSkill(props: SkillLinkProps) {
  const skill = props.skill;
  const path = `/skills/${skill.id}`;
  const name = nameForSkill(skill);

  return <Link to={path}>{name}</Link>;
}
