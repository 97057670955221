import { Badge } from "../../components/Antd";
import { VacationIcon, HolidayIcon, IconHolder } from "./Vacation";
import { bookingStyle, classesForBooking } from "./Styling";
import { BookingPopover } from "./BookingPopover";
import { BookingCellProps } from "./Bookings";
import React from "react";
import { containsAbsence, containsHoliday } from "./logic";

export function BookingCellView(props: BookingCellProps) {
  const md = props.weekMetadata;
  let classNames = ["allocation", ...classesForBooking(md)];
  const className = classNames.join(" ");
  return (
    <td className={className}>
      <Cell {...props} />
    </td>
  );
}

function Cell(props: BookingCellProps) {
  const md = props.weekMetadata;

  let style = {} as any;
  if (!md.afterProject) {
    style = bookingStyle(props.weekSummary);
  }

  if (props.options.summary === true) {
    style["fontWeight"] = "bold";
  }

  const amount = props.weekSummary.amount;
  const visibleAmount = md.afterProject ? undefined : amount;
  const showPopover = props.options.showPopover;
  const shouldUsePopover = showPopover && props.weekSummary.totalAmount > 0;

  const hasVacation = containsAbsence(props.weekSummary);
  const hasHoliday = containsHoliday(props.weekSummary);

  if (shouldUsePopover) {
    return (
      <BookingPopover
        holidays={props.options.holidays}
        schedules={props.options.schedules}
        absences={props.options.absences}
        ws={props.weekSummary}
      >
        <BookingEntry
          holiday={hasHoliday}
          vacation={hasVacation}
          amount={visibleAmount}
          style={style}
        />
      </BookingPopover>
    );
  }

  return (
    <BookingEntry
      vacation={hasVacation}
      holiday={hasHoliday}
      amount={visibleAmount}
      style={style}
    />
  );
}

interface BookingEntryAmountProps {
  style: React.CSSProperties;
  amount: number | undefined;
}

function BookingEntryAmount(props: BookingEntryAmountProps) {
  return (
    <div className="entry" style={props.style}>
      {props.amount}
    </div>
  );
}

interface BookingBadgeProps {
  vacation: boolean;
  holiday: boolean;
  children?: React.ReactNode;
}

function BookingCellBadgeIcons(props: BookingBadgeProps) {
  if (props.vacation && props.holiday) {
    return (
      <>
        <VacationIcon />
        <HolidayIcon />
      </>
    );
  }
  if (props.vacation) {
    return <VacationIcon />;
  }
  if (props.holiday) {
    return <HolidayIcon />;
  }
}

export function BookingCellBadge(props: BookingBadgeProps) {
  const icons = <BookingCellBadgeIcons {...props} />;
  return (
    <Badge count={<IconHolder>{icons}</IconHolder>}>{props.children}</Badge>
  );
}

interface BookingEntryProps extends BookingEntryAmountProps {
  holiday: boolean;
  vacation: boolean;
}

export function BookingEntry(props: BookingEntryProps) {
  if (props.vacation || props.holiday) {
    return (
      <BookingCellBadge {...props}>
        <BookingEntryAmount style={props.style} amount={props.amount} />
      </BookingCellBadge>
    );
  }

  return <BookingEntryAmount style={props.style} amount={props.amount} />;
}
