import { Project, ProjectUtilizationMap } from "../../models";
import SetFilter from "../SetFilter";
import { percentFormatter } from "../Table/Formatters/PercentFormatter";
import { ProjectRenderer } from "../Table/Renderers/ProjectRenderer";
import { Column, Table } from "../Table/Table";
import { numberValueParser } from "../Table/ValueParsers/NumberParser";

const utilColumns: Column<Project>[] = [
  {
    field: "utilization.tracking_utilization",
    headerName: "Util",
    width: 100,
    valueParser: numberValueParser,
    comparator: (valueA, valueB) => valueA - valueB,
    valueFormatter: percentFormatter,
  },
  {
    field: "utilization.accuracy",
    headerName: "Accuracy",
    width: 100,
    valueParser: numberValueParser,
    comparator: (valueA, valueB) => valueA - valueB,
    valueFormatter: percentFormatter,
  },
];

const projectColumns: Column<Project>[] = [
  {
    field: "name",
    flex: 1,
    cellRenderer: ProjectRenderer,
  },
  {
    field: "type",
    width: 150,
    filter: SetFilter,
  },
  {
    field: "status",
    width: 130,
    filter: SetFilter,
  },
];

const projectDateColumns: Column<Project>[] = [
  { field: "start_date", headerName: "Start", width: 130 },
  { field: "end_date", headerName: "End", width: 130 },
];

type ProjectListProps = {
  projects: Array<Project>;
  utilization?: ProjectUtilizationMap;
};

export function ProjectTable(props: ProjectListProps) {
  const projects = props.projects;
  const utilization = props.utilization;

  const cols = [...projectColumns];

  if (utilization !== undefined) {
    cols.push(...utilColumns);
  }

  cols.push(...projectDateColumns);

  const emptyUtil = {
    total_time_entries: 0,
    total_time_tracked: 0,
    total_staffings: 0,
    total_staffing: 0,
    accuracy: 0,
    tracking_utilization: 0,
  };

  const dataSource =
    projects.map((p) => {
      const util = utilization ? utilization[p.id] : emptyUtil;
      p.key = p.id.toString();
      p.utilization = util;
      return p;
    }) ?? [];

  return (
    <Table
      data={dataSource ?? []}
      autoHeight={true}
      columns={cols}
      paginate={20}
    />
  );
}
