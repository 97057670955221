import { Location } from "@/models";
import { LinkToLocation } from "@/utils/location";
import { ICellRendererParams } from "@ag-grid-community/core";

export function LocationRenderer(props: ICellRendererParams<Location, string>) {
  const l = props.data!;

  return (
    <div>
      <LinkToLocation location={l} />
    </div>
  );
}
