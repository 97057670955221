import { Divider } from "../../components/Antd";
import { useAuth } from "../../Providers/AuthProvider";
import { ProjectTable } from "../../components/Projects/Table";
import { MainTitle } from "../../components/Typography";
import { Card } from "../../components/Antd";
import { Loading } from "../../components/Status/Loading";
import { addMonths, dateRangeToQueryParam, weekStart } from "../../utils";
import { InternalError } from "../../components/Status/InternalError";

export function ProjectManaged() {
  const { api } = useAuth();
  const endDate = weekStart(new Date()).toDate();
  const startDate = addMonths(endDate, -2).toDate();
  const params = dateRangeToQueryParam(startDate, endDate);
  const fetchProjectUtilization = api.fetchProjectUtilization(params);
  const fetchProjects = api.fetchMyManagedProjects();

  if (fetchProjects.isPending || fetchProjectUtilization.isPending) {
    return <Loading />;
  }

  if (fetchProjects.isError || fetchProjectUtilization.isError) {
    return <InternalError />;
  }

  return (
    <div>
      <MainTitle text="Managed Projects" />
      <Divider />
      <Card size="small">
        <ProjectTable
          utilization={fetchProjectUtilization.data}
          projects={fetchProjects.data}
        />
      </Card>
    </div>
  );
}
