import type { UserWorkDaySchedule } from "../models";
import { parseDate } from "./date";

export function activeSchedule(d: Date, s: UserWorkDaySchedule): boolean {
  const start = parseDate(s.active_from);
  const end = s.active_until !== null ? parseDate(s.active_until) : null;

  return start <= d && (end === null || d <= end);
}

export function hoursForWeek(d: Date, schedules: Array<UserWorkDaySchedule>) {
  const activeSchedules = schedules.filter((s) => activeSchedule(d, s));
  return activeSchedules.reduce((t, s) => t + s.minutes, 0) / 60;
}
