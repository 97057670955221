import React from "react";
import { Navigate } from "react-router-dom";
import App from "../components/App/App";
import UI from "../components/ui/UI";
import { WeekOverviewPage } from "../components/ui/WeekOverview";
import { useAuth } from "../Providers/AuthProvider";
import { Login } from "./Auth/Login";
import { ClientIndex } from "./Clients/Index";
import { ClientPlanner } from "./Clients/Planner";
import { ClientShow } from "./Clients/Show";
import { Dashboard } from "./Dashboard/Dashboard";
import { LocationIndex } from "./Locations/Index";
import { LocationShow } from "./Locations/Show";
import { ProjectInactive } from "./Projects/Inactive";
import { ProjectIndex } from "./Projects/Index";
import { ProjectManaged } from "./Projects/Managed";
import { ProjectMine } from "./Projects/Mine";
import { ProjectPlannerPage } from "./Projects/Planner";
import { ProjectShow } from "./Projects/Show";
import { ProjectStaffingSuggestionsPage } from "./Projects/StaffingSuggestionsPage";
import { ProjectUserRequestIndex } from "./ProjectUserRequests/Index";
import { ReportIndex } from "./Reports/Index";
import { ReportProject } from "./Reports/Project";
import { ReportTeam } from "./Reports/Team";
import EditSkill from "./Skills/Edit";
import { SkillIndex } from "./Skills/Index";
import { SkillSelection } from "./Skills/Selection";
import { SkillShow } from "./Skills/Show";
import { StaffingIssuesIndex } from "./Staffing/Issues/Index";
import { TeamIndex } from "./Teams/Index";
import { TeamShow } from "./Teams/Show";
import { TenantIndex } from "./Tenants/Index";
import { TenantShow } from "./Tenants/Show";
import { UserEdit } from "./Users/Edit";
import { UserIndex } from "./Users/Index";
import { UserOverbooked } from "./Users/Overbooked";
import { MyUserReports, UserReports } from "./Users/Reports";
import { UserShow } from "./Users/Show";
import { UserTimeEntries } from "./Users/TimeEntries";
import { UserUnderbooked } from "./Users/Underbooked";
import { WorkbookIndex } from "./Workbook/Index";

interface ProjectProps {
  children: React.ReactElement;
}
function Protect(props: ProjectProps): JSX.Element {
  const { authed } = useAuth();

  if (!authed) {
    return <Navigate to="/login" replace />;
  }

  return props.children;
}

const routes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/ui",
    element: <UI />,
    children: [
      {
        path: "week-overview",
        element: <WeekOverviewPage />,
      },
    ],
  },
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: (
          <Protect>
            <Dashboard />
          </Protect>
        ),
      },
      {
        path: "/clients",
        element: (
          <Protect>
            <ClientIndex />
          </Protect>
        ),
      },
      {
        path: "/clients/:clientId",
        element: (
          <Protect>
            <ClientShow />
          </Protect>
        ),
      },
      {
        path: "/clients/:clientId/planner",
        element: (
          <Protect>
            <ClientPlanner />
          </Protect>
        ),
      },
      {
        path: "/staffing/issues/:year/:cw",
        element: (
          <Protect>
            <StaffingIssuesIndex />
          </Protect>
        ),
      },
      {
        path: "managed-projects",
        element: (
          <Protect>
            <ProjectManaged />
          </Protect>
        ),
      },
      {
        path: "inactive-projects",
        element: (
          <Protect>
            <ProjectInactive />
          </Protect>
        ),
      },
      {
        path: "my-projects",
        element: (
          <Protect>
            <ProjectMine />
          </Protect>
        ),
      },
      {
        path: "reports",
        element: (
          <Protect>
            <ReportIndex />
          </Protect>
        ),
      },
      {
        path: "reports/teams/:teamId",
        element: (
          <Protect>
            <ReportTeam />
          </Protect>
        ),
      },
      {
        path: "reports/projects/:projectId",
        element: (
          <Protect>
            <ReportProject />
          </Protect>
        ),
      },
      {
        path: "projects/:projectId",
        element: (
          <Protect>
            <ProjectShow />
          </Protect>
        ),
      },
      {
        path: "projects/:projectId/staffing_suggestions",
        element: (
          <Protect>
            <ProjectStaffingSuggestionsPage />
          </Protect>
        ),
      },
      {
        path: "projects/:projectId/planner",
        element: (
          <Protect>
            <ProjectPlannerPage />
          </Protect>
        ),
      },
      {
        path: "projects",
        element: (
          <Protect>
            <ProjectIndex />
          </Protect>
        ),
      },
      {
        path: "skill-selection",
        element: (
          <Protect>
            <SkillSelection />
          </Protect>
        ),
      },
      {
        path: "skills",
        element: (
          <Protect>
            <SkillIndex />
          </Protect>
        ),
      },
      {
        path: "skills/:skillId",
        element: (
          <Protect>
            <SkillShow />
          </Protect>
        ),
      },
      {
        path: "skills/:skillId/edit",
        element: (
          <Protect>
            <EditSkill />
          </Protect>
        ),
      },
      {
        path: "overbooked",
        element: (
          <Protect>
            <UserOverbooked />
          </Protect>
        ),
      },
      {
        path: "underbooked",
        element: (
          <Protect>
            <UserUnderbooked />
          </Protect>
        ),
      },
      {
        path: "project-user-requests",
        element: (
          <Protect>
            <ProjectUserRequestIndex />
          </Protect>
        ),
      },
      {
        path: "users",
        element: (
          <Protect>
            <UserIndex />
          </Protect>
        ),
      },
      {
        path: "users/:userId",
        element: (
          <Protect>
            <UserShow />
          </Protect>
        ),
      },
      {
        path: "users/:userId/time_entries",
        element: (
          <Protect>
            <UserTimeEntries />
          </Protect>
        ),
      },
      {
        path: "users/:userId/edit",
        element: (
          <Protect>
            <UserEdit />
          </Protect>
        ),
      },
      {
        path: "direct-reports",
        element: (
          <Protect>
            <MyUserReports />
          </Protect>
        ),
      },
      {
        path: "users/:userId/reports",
        element: (
          <Protect>
            <UserReports />
          </Protect>
        ),
      },
      {
        path: "teams",
        element: (
          <Protect>
            <TeamIndex />
          </Protect>
        ),
      },
      {
        path: "teams/:teamId",
        element: (
          <Protect>
            <TeamShow />
          </Protect>
        ),
      },
      {
        path: "workbook/:year/:cw",
        element: (
          <Protect>
            <WorkbookIndex />
          </Protect>
        ),
      },
      {
        path: "tenants",
        element: (
          <Protect>
            <TenantIndex />
          </Protect>
        ),
      },
      {
        path: "tenants/:tenantId",
        element: (
          <Protect>
            <TenantShow />
          </Protect>
        ),
      },
      {
        path: "locations",
        element: (
          <Protect>
            <LocationIndex />
          </Protect>
        ),
      },
      {
        path: "locations/:locationId",
        element: (
          <Protect>
            <LocationShow />
          </Protect>
        ),
      },
    ],
  },
];

export default routes;
