import { Popover } from "antd";
import { AlertCircle, ArrowUpDown, CircleHelp, Search } from "lucide-react";
import { useState } from "react";

function Progress(props: { value: number; className?: string }) {
  let value = props.value;
  const className = props.className ?? "";

  if (Number.isNaN(value) || !Number.isFinite(value)) {
    value = 100;
  }

  return (
    <div className="flex bg-gray-300 w-full h-2.5 rounded-full overflow-hidden">
      <div
        className={`inline-block h-full rounded-r-full ${className}`}
        style={{ width: `${value}%` }}
      />
    </div>
  );
}

export type BarData = {
  id: number;
  name: string;
  budgetHours: number;
  usedHours: number;
};

const Bars = (props: { data: BarData[]; options: boolean }) => {
  // Sample data - would come from props in real application

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("name"); // 'name', 'usage', 'remaining'
  const [sortDirection, setSortDirection] = useState<string>("asc");

  const getUsagePercentage = (used: number, total: number) => {
    return (used / total) * 100;
  };

  const getProgressColor = (used: number, total: number) => {
    const percentage = getUsagePercentage(used, total);
    if (Number.isNaN(percentage) || !Number.isFinite(percentage)) {
      return "bg-red-500";
    }
    if (percentage >= 90) return "bg-red-500";
    if (percentage >= 75) return "bg-amber-500";
    return "bg-blue-500";
  };

  const sortServices = (services: BarData[]) => {
    return [...services].sort((a, b) => {
      let compareA, compareB;

      switch (sortBy) {
        case "usage":
          compareA = getUsagePercentage(a.usedHours, a.budgetHours);
          compareB = getUsagePercentage(b.usedHours, b.budgetHours);
          break;
        case "remaining":
          compareA = a.budgetHours - a.usedHours;
          compareB = b.budgetHours - b.usedHours;
          break;
        default:
          compareA = a.name;
          compareB = b.name;
      }

      if (sortDirection === "asc") {
        return compareA > compareB ? 1 : -1;
      }
      return compareA < compareB ? 1 : -1;
    });
  };

  const filteredAndSortedServices = sortServices(
    props.data.filter((service) =>
      service.name.toLowerCase().includes(searchTerm.toLowerCase()),
    ),
  );

  const handleSort = (newSortBy: string) => {
    if (sortBy === newSortBy) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(newSortBy);
      setSortDirection("asc");
    }
  };

  return (
    <div>
      <div>
        <div
          className={`flex-col sm:flex-row gap-2 mb-2 ${props.options === true ? "flex" : "hidden"}`}
        >
          {/* Search Bar */}
          <div className="relative flex-grow">
            <Search className="absolute left-2 top-1.5 h-4 w-4 text-gray-500" />
            <input
              type="text"
              placeholder="Search services..."
              className="w-full pl-8 py-0.5 pr-4 border rounded-md"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <div className="flex gap-2">
            <button
              onClick={() => handleSort("name")}
              className={`px-3 rounded-md text-sm flex items-center ${
                sortBy === "name" ? "bg-blue-100" : "bg-gray-100"
              }`}
            >
              Name
              {sortBy === "name" && <ArrowUpDown className="h-4 w-4" />}
            </button>
            <button
              onClick={() => handleSort("usage")}
              className={`px-3 rounded-md text-sm flex items-center ${
                sortBy === "usage" ? "bg-blue-100" : "bg-gray-100"
              }`}
            >
              Usage{sortBy === "usage" && <ArrowUpDown className="h-4 w-4" />}
            </button>
            <button
              onClick={() => handleSort("remaining")}
              className={`px-3 rounded-md text-sm flex items-center ${
                sortBy === "remaining" ? "bg-blue-100" : "bg-gray-100"
              }`}
            >
              Remaining
              {sortBy === "remaining" && <ArrowUpDown className="h-4 w-4" />}
            </button>
          </div>
        </div>
      </div>
      <div>
        <div>
          {filteredAndSortedServices.map((service) => {
            const usagePercentage = getUsagePercentage(
              service.usedHours,
              service.budgetHours,
            );

            return (
              <div key={service.id} className="mb-2">
                <div className="flex justify-between items-center p-0">
                  <div className="font-medium truncate">{service.name}</div>
                  <div className="text-sm text-gray-500">
                    {service.usedHours}/{service.budgetHours}
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <div className="flex-grow">
                    <Progress
                      value={usagePercentage}
                      className={getProgressColor(
                        service.usedHours,
                        service.budgetHours,
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-end gap-1 w-16 text-right text-sm font-medium">
                    <BudgetAlert value={usagePercentage} />
                    <UsagePercentage value={usagePercentage} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

function Issue(props: { text: string }) {
  return (
    <div className="flex items-center">
      <Popover content={props.text}>
        <CircleHelp size={16} />
      </Popover>
    </div>
  );
}

function BudgetAlert(props: { value: number }) {
  const value = props.value;

  let text = "";
  const overBudget = value > 100;
  const nearLimit = value > 80;

  if (nearLimit) {
    text = "Approaching Limit!";
  }

  if (overBudget) {
    text = "Overbudget!";
  }

  if (!overBudget && !nearLimit) {
    return null;
  }

  return (
    <Popover content={text}>
      <AlertCircle size={16} className="text-red-700" />
    </Popover>
  );
}

function UsagePercentage(props: { value: number }) {
  const value = props.value;
  if (Number.isNaN(value)) {
    return <Issue text="Not a number" />;
  }

  if (!Number.isFinite(value)) {
    return <Issue text="Not a finite number" />;
  }
  return <span>{value.toFixed(1)}%</span>;
}

export default Bars;
