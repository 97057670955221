import { createContext, useContext, useMemo, useState } from "react";

type SearchContextType = {
  // We defined the user type in `index.d.ts`, but it's
  // a simple object with email, name and password.
  visible: boolean;
  open?: () => void;
  close?: () => void;
};

const SearchContext = createContext<SearchContextType>({ visible: false });

export function SearchProvider(props: { children: React.ReactNode }) {
  const [visible, setVisible] = useState<boolean>(false);

  const memoedValue = useMemo(
    () => ({
      visible: visible,
      open: () => setVisible(true),
      close: () => setVisible(false),
    }),
    [visible],
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <SearchContext.Provider value={memoedValue}>
      {props.children}
    </SearchContext.Provider>
  );
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export function useSearch() {
  return useContext(SearchContext);
}
