import React, { useState } from "react";
import { Project, User, Timeline, UserAndProjectUser } from "../../../models";
import { UserSearch } from "./UserSearch";
import { Form, Divider } from "../../Antd";
import { CurrentUsers as UserSearchResults } from "./UserSearchResults";
import { userName } from "../../../utils";

interface AddUserToProjectProps {
  projectUsersWithUsers: Array<UserAndProjectUser>;
  project: Project;
  onAddUser: (u: User) => void;
  timeline: Timeline;
}

interface AddUserByNameProps extends AddUserToProjectProps {
  users: Array<User>;
}

interface UsernameProps {
  user?: User;
}
function Username(props: UsernameProps) {
  const user = props.user;
  if (user === undefined) {
    return <></>;
  }

  return <span>{userName(user)}</span>;
}

export function AddUserByName(props: AddUserByNameProps) {
  const [filteredUsers, setFilteredUsers] = useState<Array<User>>([]);

  return (
    <>
      <Form.Item label="Person Name" tooltip="This uses a fuzzy search">
        <UserSearch users={props.users} onFilter={setFilteredUsers} />
      </Form.Item>
      <Divider />
      <UserSearchResults
        label={<Username />}
        project={props.project}
        projectUsersWithUsers={props.projectUsersWithUsers}
        filteredUsers={filteredUsers}
        onAddUser={props.onAddUser}
        timeline={props.timeline}
      />
    </>
  );
}
