import { DatePicker, DatePickerProps, Divider, GetProps } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { NotFoundError } from "../../Api";
import { useAuth } from "../../Providers/AuthProvider";
import { canViewTimeEntries } from "../../Providers/permissions";
import { Card, Col, Row } from "../../components/Antd";
import { Forbidden } from "../../components/Status/Forbidden";
import { InternalError } from "../../components/Status/InternalError";
import { Loading } from "../../components/Status/Loading";
import { NotFound } from "../../components/Status/NotFound";
import { MainTitle } from "../../components/Typography";
import { LinkToUser } from "../../components/Users/LinkToUser";
import { TimeEntry } from "../../models";
import {
  addMonths,
  date2weekNum,
  dateFromWeekKey,
  dateSpanToQueryParam,
  dayjsRangeToDateSpan,
  formatFriendlyDate,
  groupTimeEntriesByWeek,
  weekEnd,
} from "../../utils";
import { LinkToProject } from "../../utils/project";
type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;
const { RangePicker } = DatePicker;

export function UserTimeEntries() {
  const { api, user: currentUser, roles } = useAuth();
  const { userId } = useParams();

  const today = new Date();
  const startDate = addMonths(today, -3);
  const endDate = addMonths(today, 0);
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([
    startDate,
    endDate,
  ]);

  if (userId === undefined) {
    return <InternalError />;
  }

  const allowed = canViewTimeEntries(userId, currentUser, roles);

  if (!allowed) {
    return <Forbidden />;
  }

  const fetchUser = api.fetchUserDetailsById(userId);

  if (fetchUser.isPending) {
    return <Loading />;
  }

  if (fetchUser.isError) {
    if (fetchUser.error instanceof NotFoundError) {
      return <NotFound />;
    }

    return <InternalError />;
  }

  const user = fetchUser.data.user;

  const onChange = (
    _: DatePickerProps["value"] | RangePickerProps["value"],
    dateString: [string, string] | string,
  ) => {
    const start = dayjs(dateString[0]);
    const end = dayjs(dateString[1]);
    setDateRange([start, end]);
  };

  return (
    <Row>
      <Col span={24}>
        <MainTitle text={fetchUser.data.user.name}>
          <LinkToUser user={user} /> - Time Entry Overview
        </MainTitle>
        <Divider />
        <Card
          size="small"
          extra={
            <RangePicker
              size="small"
              onChange={onChange}
              defaultValue={[dateRange[0], dateRange[1]]}
            />
          }
        >
          <UserTimeEntryDetails
            userId={user.id.toString()}
            timeline={dateRange}
          />
        </Card>
      </Col>
    </Row>
  );
}

interface UserTimeEntriesProps {
  userId: string;
  timeline: [Dayjs, Dayjs];
}

function UserTimeEntryDetails(props: UserTimeEntriesProps) {
  const { api } = useAuth();
  const timeline = dayjsRangeToDateSpan(props.timeline);
  const params = dateSpanToQueryParam(timeline);
  const fetchUserTimeEntries = api.fetchTimeEntriesByUserId(
    props.userId,
    params,
  );

  if (fetchUserTimeEntries.isPending) {
    return <Loading />;
  }

  if (fetchUserTimeEntries.isError) {
    return <InternalError />;
  }

  return <Details timeEntries={fetchUserTimeEntries.data} />;
}

interface DetailProps {
  timeEntries: Array<TimeEntry>;
}

function sortByWeekKey(a: string, b: string): number {
  return dateFromWeekKey(b).getTime() - dateFromWeekKey(a).getTime();
}

function Details(props: DetailProps) {
  const timeEntries = props.timeEntries;
  const groupedTimeEntries = groupTimeEntriesByWeek(timeEntries);
  const keys: Array<string> = [...groupedTimeEntries.keys()].sort(
    sortByWeekKey,
  );

  return (
    <div>
      {keys.map((k) => {
        const v = groupedTimeEntries.get(k);
        if (v === undefined) {
          return;
        }

        return (
          <div key={k}>
            <TimeEntrySummary week={k} timeEntries={v} />
            <div className="mb-8 border-solid border border-gray-300 rounded-sm">
              <TimeEntryHeader />
              {v.map((t) => (
                <TimeEntryRow key={t.id} timeEntry={t} />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

interface TimeEntrySummaryProps {
  timeEntries: Array<TimeEntry>;
  week: string;
}

function TimeEntrySummary(props: TimeEntrySummaryProps) {
  const totalHours = props.timeEntries.reduce((a, te) => a + te.hours, 0);
  const totalBillable = props.timeEntries.reduce(
    (a, te) => a + (te.billable ? te.hours : 0),
    0,
  );
  const totalNonBillable = totalHours - totalBillable;

  const weekStart = dateFromWeekKey(props.week);

  const weekNo = date2weekNum(weekStart);
  const start = formatFriendlyDate(weekStart);
  const end = formatFriendlyDate(weekEnd(weekStart).toDate());

  return (
    <div>
      <div>
        <span className="font-bold">CW {weekNo}</span>: {start} - {end}
      </div>
      <div>
        Total: {totalHours}, Billable: {totalBillable}, Non Billable:{" "}
        {totalNonBillable}
      </div>
    </div>
  );
}

interface TimeEntryRowProps {
  timeEntry: TimeEntry;
}

function TimeEntryRow(props: TimeEntryRowProps) {
  const t = props.timeEntry;

  const { api } = useAuth();
  const fetchProject = api.fetchProjectById(t.project_id.toString());

  if (fetchProject.isPending) {
    return <div>loading</div>;
  }

  if (fetchProject.isError) {
    return <div>error</div>;
  }

  const isOnProject = fetchProject.data.project_users_with_users.find(
    (p) => p.user.id === t.user_id,
  );

  return (
    <div className="flex border-0 border-b border-solid border-b-black">
      <TimeEntryCell content={t.date} className="min-w-32" />
      <TimeEntryCell content={t.hours.toString()} className="min-w-16" />
      <TimeEntryCell
        content={t.billable ? "Yes" : "No"}
        className="text-center min-w-20"
      />
      <TimeEntryCell
        content={isOnProject ? "Yes" : "No"}
        className="text-center min-w-20"
      />
      <TimeEntryCell className="w-[400px] text-ellipsis overflow-hidden whitespace-nowrap">
        <LinkToProject project={fetchProject.data.project} />
      </TimeEntryCell>
      <TimeEntryCell content={t.task} className="flex-grow" />
    </div>
  );
}

function TimeEntryCell(props: {
  content?: string;
  className: string;
  children?: React.ReactNode;
}) {
  return (
    <div
      className={`px-2 border-0 border-r border-solid border-r-grey-300 ${props.className}`}
    >
      {props.children ? props.children : props.content}
    </div>
  );
}

function TimeEntryHeader() {
  return (
    <div className="flex font-bold border-0 border-b-gray-500 border-b border-solid ">
      <TimeEntryCell content="Date" className="min-w-32" />
      <TimeEntryCell content="Hours" className="min-w-16" />
      <TimeEntryCell content="Billable" className="text-center min-w-20" />
      <TimeEntryCell content="Member" className="text-center min-w-20" />
      <TimeEntryCell content="Project" className="w-[400px]" />
      <TimeEntryCell content="Task" className="flex-grow" />
    </div>
  );
}
