import flag_DE from "../flags/de.svg";
import flag_CH from "../flags/ch.svg";
import flag_AT from "../flags/at.svg";
import flag_HR from "../flags/hr.svg";

function flagSvg(code: string) {
  switch (code) {
    case "de":
      return flag_DE;
    case "ch":
      return flag_CH;
    case "at":
      return flag_AT;
    case "hr":
      return flag_HR;
  }
  return "";
}

export function Flag(props: { code: string; height: number }) {
  const flag = flagSvg(props.code);
  return (
    <>
      <img className="min-w-8" height={props.height} src={flag} alt="flag" />
    </>
  );
}
