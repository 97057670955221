import { themeQuartz } from "@ag-grid-community/theming";

// to use myTheme in an application, pass it to the theme grid option
export const theme = themeQuartz.withParams({
  accentColor: "#56ADFF",
  browserColorScheme: "light",
  columnBorder: false,
  headerFontSize: 14,
  sidePanelBorder: false,
  spacing: "8px",
});
