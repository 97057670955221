import { Link } from "react-router-dom";
import { Tenant } from "../models";
import { useAuth } from "../Providers/AuthProvider";
import { isAdmin } from "../Providers/permissions";
import { Space } from "antd";
import { Flag } from "./flag";

interface LinkToTenantProps {
  tenant: Tenant | null;
  showFlag?: boolean;
}

export function LinkToTenant(props: LinkToTenantProps) {
  const tenant = props.tenant;
  const { roles } = useAuth();

  if (tenant === null) {
    return <b>No tenant assigned</b>;
  }

  if (!isAdmin(roles)) {
    return <TenantName tenant={tenant} />;
  }

  return (
    <Link to={`/tenants/${tenant.id}`}>
      <TenantName {...props} />
    </Link>
  );
}

function TenantName(props: LinkToTenantProps) {
  const tenant = props.tenant!;

  return (
    <span>
      <Space align="center">
        {tenant.name}
        {props.showFlag ? (
          <Flag code={tenant.country_code} height={16} />
        ) : null}
      </Space>
    </span>
  );
}
