import { ColDef } from "@ag-grid-community/core";
import { AgGridReact, AgGridReactProps } from "@ag-grid-community/react"; // React Data Grid Component
import { theme } from "./Theme";
export type Column<T> = ColDef<T>;
export function Table<T>(props: {
  data: T[];
  columns: Column<T>[];
  paginate?: number;
  autoHeight?: boolean;
}) {
  const tableProps = {
    theme: theme,
    rowData: props.data,
    columnDefs: props.columns,
  } as AgGridReactProps<T>;

  if (props.paginate !== undefined && props.paginate > 0) {
    tableProps.pagination = true;
    tableProps.paginationPageSize = props.paginate;
  }

  if (props.autoHeight === true) {
    tableProps.domLayout = "autoHeight";
  }

  return (
    <div className="h-max w-full">
      <AgGridReact<T> {...tableProps} />
    </div>
  );
}
