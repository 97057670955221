import { useState } from "react";
import toast from "react-hot-toast";
import { Button, Card, Modal, Select, Space } from "../../components/Antd";
import {
  BookingKind,
  BookingMode,
  Project,
  Timeline,
  User,
  UserAndProjectUser,
} from "../../models";
import { useAuth } from "../../Providers/AuthProvider";
import {
  canEditProjectStaffing,
  canSuggestStaffing,
} from "../../Providers/permissions";
import { userName } from "../../utils";
import { KindSelector } from "../Bookings/KindSelector";
import { AddUserToProject } from "./AddUserToProject/AddUserToProject";
import ProjectBookings from "./Bookings";
import { StaffingSuggestions } from "./StaffingSuggestions";
import { SuggestStaffingModal } from "./SuggestStaffing";

export interface ProjectStaffingProps {
  projectUsersWithUsers: Array<UserAndProjectUser>;
  project: Project;
  timeline: Timeline;
}

export function ProjectStaffing(props: ProjectStaffingProps) {
  const { user, api, roles } = useAuth();

  const project = props.project;
  const timeline = props.timeline;

  const [numSuggestionProcessed, _setNumSuggestionsProcessed] = useState(0);
  const [displayMode, setDisplayMode] = useState<BookingMode>(BookingMode.View);
  const [projectUsersWithUsers, setProjectUsersWithUsers] = useState(
    props.projectUsersWithUsers,
  );
  const [visibleKinds, setVisibleKinds] = useState<BookingKind[]>([]);

  function openAddUser() {
    setIsOpen(true);
  }

  const [buttonText, setButtonText] = useState("Start Editing");
  const [modalIsOpen, setIsOpen] = useState(false);

  const [suggestModalOpen, setSuggestModalOpen] = useState(false);

  const teamMemberAdjustmentEnabled = false;

  function onAddUser(u: User) {
    const name = userName(u);

    const userId = u.id.toString();
    const projectId = project.id.toString();

    toast(
      "warning: Please manage team members in Salesforce for now. Managing members here should be available mid to end of June.",
    );

    if (teamMemberAdjustmentEnabled) {
      api
        .createProjectUser(userId, projectId)
        .then((pu) => {
          setProjectUsersWithUsers([...projectUsersWithUsers, pu]);

          toast(`success: Added ${name} to project`);
        })
        .catch(() => {
          toast(`error: There was a problem adding ${name} to the project`);
        });
    }
  }

  function onRemoveUser(u: User, projectUserId: number) {
    const name = userName(u);

    api
      .removeProjectUser(projectUserId.toString())
      .then(() => {
        setProjectUsersWithUsers(
          projectUsersWithUsers.filter(
            (pu) => pu.project_user.id !== projectUserId,
          ),
        );

        toast(`success: Removed ${name} from the project`);
      })
      .catch(() => {
        toast(`error: There was a problem removing ${name} from the project`);
      });
  }

  const myProjectUser = projectUsersWithUsers.find(
    (pu) => pu.user.id === user.id,
  );
  const editStaffingVisible = canEditProjectStaffing(
    roles,
    project,
    myProjectUser?.project_user,
  );
  const isOnProject = myProjectUser !== undefined;
  const canSeeStaffingRequests = editStaffingVisible;
  const canSuggest = canSuggestStaffing(
    user,
    project,
    myProjectUser?.project_user,
  );
  const suggestStaffingVisible = canSuggest;

  function staffingExtra() {
    if (project.status === "Completed") {
      return <div>Project Completed</div>;
    }

    if (editStaffingVisible === false && isOnProject === false) {
      return <div>You are not on this project</div>;
    }

    return (
      <>
        {editStaffingVisible ? (
          <>
            <Button size="small" type="primary" onClick={openAddUser}>
              Add Team Member
            </Button>
            <Button size="small" type="primary" onClick={toggleEdit}>
              {buttonText}
            </Button>
          </>
        ) : (
          "You may not adjust the staffing of this project"
        )}
        {suggestStaffingVisible && (
          <Button
            size="small"
            type="primary"
            onClick={() => setSuggestModalOpen(true)}
          >
            Suggest Staffing
          </Button>
        )}
      </>
    );
  }

  function toggleEdit() {
    if (displayMode === BookingMode.View) {
      setDisplayMode(BookingMode.Edit);
      setButtonText("Stop Editing");
    } else {
      setDisplayMode(BookingMode.View);
      setButtonText("Start Editing");
    }
  }

  return (
    <>
      <Space style={{ display: "flex" }} direction="vertical" size="small">
        {canSeeStaffingRequests && (
          <StaffingSuggestions
            project={project}
            timeline={timeline}
            projectUsersWithUsers={projectUsersWithUsers}
          />
        )}
        <Card
          size="small"
          title="Staffing Overview"
          extra={
            <div className="flex flex-row gap-2">
              {staffingExtra()}
              <KindSelector
                onChange={(ks) => setVisibleKinds(ks)}
                selected={visibleKinds}
              />
            </div>
          }
        >
          {displayMode === BookingMode.Edit && (
            <ProjectEdit
              project={project}
              projectUsersWithUsers={projectUsersWithUsers}
              onRemoveUser={onRemoveUser}
              timeline={timeline}
            />
          )}

          {displayMode === BookingMode.View && numSuggestionProcessed >= 0 && (
            <ProjectBookings
              cacheInvalidator={numSuggestionProcessed}
              project={project}
              projectUsersWithUsers={projectUsersWithUsers}
              timeline={timeline}
              visibleKinds={visibleKinds}
              onRemoveUser={onRemoveUser}
              displayMode={BookingMode.View}
            />
          )}
        </Card>
        <SuggestStaffingModal
          isOpen={suggestModalOpen}
          setOpen={setSuggestModalOpen}
          project={project}
          timeline={timeline}
          projectUsersWithUsers={projectUsersWithUsers}
        />
      </Space>

      <Modal
        title="Add user to Project"
        centered
        open={modalIsOpen}
        onOk={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        width={1000}
        styles={{
          body: { minHeight: "80vh", maxHeight: "80vh", overflow: "scroll" },
        }}
      >
        <AddUserToProject
          project={project}
          projectUsersWithUsers={projectUsersWithUsers}
          onAddUser={onAddUser}
          timeline={timeline}
        />
      </Modal>
    </>
  );
}

const nameMap = new Map<BookingKind, string>([
  [BookingKind.Sales, "Sales"],
  [BookingKind.Booked, "Booked"],
  [BookingKind.Reserved, "Reserved"],
]);

interface ProjectEditProps {
  project: Project;
  projectUsersWithUsers: Array<UserAndProjectUser>;
  timeline: Timeline;
  onRemoveUser: (user: User, projectUserId: number) => void;
}
function ProjectEdit(props: ProjectEditProps) {
  const [editBookingKind, setEditBookingKind] = useState<BookingKind>(
    BookingKind.Booked,
  );
  const project = props.project;
  const projectUsersWithUsers = props.projectUsersWithUsers;
  const timeline = props.timeline;

  const bookingKinds = [
    BookingKind.Booked,
    BookingKind.Sales,
    BookingKind.Reserved,
  ];
  const selectOptions = bookingKinds.map((v) => ({
    value: v,
    label: nameMap.get(v),
  }));

  return (
    <div className="editing">
      <Select
        defaultValue={BookingKind.Booked}
        style={{
          width: 280,
          float: "left",
          zIndex: 2,
          marginLeft: "10px",
          marginTop: "10px",
        }}
        onChange={setEditBookingKind}
        options={selectOptions}
      />
      <ProjectBookings
        kind={editBookingKind}
        project={project}
        projectUsersWithUsers={projectUsersWithUsers}
        timeline={timeline}
        onRemoveUser={props.onRemoveUser}
        displayMode={BookingMode.Edit}
      />
    </div>
  );
}
