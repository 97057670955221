import { BookingKind, BookingOptions, Timeline, User } from "../../models";
import {
  BookingTable,
  BookingsHeader,
  BookingLine,
} from "../Bookings/Structure";
import { Bookings } from "../Bookings/Bookings";
import { UserBookingName } from "../Generic/UserBookingName";

import { dateSpanToQueryParam } from "../../utils";
import { useAuth } from "../../Providers/AuthProvider";

interface UserStaffingProps {
  users?: Array<User>;
  ignoredUsers?: Array<User>;
  user_ids?: Array<number>;
  timeline: Timeline;
  visibleKinds?: Array<BookingKind>;
}

interface UserStaffingLineProps {
  user_id: number;
  timeline: Timeline;
  visibleKinds?: Array<BookingKind>;
  lineContent?: React.ReactNode;
}

export function UserStaffingLine(props: UserStaffingLineProps) {
  const { api } = useAuth();
  const userId = props.user_id.toString();
  const params = dateSpanToQueryParam(props.timeline.view);
  const fetchUser = api.fetchUserById(userId);
  const fetchSchedule = api.fetchUserWorkDayScheduleByUserId(userId);
  const fetchAbsences = api.fetchAbsencesByUserId(userId, params);
  const fetchBookings = api.fetchProjectBookingsByUserId(userId, params);

  const holidayLocationId = fetchUser.isSuccess
    ? fetchUser.data.location_id!
    : undefined;
  const fetchHolidays = api.fetchHolidaysByLocationId(
    holidayLocationId,
    params,
  );

  if (
    fetchUser.isPending ||
    fetchBookings.isPending ||
    fetchAbsences.isPending ||
    fetchHolidays.isPending ||
    fetchSchedule.isPending
  ) {
    return (
      <tr>
        <td>loading</td>
      </tr>
    );
  }

  if (
    fetchUser.isError ||
    fetchBookings.isError ||
    fetchAbsences.isError ||
    fetchHolidays.isError ||
    fetchSchedule.isError
  ) {
    return (
      <tr>
        <td>error</td>
      </tr>
    );
  }

  const lineContent = props.lineContent ?? (
    <UserBookingName user={fetchUser.data} />
  );

  const options = {
    bookings: fetchBookings.data,
    absences: fetchAbsences.data,
    timeline: props.timeline,
    schedules: fetchSchedule.data,
    holidays: fetchHolidays.data,
    visibleKinds: props.visibleKinds,
    showPopover: true,
  } as BookingOptions;

  return (
    <BookingLine key={props.user_id} lineContent={lineContent}>
      <Bookings options={options} />
    </BookingLine>
  );
}

export function UserStaffing(props: UserStaffingProps) {
  const user_ids = props.users ? props.users.map((u) => u.id) : props.user_ids;

  if (user_ids === undefined) {
    throw "no user ids specified";
  }

  const ignoredUserIds = (props.ignoredUsers ?? []).map((u) => u.id);

  return (
    <BookingTable width="300px">
      <BookingLine>
        <BookingsHeader timeline={props.timeline} />
      </BookingLine>

      {user_ids.map((id) => {
        if (ignoredUserIds.indexOf(id) > -1) {
          return null;
        }

        return (
          <UserStaffingLine
            key={id}
            user_id={id}
            timeline={props.timeline}
            visibleKinds={props.visibleKinds}
          />
        );
      })}
    </BookingTable>
  );
}
