import { Popover } from "antd";
import { formatDecimal } from "../../utils/formatting";

export function SummaryPercentNumber(props: { value: number; text: string; hover: string }) {
  const valueStr = formatDecimal(props.value);

  return (
    <Popover content={props.hover}>
      <div className="flex flex-col">
        <div className="pb-2 text-center text-3xl font-bold">{valueStr}</div>
        <div className="text-center">{props.text}</div>
      </div>
    </Popover>
  );
}

export function SummaryPercentStat(props: { percent: number; text: string; hover: string }) {
  const percentStr = formatDecimal(props.percent * 100);

  return (
    <Popover content={props.hover}>
      <div className="flex flex-col">
        <div className="pb-2 text-center text-3xl font-bold">{percentStr}%</div>
        <div className="text-center">{props.text}</div>
      </div>
    </Popover>
  );
}
