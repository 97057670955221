import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { MyInfoResponse, NamedRole, Team, TeamUser, User } from "../models";
import { ApiClient, Api } from "../Api";

interface AuthContextType {
  // We defined the user type in `index.d.ts`, but it's
  // a simple object with email, name and password.
  info: MyInfoResponse;
  user: User;
  team?: Team;
  team_user?: TeamUser;
  authed: boolean;
  error?: any;
  api: ApiClient;
  roles: Array<NamedRole>;
  login: (user: User, token: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

function emptyUser() {
  return {
    given_name: "undefined",
    family_name: "undefined",
    email: "undefined",
    title: "undefined",
    roles: [],
    working_hours: 40,
    name: "undefined",
    key: "undefined",
    location_id: null,
    tenant_id: null,
    manager_id: null,
    active: true,
    id: 0,
  } as User;
}

const AuthLocalStorageKey = "auth";

function getAuthData(): any {
  const v = localStorage.getItem(AuthLocalStorageKey);
  if (v === null) {
    return null;
  }

  return JSON.parse(v);
}

function clearAuthData() {
  localStorage.removeItem(AuthLocalStorageKey);
}

function saveAuthData(token: string) {
  localStorage.setItem(AuthLocalStorageKey, JSON.stringify({ token: token }));
}

interface AuthProviderProps {
  children: React.ReactNode;
}

export function AuthProvider(props: AuthProviderProps) {
  const [myInfo, setMyInfo] = useState<MyInfoResponse | null>(null);
  const [apiToken, setApiToken] = useState<string>();
  const [error, setError] = useState<any>();
  const [authed, setAuthed] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);

  useEffect(() => {
    if (error) setError(null);
  }, [location.pathname]);

  useEffect(() => {
    const d = getAuthData();
    if (d === null) {
      setLoadingInitial(false);
      return;
    }

    setAuthed(true);
    setApiToken(d.token);
  }, []);

  const api = Api(apiToken ?? "");
  const reqMyInfo = api.fetchMyInfo();

  // this seems gross to rely on an empty user
  if (myInfo === null && reqMyInfo.isSuccess) {
    setMyInfo(reqMyInfo.data);
    setAuthed(true);
    setLoadingInitial(false);
  }

  function login(user: User, token: string) {
    setApiToken(token);
    saveAuthData(token);
  }

  function logout() {
    setAuthed(false);
    setMyInfo(null);
    clearAuthData();
    window.location.reload();
  }

  const memoedValue = useMemo(
    () => ({
      info: myInfo!,
      user: myInfo?.user ?? emptyUser(),
      roles: myInfo?.roles ?? [],
      team: myInfo?.team,
      team_user: myInfo?.team_user,
      error,
      authed,
      api,
      login,
      logout,
    }),
    [myInfo, authed, error],
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && props.children}
    </AuthContext.Provider>
  );
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export function useAuth() {
  return useContext(AuthContext);
}
