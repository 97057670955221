import React from "react";
import { useAuth } from "../../Providers/AuthProvider";
import { useParams } from "react-router-dom";
import { InternalError } from "../../components/Status/InternalError";
import { Loading } from "../../components/Status/Loading";
import { Button, Card, Divider, Form, Input, Layout, Select, notification } from "antd";
import { MainTitle } from "../../components/Typography";
import { Skill, SkillCategory } from "../../models";
import { UseMutationResult } from "@tanstack/react-query";

export default function EditSkill() {
  const { api } = useAuth();
  const { skillId } = useParams();
  if (skillId === undefined) {
    return <InternalError />;
  }
  const fetchSkill = api.fetchSkillById(skillId);
  const fetchCategories = api.fetchSkillCategories();
  const mut = api.updateSkill();
  if (fetchSkill.isPending || fetchCategories.isPending) {
    return <Loading />;
  }

  if (fetchSkill.isError || fetchCategories.isError) {
    return <InternalError />;
  }

  const cardTitle = "Edit skill";
  return (
    <Layout>
      <MainTitle text={fetchSkill.data.skill.name} />
      <Divider />

      <Card size="default" title={cardTitle}>
        <EditSkillForm skill={fetchSkill.data.skill} updateSkill={mut} skillCategories={fetchCategories.data} />
      </Card>
    </Layout>
  );
}

interface EditSkillFormProps {
  readonly skill: Skill;
  readonly updateSkill: UseMutationResult<Skill, Error, Skill, unknown>;
  readonly skillCategories: Array<SkillCategory>;
}
type NotificationType = "success" | "error";

function EditSkillForm(props: EditSkillFormProps) {
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const onFinish = () => {
    if (props.updateSkill.isPending) {
      return;
    }
    const { name, skill_category_id } = form.getFieldsValue();
    const updateSkillBody = { ...props.skill, name, skill_category_id };
    let type: NotificationType, notificationMessage: string, notificationDescription: string;
    props.updateSkill
      .mutateAsync(updateSkillBody)
      .then(() => {
        type = "success";
        notificationMessage = "Skill successfully updated";
      })
      .catch(() => {
        type = "error";
        notificationMessage = "Couldn't update Skill";
        notificationDescription = "Please review your input and try again";
      })
      .finally(() => {
        api[type]({
          message: notificationMessage,
          description: notificationDescription,
        });
      });
  };

  const switchItems = props.skillCategories.map((i) => {
    return { value: i.id, label: i.name };
  });
  return (
    <>
      {contextHolder}
      <Form layout="vertical" form={form} initialValues={props.skill} onFinish={onFinish}>
        <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please enter a Skill name" }]}>
          <Input value={props.skill.name} />
        </Form.Item>
        <Form.Item label="Category" name="skill_category_id" rules={[{ required: true, message: "Please select a Skill category" }]}>
          <Select placeholder="Select a category" options={switchItems} value={props.skill.skill_category_id}></Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
