import { ICellRendererParams } from "@ag-grid-community/core";
import { useAuth } from "../../Providers/AuthProvider";
import { InternalError } from "../../components/Status/InternalError";
import { Loading } from "../../components/Status/Loading";
import { MainTitle } from "../../components/Typography";
import { LinkToUser } from "../../components/Users/LinkToUser";
import { Location, Team, TeamWithUsers, Tenant } from "../../models";
import { User, UserImbalance } from "../../models/user";
import { LinkToTeam } from "../../utils";
import { Badge } from "../../components/Badge";
import SetFilter from "../../components/SetFilter";
import { Column, Table } from "@/components/Table/Table";
import { isAdmin } from "@/Providers/permissions";
import { Forbidden } from "@/components/Status/Loader";

export function UserIndex() {
  const { api, roles } = useAuth();

  if (!isAdmin(roles)) {
    return <Forbidden />;
  }

  const fetchUsers = api.fetchUsers();
  const fetchUserImbalance = api.fetchUserImbalance();
  const fetchTeams = api.fetchTeams();
  const fetchLocations = api.fetchLocations();
  const fetchTenants = api.fetchTenants();

  if (
    fetchUsers.isPending ||
    fetchTeams.isPending ||
    fetchLocations.isPending ||
    fetchTenants.isPending ||
    fetchUserImbalance.isPending
  ) {
    return <Loading />;
  }

  if (
    fetchUsers.isError ||
    fetchTeams.isError ||
    fetchLocations.isError ||
    fetchTenants.isError ||
    fetchUserImbalance.isError
  ) {
    return <InternalError />;
  }

  return (
    <div>
      <MainTitle text="Users" />
      <UserTable
        users={fetchUsers.data}
        teams={fetchTeams.data}
        tenants={fetchTenants.data}
        locations={fetchLocations.data}
        imbalance={fetchUserImbalance.data}
      />
    </div>
  );
}

type UserRow = {
  user: User;
  manager?: User;
  team?: Team;
  tenant?: Tenant;
  location?: Location;
  imbalance?: UserImbalance;
};

function ImbalanceBadge(props: { imbalance: UserImbalance | undefined }) {
  if (props.imbalance === undefined) {
    return null;
  }

  const s = props.imbalance.status;

  if (s === "overbooked") {
    return <Badge type="warn" text="Overbooked" />;
  }

  if (s === "underbooked") {
    return <Badge type="info" text="Underbooked" />;
  }

  return null;
}

const columns: Column<UserRow>[] = [
  {
    field: "user",
    headerName: "Name",
    flex: 1,
    initialSort: "asc",
    cellRenderer: (params: ICellRendererParams<UserRow, string>) => {
      const user = params.data!.user;
      const imbalance = params.data!.imbalance;
      return (
        <div className="flex justify-between items-center">
          <LinkToUser user={user} />
          <ImbalanceBadge imbalance={imbalance} />
        </div>
      );
    },
    comparator: (valueA, valueB) => {
      if (valueA == valueB) return 0;
      return valueA > valueB ? 1 : -1;
    },
    valueGetter: (params) => {
      const userRow = params.data!;
      const user = userRow.user;
      return user.given_name;
    },
  },
  {
    field: "user.title",
    headerName: "Title",
  },
  {
    field: "team",
    width: 140,
    filter: SetFilter,
    valueGetter: (params) => {
      const userRow = params.data!;
      const team = userRow.team;
      if (team === undefined) {
        return "";
      }

      return team.name;
    },
    comparator: (valueA, valueB) => {
      if (valueA == valueB) return 0;
      return valueA > valueB ? 1 : -1;
    },
    cellRenderer: (params: ICellRendererParams<UserRow, string>) => {
      const team = params.data!.team;

      if (team === undefined) {
        return <div>No Team</div>;
      }

      return <LinkToTeam team={team} />;
    },
  },
  {
    field: "manager",
    width: 150,
    filter: SetFilter,
    valueGetter: (params) => {
      const manager = params.data!.manager;

      if (manager === undefined) {
        return "No manager";
      }

      return `${manager.given_name} ${manager.family_name}`;
    },
    cellRenderer: (params: ICellRendererParams<UserRow, string>) => {
      const user = params.data!.manager;

      if (user === undefined) {
        return <div>No manager</div>;
      }

      return (
        <div>
          <LinkToUser user={user} />
        </div>
      );
    },
  },
  {
    field: "location",
    width: 130,
    filter: SetFilter,
    valueGetter: (params) => {
      const location = params.data!.location;

      if (location === undefined) {
        return "No location";
      }

      return location.name;
    },
  },
  {
    field: "tenant",
    width: 100,
    filter: SetFilter,
    valueGetter: (params) => {
      const tenant = params.data!.tenant;

      if (tenant === undefined) {
        return "No tenant";
      }

      return tenant.name;
    },
  },
];

function UserTable(props: {
  locations: Location[];
  users: User[];
  tenants: Tenant[];
  teams: TeamWithUsers[];
  imbalance: UserImbalance[];
}) {
  const userImbalanceMap = new Map<number, UserImbalance>();
  props.imbalance.forEach((i) => {
    userImbalanceMap.set(i.user_id, i);
  });

  const userTeamMap = new Map<number, Team>();

  props.teams.forEach((t) => {
    t.users.forEach((u) => {
      userTeamMap.set(u.id, t.team);
    });
  });

  const userRows: UserRow[] = props.users
    .filter((u) => u.given_name)
    .map((u) => {
      return {
        user: u,
        manager: props.users.find((au) => u.manager_id === au.id),
        team: userTeamMap.get(u.id),
        location: props.locations.find((l) => u.location_id === l.id),
        tenant: props.tenants.find((t) => u.tenant_id === Number(t.id)),
        imbalance: userImbalanceMap.get(u.id),
      };
    });

  return <Table data={userRows} columns={columns} autoHeight={true} />;
}
