import { useAuth } from "../../Providers/AuthProvider";
import {
  addMonths,
  dateRangeToQueryParam,
  formatDate,
  formatFriendlyDate,
  formatFriendlyDateWithoutYear,
  sameDay,
  weekEnd,
  weekStart,
} from "../../utils";
import { Loading } from "../Status/Loading";

interface AbsencesProps {
  userId: number;
  start: Date;
  end: Date;
}

export function Absences(props: AbsencesProps) {
  const { api } = useAuth();
  const userId = props.userId;
  const start = weekStart(props.start).toDate();
  const end = weekEnd(addMonths(new Date(), 12).toDate()).toDate();
  const params = dateRangeToQueryParam(start, end);

  const { isPending, isError, data } = api.fetchAbsencesByUserId(
    userId.toString(),
    params,
  );

  if (isPending) {
    return <Loading />;
  }

  if (isError) {
    return <div>error</div>;
  }

  if (data.length === 0) {
    return "No upcoming absences :(";
  }

  const today = new Date();
  const dates = data.filter((d) => new Date(d.date) >= today);

  const dateSpans = getDateSpans(dates.map((a) => new Date(a.date)));

  return (
    <ul>
      {dateSpans.map(([start, end]) => (
        <li key={formatDate(start)}>
          <DateInfo start={start} end={end} />
        </li>
      ))}
    </ul>
  );
}
interface DateInfoProps {
  start: Date;
  end: Date;
}
function DateInfo(props: DateInfoProps) {
  if (sameDay(props.start, props.end)) {
    return <span>{formatFriendlyDateWithoutYear(props.start)}</span>;
  }

  return (
    <span>
      {formatFriendlyDate(props.start)} until {formatFriendlyDate(props.end)}
    </span>
  );
}

type DateRange = {
  start: Date;
  end: Date;
};

function isWeekend(date: Date): boolean {
  const day = date.getDay();
  return day === 0 || day === 6;
}

/*
function formatDate(date: Date): string {
  return date.toLocaleString("en-US", {
    month: "long",
    day: "numeric",
  });
}
*/

function generateDateRanges(dates: (Date | string)[]): DateRange[] {
  if (dates.length === 0) return [];

  // Sort dates and convert to Date objects
  const sortedDates = dates
    .map((d) => new Date(d))
    .sort((a, b) => a.getTime() - b.getTime());

  const ranges: DateRange[] = [];
  let currentRange: DateRange = {
    start: sortedDates[0],
    end: sortedDates[0],
  };

  for (let i = 1; i < sortedDates.length; i++) {
    const currentDate = sortedDates[i];
    const prevDate = sortedDates[i - 1];
    const dayDiff =
      (currentDate.getTime() - prevDate.getTime()) / (1000 * 60 * 60 * 24);

    // Check if dates are consecutive or if gap contains only weekends
    let isValidGap = dayDiff === 1;
    if (!isValidGap && dayDiff <= 3) {
      isValidGap = true;
      for (let d = 1; d < dayDiff; d++) {
        const gapDate = new Date(prevDate);
        gapDate.setDate(prevDate.getDate() + d);
        if (!isWeekend(gapDate)) {
          isValidGap = false;
          break;
        }
      }
    }

    if (isValidGap) {
      currentRange.end = currentDate;
    } else {
      ranges.push({ ...currentRange });
      currentRange = {
        start: currentDate,
        end: currentDate,
      };
    }
  }

  ranges.push(currentRange);
  return ranges;
}

function getDateSpans(allDates: Array<Date>) {
  const dateRanges = generateDateRanges(allDates);
  return dateRanges.map((r) => {
    return [r.start, r.end];
  });
}
