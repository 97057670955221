import { LinkToClient } from "@/utils/client";
import { Popover } from "antd";
import { Link } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";
import { isAdmin } from "../../Providers/permissions";
import { ProjectStats } from "../../models";
import { Project } from "../../models/project";
import { formatFriendlyDate } from "../../utils/date";

export function ProjectHeader(props: {
  projectId: string;
  children?: React.ReactNode;
}) {
  const { api } = useAuth();
  const projectId = props.projectId;
  const fetchProject = api.fetchProjectById(projectId);
  const fetchProjectStats = api.fetchProjectStatsByProjectId(projectId);

  if (fetchProject.isPending || fetchProjectStats.isPending) {
    return null;
  }

  if (fetchProject.isError || fetchProjectStats.isError) {
    return null;
  }

  const projectStats = fetchProjectStats.data!;
  const project = fetchProject.data.project;

  const startDate = formatFriendlyDate(project.start_date);
  const endDate = formatFriendlyDate(project.end_date);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">
          <Link to={`/projects/${project.id}`}>{project.name}</Link>
        </h2>
        <div className="flex gap-1">{props.children}</div>
      </div>
      <div className="flex justify-between items-center text-sm text-gray-500">
        <div>
          <div className="inline-flex gap-2 pr-2">
            <span className="rounded-md bg-green-700 text-green-100 py-1 px-2 text-xs">
              {project.status}
            </span>
            <span className="rounded-md bg-gray-300 text-gray-800 py-1 px-2 text-xs">
              {project.project_type}
            </span>
          </div>
          {startDate} - {endDate}
        </div>
        <ClientInfo project={project} />
      </div>
      <ProjectProgress stats={projectStats} />
    </div>
  );
}

function ClientInfo(props: { project: Project }) {
  const { api, roles } = useAuth();

  if (!isAdmin(roles)) {
    return null;
  }

  const clientId = props.project.client_id;
  const fetchClient = api.fetchClientById(clientId);

  if (fetchClient.isPending) {
    return null;
  }
  if (fetchClient.isError) {
    return null;
  }

  const client = fetchClient.data!;

  return (
    <span className="pl-2 font-bold">
      <LinkToClient client={client} />
    </span>
  );
}

function ProjectProgress(props: { stats: ProjectStats }) {
  const s = props.stats;
  const totalBudget = Number(s.total_role_budget);
  const totalUsed = Number(s.total_hours);
  const totalStaffed = Number(s.future_booked_hours);
  const totalFree = totalBudget - (totalStaffed + totalUsed);

  const totalUsedPercent =
    totalBudget > 0 ? (totalUsed / totalBudget) * 100 : 0;
  const totalStaffedPercent =
    totalBudget > 0 ? (totalStaffed / totalBudget) * 100 : 0;
  const totalFreePercent = Math.max(
    totalBudget > 0 ? (totalFree / totalBudget) * 100 : 0,
    0,
  );

  const usedColor = totalUsedPercent > 100 ? "bg-red-600" : "bg-blue-400";
  const debug = false;

  return (
    <>
      {debug && (
        <>
          TF:{totalFree}
          <br />
          TB:{totalBudget}
          <br />
          TS:{totalStaffed}
          <br />
          TU:{totalUsed}
          <br />
          TFP:{totalFree}
          <br />
          TSP:{totalStaffedPercent}
          <br />
          TUP:{totalUsedPercent}
          <br />
        </>
      )}
      <div className="flex w-full h-2.5 bg-gray-400 overflow-hidden rounded-full cursor-pointer shadow-md">
        <Popover
          title={`Budget Used: ${totalUsedPercent.toFixed(2)}%`}
          content={`${totalUsed} hours tracked of ${totalBudget} hours.`}
        >
          <div
            className={`${usedColor} text-right`}
            style={{ width: `${totalUsedPercent}%` }}
          ></div>
        </Popover>
        <Popover
          title={`Budget Staffed: ${totalStaffedPercent.toFixed(2)}%`}
          content={`${totalStaffed} hours staffed in the future.`}
        >
          <div
            className="bg-green-500 text-right"
            style={{ width: `${totalStaffedPercent}%` }}
          ></div>
        </Popover>
        <Popover
          title={`Unaccounted Budget: ${totalFreePercent.toFixed(2)}%`}
          content={`${totalFree} hours of the budget are not staffed or tracked.`}
          placement="left"
        >
          <div
            className="bg-yellow-600"
            style={{ width: `${totalFreePercent}%` }}
          ></div>
        </Popover>
      </div>
    </>
  );
}
