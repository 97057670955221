import {
  Absence,
  Booking,
  Holiday,
  TimeEntry,
  User,
  UserWorkDaySchedule,
} from "../../models";
import { daysSince, sameWeek } from "../../utils";
import { activeSchedule } from "../../utils/schedule";

export function getTotalWorkingHours(us: Array<User>): number {
  return us.reduce((amount, u) => amount + u.working_hours, 0);
}

export function getTotalNonBillableHours(te: Array<TimeEntry>): number {
  return te.reduce((amount, t) => amount + (t.billable ? 0 : t.hours), 0);
}

export function getTotalBillableHours(te: Array<TimeEntry>): number {
  return te.reduce((amount, t) => amount + (t.billable ? t.hours : 0), 0);
}

export function getTotalTrackedHours(te: Array<TimeEntry>): number {
  return te.reduce((amount, t) => amount + t.hours, 0);
}

export function getTotalBookedHours(bo: Array<Booking>): number {
  return bo.reduce((amount, b) => amount + b.amount, 0);
}

// returns the number of hours that should be possible to work
// for a given user. there is some not so great compromises for
// part time workers as we do not have the work schedules in
// the tool yet.
export function hoursForWeek(
  week: Date,
  schedules: Array<UserWorkDaySchedule>,
): number {
  const minutesPerDay = new Map<number, number>();

  if (schedules === undefined || schedules === null || schedules.length === 0) {
    return 40;
  }

  schedules.forEach((s) => {
    if (activeSchedule(week, s)) {
      minutesPerDay.set(s.day, s.minutes);
    }
  });

  let weekLength = 5;
  if (sameWeek(week, new Date())) {
    weekLength = Math.min(1 + daysSince(week, new Date()), 5);
  }

  let totalMinutes = 0;
  const weekDays = Array.from(Array(weekLength), (_, i) => i);
  weekDays.forEach((dayNo) => {
    const minutesForDay = minutesPerDay.get(dayNo) ?? 0;
    totalMinutes += minutesForDay;
  });

  const hours = totalMinutes / 60.0;

  return hours;
}

export function getTotalAbsences(as: Array<Absence>): number {
  return as.reduce((amount, _) => amount + 1, 0);
}

// todo: store if it's a half day or full day holiday
export function getTotalHolidayHours(as: Array<Holiday>): number {
  return as.reduce((amount, _) => amount + 8, 0);
}
