import type React from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";
import {
  Card,
  Col,
  Divider,
  Popover,
  Row,
  Tooltip,
} from "../../components/Antd";
import {
  allocationStyle,
  colorForCellEntry,
} from "../../components/Bookings/Styling";
import { InternalError } from "../../components/Status/InternalError";
import { Loading } from "../../components/Status/Loading";
import type { DateSpan, Team, User } from "../../models";
import {
  LinkToTeam,
  date2weekNum,
  dateSpanToQueryParam,
  formatFriendlyDate,
  weekKeyFromDate,
  weeksBetweenDates,
} from "../../utils";
import { groupHolidaysByLocationId } from "../../utils/group";
import type {
  RawTeamData,
  TeamCellData,
  TeamCellSummary,
  TeamReport,
  TeamRowHeaderData,
} from "../../utils/team_report";
import { buildTeamReportData } from "../../utils/team_report";
import { MissingDays } from "./MissingDays";
import { UserWeekPopover } from "./UserPopover";
import {
  getTotalAbsences,
  getTotalBillableHours,
  getTotalBookedHours,
  getTotalTrackedHours,
  getTotalWorkingHours,
} from "./util";

import { UserBookingName } from "../../components/Generic/UserBookingName";
import { MainTitle } from "../../components/Typography";
import { ReportContainer, ReportDateSelectionContext } from "./ReportContainer";
import { SummaryPercentNumber, SummaryPercentStat } from "./Summary";
import { collectUniqueLocationIds } from "@/utils/report";

export function ReportTeam2(props: { users: Array<User> }) {
  const users = props.users;
  const dateSpan = useContext(ReportDateSelectionContext);

  return <ReportTeamContainer timeline={dateSpan} users={users} />;
}

function ReportTitle(props: { team: Team }) {
  const team = props.team;
  return (
    <MainTitle text={`${team.name} Report`}>
      <LinkToTeam team={team} /> - Report
    </MainTitle>
  );
}

export function ReportTeam() {
  const { api } = useAuth();
  const { teamId } = useParams();

  if (teamId === undefined) {
    return <div>error</div>;
  }

  const fetchTeam = api.fetchTeamById(teamId);
  const fetchIgnoredUsers = api.fetchIgnoredUsers();

  if (fetchTeam.isPending || fetchIgnoredUsers.isPending) {
    return <Loading />;
  }

  if (fetchTeam.isError || fetchIgnoredUsers.isError) {
    return <InternalError />;
  }

  const teamWithUsers = fetchTeam.data;
  const team = teamWithUsers.team;
  const users = teamWithUsers.users.filter((u) =>
    fetchIgnoredUsers.data.map((u) => u.id).indexOf(u.id),
  );

  return (
    <ReportContainer title={<ReportTitle team={team} />}>
      <ReportTeam2 users={users} />
    </ReportContainer>
  );
}

interface ReportTeamInnerProps {
  timeline: DateSpan;
  users: Array<User>;
}

export function ReportTeamContainer(props: ReportTeamInnerProps) {
  const { api } = useAuth();
  const users = props.users.filter((u) => u.working_hours > 0);
  const userIds = users.map((u) => u.id.toString());
  const locationIds = collectUniqueLocationIds(users);

  const params = dateSpanToQueryParam(props.timeline);
  const fetchBookings = api.fetchProjectBookingsByUserIds(userIds, params);
  const fetchTimeEntries = api.fetchTimeEntriesByUserIds(userIds, params);
  const fetchAbsences = api.fetchAbsencesByUserIds(userIds, params);
  const fetchHolidays = api.fetchHolidaysByLocationIds(locationIds, params);
  const fetchUserWorkDaySchedules = api.fetchUserWorkDaySchedulesByUserIds(
    userIds,
    params,
  );

  if (
    fetchBookings.some((b) => b.isPending) ||
    fetchTimeEntries.some((b) => b.isPending) ||
    fetchAbsences.some((b) => b.isPending) ||
    fetchHolidays.some((b) => b.isPending) ||
    fetchUserWorkDaySchedules.some((b) => b.isPending)
  ) {
    return <div>loading</div>;
  }

  if (
    fetchBookings.some((b) => b.isError) ||
    fetchTimeEntries.some((b) => b.isError) ||
    fetchHolidays.some((b) => b.isError) ||
    fetchAbsences.some((b) => b.isError) ||
    fetchUserWorkDaySchedules.some((b) => b.isError)
  ) {
    return <div>error</div>;
  }

  const bookings = fetchBookings.map((b) => b.data!).flat();
  const timeEntries = fetchTimeEntries.map((te) => te.data!).flat();
  const absences = fetchAbsences.map((a) => a.data!).flat();
  const workSchedules = fetchUserWorkDaySchedules.map((a) => a.data!).flat();
  const holidays = groupHolidaysByLocationId(
    fetchHolidays.map((h) => h.data!).flat(),
  );
  const weeks = weeksBetweenDates(props.timeline.start, props.timeline.end)
    .reverse()
    .map((w) => w.date);

  const rawData = {
    users: users,
    bookings: bookings,
    timeEntries: timeEntries,
    absences: absences,
    holidaysByLocation: holidays,
    workSchedules: workSchedules,
    weeks: weeks,
  };

  const d = buildTeamReportData(rawData);

  return (
    <div>
      <Row gutter={12}>
        <Col span={24}>
          <Card size="small" title="Details">
            <TeamSummary data={d} />
            <Divider />
            <TeamReportContainer2 data={d} />
          </Card>
        </Col>
      </Row>
      <br />

      <Row gutter={12}>
        <Col span={24}>
          <Card size="small" title="Missed Days">
            <MissingDays
              start={props.timeline.start}
              end={props.timeline.end}
              workSchedules={workSchedules}
              timeEntries={timeEntries}
              absences={absences}
              holidays={holidays}
              users={users}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export function TeamSummary(props: { data: TeamReport }) {
  const data = props.data;
  const totalAbsences = getTotalAbsences(data.raw.absences);
  const totalAbsenceHours = totalAbsences * 8;
  const totalWorkingHours =
    getTotalWorkingHours(data.raw.users) * data.raw.weeks.length;
  const totalPossibleWorkingHours = totalWorkingHours - totalAbsenceHours;
  const totalBookingHours = getTotalBookedHours(data.raw.bookings);
  const totalTrackedHours = getTotalTrackedHours(data.raw.timeEntries);
  const totalBillableHours = getTotalBillableHours(data.raw.timeEntries);

  const billingUtil = totalBillableHours / totalPossibleWorkingHours;
  const staffingUtil = totalBookingHours / totalPossibleWorkingHours;
  const trackingUtil = totalTrackedHours / totalPossibleWorkingHours;

  return (
    <div>
      <div className="flex flex-row justify-between gap-4">
        <SummaryPercentStat
          percent={trackingUtil}
          text="Tracking Util"
          hover="The % of hours tracked vs the possible hours"
        />
        <SummaryPercentStat
          percent={billingUtil}
          text="Billing Util"
          hover="The % of billable hours tracked vs whats possible"
        />
        <SummaryPercentStat
          percent={staffingUtil}
          text="Staffing Util"
          hover="The % of hours which were staffed vs whats possible"
        />
        <SummaryPercentNumber
          value={totalAbsences}
          text="Absences"
          hover="How many absences exist"
        />
        <SummaryPercentNumber
          value={totalBillableHours}
          text="Billable Hours"
          hover="How many billable hours were tracked"
        />
        <SummaryPercentNumber
          value={totalTrackedHours}
          text="Total Hours"
          hover="How many hours were tracked"
        />
      </div>
    </div>
  );
}

const firstCellClasses = `flex min-w-[300px]`;
function TeamReportFirstRow(props: { raw: RawTeamData }) {
  const weeks = props.raw.weeks;

  return (
    <div className="table-row">
      <div className={firstCellClasses}>
        <b>Person</b>
      </div>
      {weeks.map((w) => {
        const weekKey = weekKeyFromDate(w);
        const weekText = formatFriendlyDate(w);
        const weekNo = date2weekNum(w);

        return (
          <div className="col" key={weekKey}>
            <Tooltip title={weekText}>
              <b>CW: {weekNo.toString()}</b>
            </Tooltip>
          </div>
        );
      })}
      <div className="col-info">Summary</div>
    </div>
  );
}

function TeamReportRowHeader(props: { header: TeamRowHeaderData }) {
  const header = props.header;

  if (header.title) {
    return <span>{header.title}</span>;
  }

  if (header.user) {
    return <UserBookingName user={header.user} />;
  }

  return <span>unknown</span>;
}

function TeamReportRowColumn(props: { column: TeamCellData }) {
  const data = props.column;

  return (
    <Popover
      content={
        <UserWeekPopover
          week={data.week}
          user={data.user}
          holidays={data.holidays}
          absences={data.absences}
          bookings={data.bookings}
          workSchedules={data.userWorkDaySchedules}
          timeEntries={data.timeEntries}
        />
      }
      trigger="click"
    >
      <div className="cell-lines">
        <CellEntry
          percent={data.summary!.percentBooked}
          hours={data.summary!.amountTracked}
        />
      </div>
    </Popover>
  );
}

function TeamReportRows(props: { rows: TeamReport["rows"] }) {
  const rows = props.rows;

  return rows.map((row, i) => (
    <div key={`row-${i}`} className="table-row">
      <div className={firstCellClasses}>
        <TeamReportRowHeader header={row.header} />
      </div>
      {row.columns.map((column, j) => (
        <div key={`row-${i}-col-${j}`} className="col">
          <TeamReportRowColumn column={column} />
        </div>
      ))}
      <TeamReportRowSummary summary={row.summary} />
    </div>
  ));
}

function TeamReportRowSummary(props: { summary: TeamCellSummary }) {
  const summary = props.summary;

  return (
    <div className="cell-lines">
      <CellEntry
        percent={summary.percentBooked}
        hours={summary.amountTracked}
      />
    </div>
  );
}

function TeamReportContainer2(props: { data: TeamReport }) {
  const data = props.data;

  return (
    <div className="report-table">
      <TeamReportFirstRow raw={data.raw} />
      <TeamReportRows rows={data.rows} />
    </div>
  );
}

export function Cell(props: { value: string; color: string }) {
  const style = allocationStyle({
    borderColor: props.color,
  }) as React.CSSProperties;

  return (
    <div className="allocation">
      <div className="entry" style={style}>
        {props.value}
      </div>
    </div>
  );
}
export function CellEntry(props: { percent: number; hours: number }) {
  const value = props.hours.toString();
  const percentStr = (props.percent * 100).toFixed(0) + "%";
  const color = colorForCellEntry(props.percent);

  return (
    <div className="line cursor-pointer">
      <Cell value={percentStr} color={color} />
      <Cell value={value} color={color} />
    </div>
  );
}
