import type { TabsProps } from "antd";
import { Tabs } from "antd";
import { useParams } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";
import { canViewTenants } from "../../Providers/permissions";
import { Forbidden } from "../../components/Status/Forbidden";
import { Loading } from "../../components/Status/Loading";
import { MainTitle } from "../../components/Typography";
import { UserStaffing } from "../../components/Users/UserStaffing";
import { buildDefaultTimeline, nWeeksUntilThisWeek } from "../../utils";
import { ReportTeamContainer } from "../Reports/Team";

export function TenantShow() {
  const { api, roles } = useAuth();
  const { tenantId } = useParams();

  if (!canViewTenants(roles)) {
    return <Forbidden />;
  }

  if (tenantId === undefined) {
    return <div>error</div>;
  }

  const fetchTenant = api.fetchTenantById(tenantId);
  const fetchUsers = api.fetchUsersByTenantId(tenantId);

  if (fetchTenant.isPending || fetchUsers.isPending) {
    return <Loading />;
  }

  if (fetchTenant.isError || fetchUsers.isError) {
    return <div>error</div>;
  }

  const tenant = fetchTenant.data;
  const users = fetchUsers.data;
  const timeline = buildDefaultTimeline();
  const historicTimeline = nWeeksUntilThisWeek(-6);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Staffing",
      children: <UserStaffing users={users} timeline={timeline} />,
    },
    {
      key: "2",
      label: "Report",
      children: (
        <ReportTeamContainer users={users} timeline={historicTimeline} />
      ),
    },
  ];

  return (
    <div>
      <MainTitle text={tenant.name} />
      <Tabs size="small" defaultActiveKey="1" items={items} />
    </div>
  );
}
