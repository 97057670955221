import { useAuth } from "../../Providers/AuthProvider";
import type { User } from "../../models";
import { currentMonthStart, dateSpanToQueryParam } from "../../utils";
import { groupHolidaysByLocationId } from "../../utils/group";

import { Card } from "@/components/Card";
import { collectUniqueLocationIds } from "@/utils/report";
import { MissingDays } from "../Reports/MissingDays";

export function DirectReportIssues() {
  const { api, user, info } = useAuth();

  if (info.reports === false) {
    return null;
  }

  const fetchDirectReportUsers = api.fetchDirectReportsByUserId(
    user.id.toString(),
  );

  if (fetchDirectReportUsers.isPending) {
    return "loading";
  }

  if (fetchDirectReportUsers.isError) {
    return "error";
  }

  return <DirectReportMissingDays users={fetchDirectReportUsers.data!} />;
}

function DirectReportMissingDays(props: { users: User[] }) {
  const { api } = useAuth();
  const users = props.users.filter((u) => u.working_hours > 0);
  const userIds = users.map((u) => u.id.toString());
  const locationIds = collectUniqueLocationIds(users);

  const timeline = {
    start: currentMonthStart(),
    end: new Date(),
  };
  const params = dateSpanToQueryParam(timeline);
  const fetchTimeEntries = api.fetchTimeEntriesByUserIds(userIds, params);
  const fetchAbsences = api.fetchAbsencesByUserIds(userIds, params);
  const fetchHolidays = api.fetchHolidaysByLocationIds(locationIds, params);
  const fetchIgnoredUsers = api.fetchIgnoredUsers();
  const fetchUserWorkDaySchedules = api.fetchUserWorkDaySchedulesByUserIds(
    userIds,
    params,
  );

  if (
    fetchTimeEntries.some((b) => b.isPending) ||
    fetchAbsences.some((b) => b.isPending) ||
    fetchHolidays.some((b) => b.isPending) ||
    fetchIgnoredUsers.isPending ||
    fetchUserWorkDaySchedules.some((b) => b.isPending)
  ) {
    return <div>loading</div>;
  }

  if (
    fetchTimeEntries.some((b) => b.isError) ||
    fetchHolidays.some((b) => b.isError) ||
    fetchAbsences.some((b) => b.isError) ||
    fetchIgnoredUsers.isError ||
    fetchUserWorkDaySchedules.some((b) => b.isError)
  ) {
    return <div>error</div>;
  }

  const timeEntries = fetchTimeEntries.map((te) => te.data!).flat();
  const absences = fetchAbsences.map((a) => a.data!).flat();
  const workSchedules = fetchUserWorkDaySchedules.map((a) => a.data!).flat();
  const holidays = groupHolidaysByLocationId(
    fetchHolidays.map((h) => h.data!).flat(),
  );
  const ignoredUsers = fetchIgnoredUsers.data.map((u) => u.id);
  const visibleUsers = props.users.filter((u) => !ignoredUsers.includes(u.id));

  return (
    <Card title="Missing Days">
      <MissingDays
        start={timeline.start}
        end={timeline.end}
        workSchedules={workSchedules}
        timeEntries={timeEntries}
        absences={absences}
        holidays={holidays}
        users={visibleUsers}
      />
    </Card>
  );
}
