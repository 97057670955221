import type { UseMutationResult } from "@tanstack/react-query";

import {
  WarningTwoTone,
  CheckCircleTwoTone,
  SyncOutlined,
} from "@ant-design/icons";
import { useState } from "react";

const visibleTime = 5000; // 1 second

export function MutateStatus(props: {
  mutation: UseMutationResult<any, Error, any>;
}) {
  const m = props.mutation;

  return (
    <div className="absolute w-2 h-2 top-[-5px] left-[-5px]">
      {m.isPending ? (
        <IconLoading />
      ) : (
        <>
          {m.isError ? <IconError /> : null}
          {m.isSuccess ? <IconSuccess /> : null}
        </>
      )}
    </div>
  );
}

function IconSuccess() {
  const [show, setShow] = useState<boolean>(true);
  setTimeout(() => {
    setShow(false);
  }, visibleTime);

  if (!show) {
    return null;
  }

  return (
    <div className="animate-bounce transition-opacity ease-in duration-700 opacity-100">
      <CheckCircleTwoTone twoToneColor="rgb(189, 217, 185)" />
    </div>
  );
}

function IconError() {
  return <WarningTwoTone twoToneColor="red" />;
}

function IconLoading() {
  return <SyncOutlined spin />;
}
