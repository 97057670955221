import { Bookings } from "@/components/Bookings/Bookings";
import {
  BookingLine,
  BookingsHeader,
  BookingTable,
} from "@/components/Bookings/Structure";
import { ProjectHeader } from "@/components/Projects/Header";
import { Forbidden } from "@/components/Status/Forbidden";
import { InternalError } from "@/components/Status/InternalError";
import { Loading } from "@/components/Status/Loading";
import {
  BookingOptions,
  DateSpan,
  Project,
  StaffingSuggestion,
  Timeline,
  User,
  UserAndProjectUser,
} from "@/models";
import { useAuth } from "@/Providers/AuthProvider";
import { isProjectManager } from "@/Providers/permissions";
import {
  bookingsFromSuggestions,
  buildTimelineForProject,
  formatDate,
  parseDate,
  userName,
  weekBefore,
  weekStart,
} from "@/utils";
import { Divider } from "antd";
import { useParams } from "react-router-dom";

export function ProjectStaffingSuggestionsPage() {
  const { api, user, roles } = useAuth();
  const { projectId } = useParams();

  if (projectId === undefined) {
    return <div>error</div>;
  }

  const fetchProject = api.fetchProjectById(projectId);
  const fetchStaffingSuggestions =
    api.fetchAllStaffingSuggestionByProjectId(projectId);

  if (fetchProject.isPending || fetchStaffingSuggestions.isPending) {
    return <Loading />;
  }

  if (fetchProject.isError || fetchStaffingSuggestions.isError) {
    return <InternalError />;
  }

  const myUser = fetchProject.data.project_users_with_users.find(
    (pu) => pu.user.id === user.id,
  );

  const myProjectUser = myUser ? myUser.project_user : undefined;

  const isPM = isProjectManager(
    roles,
    fetchProject.data.project,
    myProjectUser,
  );

  if (!isPM) {
    return <Forbidden />;
  }

  const suggestions = fetchStaffingSuggestions.data;

  const timeline = buildTimelineForProject(fetchProject.data.project);
  const today = new Date();

  if (weekBefore(today, timeline.view.start)) {
    timeline.view.start = weekStart(today).toDate();
  }

  const openSuggestions = suggestions.filter((s) => s.status === "new");
  const acceptedSuggestions = suggestions.filter(
    (s) => s.status === "accepted",
  );
  const rejectedSuggestions = suggestions.filter(
    (s) => s.status === "rejected",
  );

  return (
    <div className="flex flex-col gap-4">
      <ProjectHeader projectId={projectId.toString()}></ProjectHeader>
      <div className="flex flex-col gap-4">
        <div>
          <div className="text-2xl font-bold">Open</div>
          <ListOfStaffingSuggestions
            project={fetchProject.data.project}
            timeline={timeline}
            projectUsersWithUsers={fetchProject.data.project_users_with_users}
            suggestions={openSuggestions}
          />
        </div>
        <div>
          <div className="text-2xl font-bold">Accepted</div>
          <ListOfStaffingSuggestions
            project={fetchProject.data.project}
            timeline={timeline}
            projectUsersWithUsers={fetchProject.data.project_users_with_users}
            suggestions={acceptedSuggestions}
          />
        </div>
        <div>
          <div className="text-2xl font-bold">Rejected</div>
          <ListOfStaffingSuggestions
            project={fetchProject.data.project}
            timeline={timeline}
            projectUsersWithUsers={fetchProject.data.project_users_with_users}
            suggestions={rejectedSuggestions}
          />
        </div>
      </div>
    </div>
  );
}

function ListOfStaffingSuggestions(props: {
  suggestions: StaffingSuggestion[];
  project: Project;
  timeline: Timeline;
  projectUsersWithUsers: UserAndProjectUser[];
}) {
  if (props.suggestions.length === 0) {
    return <p>No suggestions</p>;
  }

  return (
    <>
      {props.suggestions.map((s) => {
        return (
          <SingleStaffingSuggestion
            key={s.id}
            project={props.project}
            timeline={props.timeline}
            projectUsersWithUsers={props.projectUsersWithUsers}
            staffing_suggestion={s}
          />
        );
      })}
    </>
  );
}

function SingleStaffingSuggestion(props: {
  staffing_suggestion: StaffingSuggestion;
  project: Project;
  timeline: Timeline;
  projectUsersWithUsers: Array<UserAndProjectUser>;
}) {
  return (
    <StaffingSuggestionList
      project={props.project}
      timeline={props.timeline}
      staffingSuggestions={[props.staffing_suggestion]}
      projectUsersWithUsers={props.projectUsersWithUsers}
    />
  );
}

function UserName(props: { id: number }) {
  const { api } = useAuth();
  const fetchUser = api.fetchUserById(props.id.toString());

  if (fetchUser.isLoading) {
    return null;
  }

  if (fetchUser.isError) {
    return null;
  }

  return userName(fetchUser.data!);
}

function LineInfo(props: { suggestion: StaffingSuggestion; user: User }) {
  const user = props.user;
  const decider_id = props.suggestion.decider_id;
  const decided_at = parseDate(props.suggestion.decided_at ?? new Date());

  return (
    <div className="flex flex-col">
      <div>{userName(user)}</div>

      {decider_id && (
        <div className="font-normal">
          by <UserName id={decider_id} /> on {formatDate(decided_at)}
        </div>
      )}
    </div>
  );
}

function getDateRange(staffingSuggestion: StaffingSuggestion): DateSpan | null {
  if (
    !staffingSuggestion.suggestions ||
    staffingSuggestion.suggestions.length === 0
  ) {
    return null;
  }

  const dates = staffingSuggestion.suggestions.map(
    (entry) => new Date(entry.date),
  );

  return {
    start: new Date(Math.min(...dates.map((date) => date.getTime()))),
    end: new Date(Math.max(...dates.map((date) => date.getTime()))),
  };
}

function StaffingSuggestionList(props: {
  staffingSuggestions: Array<StaffingSuggestion>;
  project: Project;
  timeline: Timeline;
  projectUsersWithUsers: Array<UserAndProjectUser>;
}) {
  const projectUsersWithUsers = props.projectUsersWithUsers;

  if (props.staffingSuggestions.length === 0) {
    return null;
  }

  return (
    <>
      {props.staffingSuggestions.map((sr) => {
        const bookings = bookingsFromSuggestions(
          sr.project_user_id,
          props.project.id,
          sr.suggestions,
        );
        const project_user_and_user = projectUsersWithUsers.find(
          (pu) => pu.project_user.id === sr.project_user_id,
        );

        if (project_user_and_user === undefined) {
          return null;
        }

        const user = project_user_and_user.user;
        const timelineView = getDateRange(sr);

        const timeline = {
          project: timelineView,
          view: timelineView,
          today: new Date(),
        } as Timeline;

        const options = {
          timeline: timeline,
          bookings: bookings,
          project: props.project,
          userAndProjectUser: project_user_and_user,
        } as BookingOptions;

        return (
          <div key={sr.id}>
            <BookingTable width="300px">
              <BookingLine>
                <BookingsHeader timeline={timeline} />
              </BookingLine>
              <BookingLine
                lineContent={<LineInfo suggestion={sr} user={user} />}
              >
                <Bookings options={options} />
              </BookingLine>
            </BookingTable>
            <Divider />
          </div>
        );
      })}
    </>
  );
}
