import { TeamWithUsers, User } from "@/models";
import { userImageUrl, userName } from "@/utils";
import { ICellRendererParams } from "@ag-grid-community/core";
import { Avatar, Tooltip } from "antd";

export type TeamTableRow = {
  name: string;
  numMembers: number;
  users: Array<User>;
  data: TeamWithUsers;
};

export function TeamAvatarsRenderer(
  props: ICellRendererParams<TeamTableRow, string>,
) {
  const users = props.data!.data.users;

  return (
    <div className="text-left flex h-full items-center">
      <Avatar.Group max={{ count: 20 }}>
        {users.map((user) => (
          <Tooltip key={user.id} title={userName(user)} placement="top">
            <Avatar src={userImageUrl(user.id.toString())} />
          </Tooltip>
        ))}
      </Avatar.Group>
    </div>
  );
}
