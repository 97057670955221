import { useParams } from "react-router-dom";

import { Flex } from "antd";
import { NotFoundError } from "../../Api";
import { useAuth } from "../../Providers/AuthProvider";
import {
  canEditUser,
  isAdmin,
  isStaffingManager,
} from "../../Providers/permissions";
import { Card } from "../../components/Antd";
import { SkillBadges } from "../../components/Generic/SkillBadges";
import { InternalError } from "../../components/Status/InternalError";
import { Loading } from "../../components/Status/Loading";
import { NotFound } from "../../components/Status/NotFound";
import { MainTitle } from "../../components/Typography";
import { Absences } from "../../components/Users/Absences";
import { Holidays } from "../../components/Users/Holidays";
import {
  LinkToEditUser,
  LinkToManager,
  UserImage,
} from "../../components/Users/LinkToUser";
import { UserSchedule } from "../../components/Users/UserSchedule";
import type { User, UserDetails } from "../../models";
import { LinkToTeam, addMonths } from "../../utils";
import { LinkToLocation } from "../../utils/location";
import { LinkToTenant } from "../../utils/tenant";
import { UserStaffing } from "./UserStaffing";
import { UserTimeEntries } from "./UserTimeEntries";

export function UserShow() {
  const { api } = useAuth();
  const { userId } = useParams();

  if (userId === undefined) {
    return <InternalError />;
  }

  const { isPending, isError, data, error } = api.fetchUserDetailsById(userId);

  if (isPending) {
    return <Loading />;
  }

  if (isError) {
    if (error instanceof NotFoundError) {
      return <NotFound />;
    }

    return <InternalError />;
  }

  return <Details userDetails={data} />;
}

interface UserShowProps {
  userDetails: UserDetails;
}
function Details(props: UserShowProps) {
  const { roles } = useAuth();
  const user = props.userDetails.user;
  const canEdit = canEditUser(roles);

  const start = new Date();
  const end = addMonths(start, 3).toDate();

  const canSeeTimeEntries = isAdmin(roles) || isStaffingManager(roles);

  return (
    <div>
      <UserHeader userDetails={props.userDetails} />
      <div className="py-4 grid grid-cols-4 gap-4">
        <div className="flex flex-col col-span-3 gap-4">
          <UserStaffing showAllBookings={true} user={user} />
          {canSeeTimeEntries && <UserTimeEntries user={user} months={2} />}
        </div>
        <div className="col-span-1 flex flex-col gap-4">
          <Card
            size="small"
            title="Details"
            extra={canEdit && <LinkToEditUser id={user.id.toString()} />}
          >
            <UserInfo userDetails={props.userDetails} />
            <UserSchedule userId={user.id} />
          </Card>
          <Card size="small" title="Upcoming Absences">
            <Absences userId={user.id} start={start} end={end} />
            <Holidays locationId={user.location_id} start={start} end={end} />
          </Card>
        </div>
      </div>
    </div>
  );
}

function UserLabel(props: { user: User }) {
  const user = props.user;
  if (user.active === false) {
    return <b>[INACTIVE]</b>;
  }
}

function UserHeader(props: UserInfoProps) {
  const user = props.userDetails.user;
  const team = props.userDetails.team;
  const location = props.userDetails.location;
  const tenant = props.userDetails.tenant;

  return (
    <Flex align="center" justify="space-between">
      <Flex align="center">
        <UserImage id={user.id.toString()} size="large" />
        <div style={{ paddingLeft: "10px" }}>
          <MainTitle text={`${user.given_name} ${user.family_name}`} />
          <UserLabel user={user} />
          {user.title} - <LinkToTeam team={team} /> -{" "}
          <LinkToLocation location={location} />
        </div>
      </Flex>
      <div>
        <LinkToTenant tenant={tenant} />
      </div>
    </Flex>
  );
}

interface UserInfoProps {
  userDetails: UserDetails;
}

function UserInfo(props: UserInfoProps) {
  const user = props.userDetails.user;
  const manager = props.userDetails.manager;
  const skills = props.userDetails.skills ?? [];

  return (
    <div>
      <b>Working Hours</b>: {user.working_hours}
      <br />
      <b>Email</b>: {user.email}
      <br />
      <b>Manager</b>: <LinkToManager user={manager} />
      <br />
      <b>Skills</b>: <SkillBadges skills={skills} />
    </div>
  );
}
