import { useParams } from "react-router-dom";
import { Loading } from "../../components/Status/Loading";
import type {
  Booking,
  DateSpan,
  Project,
  ProjectUser,
  TimeEntry,
  User,
} from "../../models";
import {
  date2weekNum,
  dateSpanToQueryParam,
  formatFriendlyDate,
  groupHolidaysByLocationId,
  weekKeyFromDate,
  weeksBetweenDates,
} from "../../utils";
import {
  Card,
  Col,
  Divider,
  Row,
  Popover,
  Tooltip,
} from "../../components/Antd";
import { InternalError } from "../../components/Status/InternalError";
import { MainTitle } from "../../components/Typography";
import { LinkToProject } from "../../utils/project";
import { useAuth } from "../../Providers/AuthProvider";
import { ReportContainer, ReportDateSelectionContext } from "./ReportContainer";
import { useContext } from "react";
import type {
  ProjectCellData,
  ProjectCellSummary,
  ProjectReport,
  ProjectRowHeaderData,
  RawProjectData,
} from "../../utils/project_report";
import { buildProjectReportData } from "../../utils/project_report";
import { LinkToUser } from "../../components/Users/LinkToUser";
import { UserWeekPopover } from "./UserPopover";
import { colorForCellEntry } from "../../components/Bookings/Styling";
import { Cell } from "./Team";
import {
  getTotalAbsences,
  getTotalBillableHours,
  getTotalBookedHours,
  getTotalTrackedHours,
} from "./util";
import { SummaryPercentNumber, SummaryPercentStat } from "./Summary";
import { formatDecimal } from "../../utils/formatting";

function ReportTitle(props: { project: Project }) {
  const project = props.project;
  return (
    <MainTitle text={`${project.name} Report`}>
      <LinkToProject project={project} /> - Report
    </MainTitle>
  );
}

export function ReportProject() {
  const { api } = useAuth();
  const { projectId } = useParams();

  if (projectId === undefined) {
    return <InternalError />;
  }

  const fetchProject = api.fetchProjectById(projectId);

  if (fetchProject.isPending) {
    return <Loading />;
  }

  if (fetchProject.isError) {
    return <InternalError />;
  }

  const project = fetchProject.data.project;
  const projectUsers = fetchProject.data.project_users_with_users.map(
    (pu) => pu.project_user,
  );

  return (
    <ReportContainer title={<ReportTitle project={project} />}>
      <ReportProject2 project={project} projectUsers={projectUsers} />
    </ReportContainer>
  );
}

export function ReportProject2(props: {
  project: Project;
  projectUsers: Array<ProjectUser>;
}) {
  const project = props.project;
  const projectUsers = props.projectUsers;
  const dateSpan = useContext(ReportDateSelectionContext);

  return (
    <ReportProjectContainer
      timeline={dateSpan}
      project={project}
      projectUsers={projectUsers}
    />
  );
}

interface ReportProjectContainerProps {
  timeline: DateSpan;
  project: Project;
  projectUsers: Array<ProjectUser>;
}

function ReportProjectContainer(props: ReportProjectContainerProps) {
  const { api } = useAuth();
  const timeline = props.timeline;
  const project = props.project;
  const projectUsers = props.projectUsers;
  const projectId = project.id.toString();

  const params = dateSpanToQueryParam(timeline);

  const fetchTimeEntries = api.fetchTimeEntriesByProjectId(projectId, params);
  const fetchBookings = api.fetchProjectBookingsByProjectId(projectId, params);

  if (fetchTimeEntries.isPending || fetchBookings.isPending) {
    return <Loading />;
  }

  if (fetchTimeEntries.isError || fetchBookings.isError) {
    return <InternalError />;
  }

  const timeEntries = fetchTimeEntries.data!;
  const bookings = fetchBookings.data!;

  return (
    <ReportProjectContainerInner
      timeline={timeline}
      projectUsers={projectUsers}
      timeEntries={timeEntries}
      bookings={bookings}
      project={project}
    />
  );
}

interface ReportProjectContainerInnerProps {
  timeEntries: Array<TimeEntry>;
  bookings: Array<Booking>;
  project: Project;
  projectUsers: Array<ProjectUser>;
  timeline: DateSpan;
}

function ReportProjectContainerInner(props: ReportProjectContainerInnerProps) {
  const { api } = useAuth();
  const timeEntries = props.timeEntries;
  const bookings = props.bookings;
  const projectUsers = props.projectUsers;

  const userIds = [
    ...new Set(
      [
        timeEntries.map((t) => t.user_id.toString()),
        bookings.map((b) => b.user_id.toString()),
        projectUsers.map((pu) => pu.user_id.toString()),
      ].flat(),
    ),
  ];

  const fetchUsers = api.fetchUsersByIds(userIds);

  if (fetchUsers.some((u) => u.isPending)) {
    return <Loading />;
  }
  if (fetchUsers.some((u) => u.isError)) {
    return <InternalError />;
  }
  const users = fetchUsers.map((u) => u.data!);

  return <ReportProjectContainerInnerInner {...props} users={users} />;
}

interface ReportProjectContainerInnerInnerProps
  extends ReportProjectContainerInnerProps {
  users: Array<User>;
}

function ReportProjectContainerInnerInner(
  props: ReportProjectContainerInnerInnerProps,
) {
  const { api } = useAuth();
  const timeEntries = props.timeEntries;
  const bookings = props.bookings;
  const projectUsers = props.projectUsers;
  const users = props.users;
  const params = dateSpanToQueryParam(props.timeline);

  const userIds = users.map((u) => u.id.toString());
  const locationIds = [
    ...new Set(users.filter((u) => u.location_id).map((u) => u.location_id!)),
  ];
  const fetchAbsences = api.fetchAbsencesByUserIds(userIds, params);
  const fetchHolidays = api.fetchHolidaysByLocationIds(locationIds, params);

  if (
    fetchAbsences.some((b) => b.isPending) ||
    fetchHolidays.some((b) => b.isPending)
  ) {
    return <div>loading</div>;
  }

  if (
    fetchHolidays.some((b) => b.isError) ||
    fetchAbsences.some((b) => b.isError)
  ) {
    return <div>error</div>;
  }

  const absences = fetchAbsences.flatMap((a) => a.data!);
  const holidaysByLocation = groupHolidaysByLocationId(
    fetchHolidays.flatMap((h) => h.data!),
  );

  const weeks = weeksBetweenDates(props.timeline.start, props.timeline.end)
    .reverse()
    .map((w) => w.date);

  const rawData = {
    users: users,
    projectUsers,
    bookings: bookings,
    timeEntries: timeEntries,
    absences: absences,
    holidaysByLocation: holidaysByLocation,
    weeks: weeks,
  };

  const d = buildProjectReportData(rawData);
  console.log(d);

  return (
    <div>
      <Row gutter={12}>
        <Col span={24}>
          <Card size="small" title="Details">
            <ProjectSummary data={d} />
            <Divider />
            <ProjectReportContainer2 data={d} />
          </Card>
        </Col>
      </Row>
      <br />
    </div>
  );
}

export function ProjectSummary(props: { data: ProjectReport }) {
  const data = props.data;

  const totalTrackedHours = getTotalTrackedHours(data.raw.timeEntries);
  const totalTrackedHoursByNonUsers = data.rows
    .filter((r) => r.header.isProjectMember === false)
    .reduce((s, r) => s + r.summary.amountTracked, 0);

  const totalAbsences = getTotalAbsences(data.raw.absences);
  const totalBookingHours = getTotalBookedHours(data.raw.bookings);
  const totalBillableHours = getTotalBillableHours(data.raw.timeEntries);

  const billingUtil =
    totalTrackedHours > 0 ? totalBillableHours / totalTrackedHours : 0;
  const totalPossibleBookingHours = Math.max(totalBookingHours, 0);
  const trackingUtil =
    totalPossibleBookingHours > 0
      ? totalTrackedHours / totalPossibleBookingHours
      : 0;

  const accuracyMeasurements = data.rows
    .map((r) => r.summary.accuracy)
    .filter((a) => !Number.isNaN(a));
  const accuracy =
    accuracyMeasurements.reduce((s, r) => s + r, 0) /
    accuracyMeasurements.length;

  // average hours per person
  // average hours per week?
  // staffing accuracy last 3 weeks
  // staffing accuracy last 5 weeks
  // staffing accuracy all time
  // hours tracked

  return (
    <div>
      <div className="flex flex-row justify-between gap-4">
        <SummaryPercentStat
          percent={accuracy}
          text="Accuracy"
          hover="How many hours the project is overstaffed / understaffed each weeky absences exist"
        />
        <SummaryPercentNumber
          value={totalTrackedHoursByNonUsers}
          text="Goodwill Hours"
          hover="How many hours were tracked by people not on this project"
        />
        <SummaryPercentStat
          percent={trackingUtil}
          text="Tracking Util"
          hover="The % of hours tracked vs what was staffed"
        />
        <SummaryPercentStat
          percent={billingUtil}
          text="Billing Util"
          hover="The % of billable hours tracked"
        />
        <SummaryPercentNumber
          value={totalAbsences}
          text="Absences"
          hover="How many absences exist"
        />
        <SummaryPercentNumber
          value={totalBillableHours}
          text="Billable Hours"
          hover="How many billable hours were tracked"
        />
        <SummaryPercentNumber
          value={totalTrackedHours}
          text="Total Hours"
          hover="How many hours were tracked"
        />
        <SummaryPercentNumber
          value={totalBookingHours}
          text="Staffed Hours"
          hover="How many hours were staffed"
        />
      </div>
    </div>
  );
}
function ProjectReportFirstRow(props: { raw: RawProjectData }) {
  const weeks = props.raw.weeks;

  return (
    <div className="table-row">
      <div className="col-start">
        <b>Person</b>
      </div>
      {weeks.map((w) => {
        const weekKey = weekKeyFromDate(w);
        const weekText = formatFriendlyDate(w);
        const weekNo = date2weekNum(w);

        return (
          <div className="col" key={weekKey}>
            <Tooltip title={weekText}>
              <b>CW: {weekNo.toString()}</b>
            </Tooltip>
          </div>
        );
      })}
      <div className="col-info">Summary</div>
    </div>
  );
}

function ProjectReportContainer2(props: { data: ProjectReport }) {
  const data = props.data;

  return (
    <div className="report-table">
      <ProjectReportFirstRow raw={data.raw} />
      <ProjectReportRows rows={data.rows} />
    </div>
  );
}

function ProjectReportRows(props: { rows: ProjectReport["rows"] }) {
  const rows = props.rows;

  return rows.map((row) => {
    const user = row.header.user;
    const rowKey = user ? user.id : "summary";

    return (
      <div key={rowKey} className="table-row">
        <div className="col-start">
          <ProjectReportRowHeader header={row.header} />
        </div>

        {row.columns.map((column) => (
          <div key={column.week.toString()} className="col">
            <ProjectReportRowColumn column={column} />
          </div>
        ))}

        <ProjectReportRowSummary summary={row.summary} />
      </div>
    );
  });
}

function ProjectReportRowSummary(props: { summary: ProjectCellSummary }) {
  const tracked = props.summary.averageTracked;
  const acc = props.summary.accuracy;

  const color = colorForCellEntry(acc);

  const accStr = formatDecimal((Number.isNaN(acc) ? 1 : acc) * 100);
  const diffStr = `${accStr}%`;

  return (
    <div className="cell-lines">
      <div className="line cursor-pointer">
        <Cell value={tracked.toFixed(2)} color={color} />
        <Cell value={diffStr} color={color} />
      </div>
    </div>
  );
}

interface ReportProjectContainerInnerInnerProps
  extends ReportProjectContainerInnerProps {
  users: Array<User>;
}

function ProjectReportRowHeader(props: { header: ProjectRowHeaderData }) {
  const header = props.header;

  if (header.title) {
    return <span>{header.title}</span>;
  }

  if (header.user) {
    return (
      <div>
        <LinkToUser image="small" user={header.user} />
        {!header.isProjectMember && (
          <span title="Not a project member">⚠</span>
        )}
      </div>
    );
  }

  return <span>unknown</span>;
}

function ProjectReportRowColumn(props: { column: ProjectCellData }) {
  const data = props.column;

  return (
    <Popover
      content={
        <UserWeekPopover
          week={data.week}
          user={data.user}
          holidays={data.holidays}
          absences={data.absences}
          bookings={data.bookings}
          timeEntries={data.timeEntries}
        />
      }
      trigger="click"
    >
      <div className="cell-lines">
        <CellEntry summary={data.summary!} />
      </div>
    </Popover>
  );
}

function CellEntry(props: { summary: ProjectCellSummary }) {
  const tracked = props.summary.amountTracked;
  const acc = props.summary.accuracy;

  const color = colorForCellEntry(acc);

  const accStr = formatDecimal((Number.isNaN(acc) ? 1 : acc) * 100);
  const diffStr = `${accStr}%`;

  return (
    <div className="line cursor-pointer">
      <Cell value={tracked.toFixed(2)} color={color} />
      <Cell value={diffStr} color={color} />
    </div>
  );
}
