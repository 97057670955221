import { ProjectTable } from "@/components/Projects/Table";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";
import { Loading } from "../../components/Status/Loading";
import { MainTitle } from "../../components/Typography";
import { ClientId, Project } from "@/models";
import { formatFriendlyDate } from "@/utils";
import { Button } from "antd";

export function ClientShow() {
  const { api } = useAuth();
  const { clientId } = useParams();

  if (clientId === undefined) {
    return <div>error</div>;
  }

  const fetchClient = api.fetchClientById(clientId);
  const projectIds = fetchClient.isSuccess ? fetchClient.data.project_ids : [];

  const fetchProjects = api.fetchProjectsByIds(projectIds ?? []);

  if (fetchClient.isPending || fetchProjects.some((x) => x.isPending)) {
    return <Loading />;
  }

  if (fetchClient.isError || fetchProjects.some((x) => x.isError)) {
    return <div>error</div>;
  }

  const projects = fetchProjects.map((p) => p.data!);

  return (
    <div className="flex flex-col gap-3">
      <ClientHeader clientId={clientId}>
        <Link to={`/clients/${clientId}/planner`}>
          <Button type="primary">Planner</Button>
        </Link>
      </ClientHeader>
      <ProjectTable projects={projects} />
    </div>
  );
}

export function ClientHeader(props: {
  clientId: ClientId;
  children?: React.ReactNode;
}) {
  const { api } = useAuth();
  const fetchClient = api.fetchClientById(props.clientId);
  const projectIds = fetchClient.isSuccess ? fetchClient.data.project_ids : [];
  const fetchProjects = api.fetchProjectsByIds(projectIds ?? []);

  if (fetchClient.isPending || fetchProjects.some((x) => x.isPending)) {
    return null;
  }

  if (fetchClient.isError || fetchProjects.some((x) => x.isError)) {
    return null;
  }
  const client = fetchClient.data!;
  const projects = fetchProjects.map((r) => r.data!);
  const earliestProject = projects.reduce((min, v) =>
    v.start_date < min.start_date ? v : min,
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="font-sans flex justify-between items-center">
        <MainTitle text={client.name} />
        {props.children}
      </div>
      <div className="flex items-center gap-4">
        <CustomerProjectBadges projects={projects} />
        <CustomerSince date={earliestProject?.start_date} />
      </div>
    </div>
  );
}

function CustomerSince(props: { date: Date }) {
  if (props.date === undefined) {
    return null;
  }
  const d = formatFriendlyDate(props.date);
  return <div className="text-gray-500">Customer since {d}</div>;
}

function CustomerProjectBadges(props: { projects: Project[] }) {
  const projects = props.projects;
  const activeProjects = projects.filter(
    (p) => p.status === "Active" || p.status === "Hyper-Care",
  );
  const completeProjects = projects.filter((p) => p.status === "Completed");
  const presalesProjects = projects.filter((p) => p.status === "Pre-Sales");
  return (
    <div className="flex gap-2">
      <span className="rounded-md bg-green-700 text-green-100 py-1 px-2 text-xs">
        Completed: {completeProjects.length}
      </span>
      <span className="rounded-md bg-blue-700 text-blue-100 py-1 px-2 text-xs">
        Active: {activeProjects.length}
      </span>
      <span className="rounded-md bg-yellow-700 text-yellow-100 py-1 px-2 text-xs">
        Presales: {presalesProjects.length}
      </span>
    </div>
  );
}
