import Card from "antd/es/card/Card";
import { groupBy } from "lodash-es";
import { LinkToSkill } from "../../components/Skills/LinkToSkill";
import { Loading } from "../../components/Status/Loading";
import { MainTitle } from "../../components/Typography";
import { SkillCategory } from "../../models";
import { useAuth } from "../../Providers/AuthProvider";
import { sortSkillsByName } from "../../utils";
import CreateSkill from "./CreateSkill";
import { InternalError } from "@/components/Status/InternalError";

export function SkillIndex() {
  const { api } = useAuth();
  const { isPending, isError, data } = api.fetchSkills();

  if (isPending) {
    return <Loading />;
  }

  if (isError) {
    return <InternalError />;
  }

  const skillCategories = new Map<number, SkillCategory>();
  data.categories.forEach((c) => {
    skillCategories.set(c.id, c);
  });

  const skillsGroupedByCategory = groupBy(data.skills, "skill_category_id");
  const skillCategoryIds = [...skillCategories.keys()];

  return (
    <div>
      <div className="flex justify-between">
        <MainTitle text="All Skills" />
        <CreateSkill />
      </div>
      {skillCategoryIds.map((category_id) => {
        const category = skillCategories.get(category_id);
        const skills = skillsGroupedByCategory[category_id] ?? [];
        const key = `cat-${category_id}`;

        if (category === undefined) {
          return <div key={key}>issue with category</div>;
        }

        if (skills.length === 0) {
          return null;
        }

        return (
          <div key={key}>
            <Card size="small" title={category.name}>
              <ul>
                {sortSkillsByName(skills).map((s) => (
                  <li key={s.id}>
                    <LinkToSkill skill={s} />
                  </li>
                ))}
              </ul>
            </Card>
            <br />
          </div>
        );
      })}
    </div>
  );
}
