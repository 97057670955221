import { createContext, useContext, useMemo, useState } from "react";

type ProjectStaffingContextType = {
  // We defined the user type in `index.d.ts`, but it's
  // a simple object with email, name and password.
  lastStaffingChange: Date | null;
  setLastStaffingChange?: (d: Date) => void;
};

const ProjectStaffingContext = createContext<ProjectStaffingContextType>({
  lastStaffingChange: null,
});
export function ProjectStaffingProvider(props: { children: React.ReactNode }) {
  const [lastStaffingChange, setLastStaffingChange] = useState<Date | null>(
    null,
  );

  const memoedValue = useMemo(
    () => ({
      lastStaffingChange: lastStaffingChange,
      setLastStaffingChange: setLastStaffingChange,
    }),
    [lastStaffingChange, setLastStaffingChange],
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <ProjectStaffingContext.Provider value={memoedValue}>
      {props.children}
    </ProjectStaffingContext.Provider>
  );
}
export function useProjectStaffing() {
  return useContext(ProjectStaffingContext);
}
