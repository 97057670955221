interface Measurement {
  tracked: number;
  booked: number;
}

export function accuracy(measurements: Array<Measurement>): number {
  const filteredMeasurements = measurements.filter((m) => m.booked !== 0 || m.tracked !== 0);
  if (filteredMeasurements.length === 0) {
    return Number.NaN;
  }

  const totalBooked = filteredMeasurements.reduce((s, m) => s + m.booked, 0);
  const totalTracked = filteredMeasurements.reduce((s, m) => s + m.tracked, 0);

  if (totalBooked === 0 && totalTracked === 0) {
    return 1;
  }

  if (totalBooked === 0) {
    return 0;
  }

  const totalDiff = Math.min(
    totalBooked,
    filteredMeasurements.reduce((s, m) => s + Math.abs(m.tracked - m.booked), 0),
  );
  return 1 - totalDiff / totalBooked;
}
