import { Project, Timeline, User, UserAndProjectUser } from "../../../models";
import { useAuth } from "../../../Providers/AuthProvider";
import { Tabs, type TabsProps } from "../../Antd";
import { Loading } from "../../Status/Loading";
import { AddUserByName } from "./ByName";
import { AddUserBySkill } from "./BySkill";

interface AddUserToProjectProps {
  projectUsersWithUsers: Array<UserAndProjectUser>;
  project: Project;
  onAddUser: (u: User) => void;
  timeline: Timeline;
}

export function AddUserToProject(props: AddUserToProjectProps) {
  const { api } = useAuth();
  const fetchUsers = api.fetchUsers();
  const fetchSkills = api.fetchSkills();
  const fetchUserSkills = api.fetchUserSkills();

  if (
    fetchUsers.isPending ||
    fetchSkills.isPending ||
    fetchUserSkills.isPending
  ) {
    return <Loading />;
  }

  if (fetchUsers.isError || fetchSkills.isError || fetchUserSkills.isError) {
    return <div>error</div>;
  }

  const users = fetchUsers.data;
  const skills = fetchSkills.data.skills;
  const userSkills = fetchUserSkills.data;

  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Name`,
      children: <AddUserByName users={users} {...props} />,
    },
    {
      key: "2",
      label: `Skill`,
      children: (
        <AddUserBySkill
          users={users}
          skills={skills}
          userSkills={userSkills}
          {...props}
        />
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} onChange={onChange} />;
}
