import { useParams, useSearchParams } from "react-router-dom";
import { Divider, Layout } from "../../components/Antd";
import { Forbidden } from "../../components/Status/Forbidden";
import { InternalError } from "../../components/Status/InternalError";
import { Loading } from "../../components/Status/Loading";
import { NotFound } from "../../components/Status/NotFound";
import { MainTitle } from "../../components/Typography";
import { Workbook } from "../../components/Workbook/Table";
import { paramsToOptions } from "../../components/Workbook/util";
import { useAuth } from "../../Providers/AuthProvider";
import { canViewWorkbook } from "../../Providers/permissions";
import { createDateFromYearCW, date2weekNum } from "../../utils";

export function WorkbookIndex() {
  const { api, roles } = useAuth();
  const { year, cw } = useParams();
  const [searchParams, _] = useSearchParams();

  const options = paramsToOptions(searchParams);

  if (year === undefined || cw === undefined) {
    return <NotFound />;
  }

  if (!canViewWorkbook(roles)) {
    return <Forbidden />;
  }

  const weekDate = createDateFromYearCW(year, cw);
  const cwD = date2weekNum(weekDate);

  const fetchWorkbook = api.fetchWorkbook(weekDate);
  const fetchTeams = api.fetchTeams();
  const fetchLocations = api.fetchLocations();
  const fetchIgnoredUsers = api.fetchIgnoredUsers();

  if (
    fetchWorkbook.isLoading ||
    fetchTeams.isLoading ||
    fetchLocations.isLoading ||
    fetchIgnoredUsers.isLoading
  ) {
    return <Loading />;
  }

  if (
    fetchWorkbook.isError ||
    fetchTeams.isError ||
    fetchLocations.isError ||
    fetchIgnoredUsers.isError
  ) {
    return <InternalError />;
  }

  return (
    <Layout>
      <MainTitle>
        Workbook - {year} - <LinkToWeek year={year} week={cwD} />
      </MainTitle>
      <Divider />
      <Workbook
        options={options}
        ignoredUsers={fetchIgnoredUsers.data!}
        locations={fetchLocations.data!}
        teamsWithUsers={fetchTeams.data!}
        workbook={fetchWorkbook.data!}
      />
    </Layout>
  );
}

function LinkToWeek(props: { year: string; week: number }) {
  return <a href={`/workbook/${props.year}/${props.week}`}>CW{props.week}</a>;
}
