import { SizeType } from "antd/es/config-provider/SizeContext";
import { useState } from "react";
import toast from "react-hot-toast";
import { useAuth } from "../../Providers/AuthProvider";
import { Button, Form, Modal } from "../../components/Antd";
import { Loading } from "../../components/Status/Loading";
import { ProjectUserRequest } from "../../models";
import { ProjectUserRequestForm } from "./Form";

const modeForProjectUserRequest = (pur: ProjectUserRequest | undefined) => {
  if (pur === undefined) {
    return "create";
  }

  if (pur.approver_id === null && pur.rejecter_id === null) {
    return "edit";
  }

  return "view";
};

const textForButton = (mode: string) => {
  const str = mode;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

interface CreateModalProps {
  projectUserRequest?: ProjectUserRequest;
  onFinish: (pur: ProjectUserRequest) => void;
  size: SizeType;
}
export function ProjectUserRequestModal(props: CreateModalProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const showModal = () => setIsModalOpen(true);
  const handleOk = () => setIsModalOpen(false);
  const handleCancel = () => setIsModalOpen(false);

  const mode = modeForProjectUserRequest(props.projectUserRequest);

  const { api } = useAuth();
  const mut =
    mode === "create"
      ? api.createProjectUserRequest()
      : api.updateProjectUserRequest();

  const [form] = Form.useForm();

  const projectData = api.fetchProjects();
  const userData = api.fetchUsers();

  if (projectData.isPending || userData.isPending) {
    return <Loading />;
  }

  if (projectData.isError || userData.isError) {
    return <div>error</div>;
  }

  const onFinish = (pur: ProjectUserRequest) => {
    mut
      .mutateAsync(pur)
      .then(() => {
        const newPur = { ...props.projectUserRequest, ...pur };
        handleOk();
        props.onFinish(newPur);
      })
      .catch(() => {
        toast("There was an error creating the request");
      });
  };

  return (
    <>
      <Button
        className="z-10"
        type="primary"
        size={props.size}
        onClick={showModal}
      >
        {textForButton(mode)}
      </Button>
      <Modal
        title="Request user for project"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer=""
      >
        <ProjectUserRequestForm
          projectUserRequest={props.projectUserRequest}
          mode={mode}
          form={form}
          onFinish={onFinish}
          projects={projectData.data}
          users={userData.data}
        />
      </Modal>
    </>
  );
}
