import { Button } from "antd";
import Card from "antd/es/card/Card";
import { Link, useParams } from "react-router-dom";
import {
  BookingLine,
  BookingsHeader,
  BookingTable,
} from "../../components/Bookings/Structure";
import { UserBookingName } from "../../components/Generic/UserBookingName";
import { InternalError } from "../../components/Status/InternalError";
import { Loading } from "../../components/Status/Loading";
import { MainTitle } from "../../components/Typography";
import { UserStaffingLine } from "../../components/Users/UserStaffing";
import type { User, UserWithUserSkill } from "../../models";
import { useAuth } from "../../Providers/AuthProvider";
import { canUpdateSkills } from "../../Providers/permissions";
import {
  buildDefaultTimeline,
  groupUserWithUserSkillsBySkillLevel,
  sortUsersBySkill,
  userSkillToString,
} from "../../utils";
import { DeleteSkill } from "./DeleteSkill";

export function SkillShow() {
  const { api, roles } = useAuth();
  const { skillId } = useParams();

  if (skillId === undefined) {
    return <InternalError />;
  }

  const { isPending, isError, data } = api.fetchSkillById(skillId);

  if (isPending) {
    return <Loading />;
  }

  if (isError) {
    return <InternalError />;
  }

  const areAdminButtonsVisible = canUpdateSkills(roles);

  const groupedUsers = groupUserWithUserSkillsBySkillLevel(
    data.users_with_user_skills,
  );

  return (
    <div>
      <div className="flex justify-between">
        <MainTitle text={data.skill.name} />
        {areAdminButtonsVisible && (
          <div className="flex gap-2">
            <Link to={`/skills/${skillId}/edit`}>
              <Button type="primary">Edit</Button>
            </Link>
            <DeleteSkill skillId={skillId} />
          </div>
        )}
      </div>

      {data.users_with_user_skills.length === 0 && (
        <p>Unfortunately, nobody has this skill (yet).</p>
      )}

      <UserList users={groupedUsers.get(5) ?? []} level={5} />
      <UserList users={groupedUsers.get(4) ?? []} level={4} />
      <UserList users={groupedUsers.get(3) ?? []} level={3} />
      <UserList users={groupedUsers.get(2) ?? []} level={2} />
      <UserList users={groupedUsers.get(1) ?? []} level={1} />
    </div>
  );
}

interface UserListProps {
  users: Array<UserWithUserSkill>;
  level: number;
}

function UserList(props: UserListProps) {
  const people = sortUsersBySkill(props.users).reverse();
  const title = userSkillToString(props.level);
  const timeline = buildDefaultTimeline();

  if (people.length === 0) {
    return <></>;
  }

  return (
    <>
      <Card size="small" title={title}>
        <BookingTable width="300px">
          <BookingLine>
            <BookingsHeader timeline={timeline} />
          </BookingLine>
          {people.map((u) => {
            const user = u.user;
            const user_skill = u.user_skill;
            if (user_skill.level === 0) {
              return null;
            }

            const lc = <UserSkillName user={user} level={user_skill.level} />;

            return (
              <UserStaffingLine
                lineContent={lc}
                key={user.id}
                user_id={user.id}
                timeline={timeline}
                visibleKinds={[]}
              />
            );
          })}
        </BookingTable>
      </Card>
      <br />
    </>
  );
}

function UserSkillName(props: { user: User; level: number }) {
  return (
    <UserBookingName
      user={props.user}
      subText={`Level ${props.level} - ${props.user.title}`}
    />
  );
}
