import type { TooltipPlacement } from "../Antd";
import { Button, Popconfirm } from "../Antd";

interface PopoverButtonConfirmProps {
  title: string;
  content: React.ReactNode;
  icon: React.ReactNode;
  danger?: boolean;
  placement?: TooltipPlacement;
  okText: string;
  onConfirm: () => void;
}

export function PopoverButtonConfirm(props: PopoverButtonConfirmProps) {
  return (
    <Popconfirm
      placement={props.placement ?? "topRight"}
      title={props.title}
      description={props.content}
      onConfirm={props.onConfirm}
      okText={props.okText}
      trigger="click"
    >
      <Button
        size="small"
        danger={props.danger}
        type="primary"
        shape="circle"
        icon={props.icon}
      />
    </Popconfirm>
  );
}
