import { Button } from "antd";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";
import { isAdmin, isStaffingManager } from "../../Providers/permissions";
import { Card } from "../../components/Antd";
import { KindSelector } from "../../components/Bookings/KindSelector";
import { InternalError } from "../../components/Status/InternalError";
import { Loading } from "../../components/Status/Loading";
import { MainTitle } from "../../components/Typography";
import { UserStaffing } from "../../components/Users/UserStaffing";
import { BookingKind } from "../../models/booking";
import { sortUsersByGivenName } from "../../utils";
import { buildDefaultTimeline } from "../../utils/date";

export function TeamShow() {
  const { api, roles } = useAuth();
  const { teamId } = useParams();
  const [visibleKinds, setVisibleKinds] = useState<BookingKind[]>([]);

  if (teamId === undefined) {
    return <div>error</div>;
  }
  const fetchTeam = api.fetchTeamById(teamId);
  const fetchIgnoredUsers = api.fetchIgnoredUsers();

  if (fetchTeam.isPending || fetchIgnoredUsers.isPending) {
    return <Loading />;
  }

  if (fetchTeam.isError || fetchIgnoredUsers.isError) {
    return <InternalError />;
  }

  const canSeeReportButton = isAdmin(roles) || isStaffingManager(roles);
  const sortedUsers = sortUsersByGivenName(fetchTeam.data.users);
  const team = fetchTeam.data.team;

  const timeline = buildDefaultTimeline();
  return (
    <div>
      <div className="flex justify-between">
        <MainTitle title={team.name}>{team.name} team overview.</MainTitle>
        {canSeeReportButton && (
          <Link to={`/reports/teams/${team.id}`}>
            <Button type="primary">Report</Button>
          </Link>
        )}
      </div>
      <Card
        size="small"
        title="Staffing Overview"
        extra={
          <KindSelector
            onChange={(ks) => setVisibleKinds(ks)}
            selected={visibleKinds}
          />
        }
      >
        <UserStaffing
          users={sortedUsers}
          ignoredUsers={fetchIgnoredUsers.data}
          timeline={timeline}
          visibleKinds={visibleKinds}
        />
      </Card>
    </div>
  );
}
