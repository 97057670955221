import { Team } from "./team";
import { Skill } from "./skill";
import { Location } from "./location";
import { Tenant } from "./tenant";

export enum UserRole {
  Admin = "admin",
  StaffingManager = "staffing_manager",
  User = "user",
}

export enum NamedRole {
  Admin = "Admin",
  StaffingManager = "Staffing Manager",
  SkillManager = "Skill Manager",
  RequestManager = "Request Manager",
  UserManager = "User Manager",
  TenantViewer = "Tenant Viewer",
  LocationViewer = "Location Viewer",
}

export interface UserDetails {
  user: User;
  team: Team | undefined;
  tenant: Tenant | null;
  manager: User | null;
  location: Location | null;
  skills?: Array<Skill>;
}

export type UserImbalance = {
  user_id: number;
  status: "overbooked" | "underbooked";
  available_hours: number;
  booked_hours: number;
  week_number: number;
};

export interface User extends BaseUser {
  key?: string;
  location_id: number | null;
  tenant_id: number | null;
  email: string;
  name: string;
  roles: Array<NamedRole>;
  title: string;
  active: boolean;
  manager_id: number | null;
  working_hours: number;
}

export interface BaseUser {
  id: number;
  given_name: string;
  family_name: string;
}
