import dayjs from "dayjs";

import type {
  Location,
  Project,
  ProjectAndProjectUser,
  ProjectRole,
  ProjectStats,
  ProjectTask,
  ProjectTrackingSummary,
  ProjectUserStaffingSuggestion,
  Skill,
  TeamWithUsers,
  Tenant,
  User,
  UserAndProjectUser,
  UserWithUserSkill,
} from "../models";
import type {
  TeamLeadReportProjectDetail,
  TeamLeadReportProjectDetailUser,
  TeamLeadReportUserDetail,
  TeamLeadReportUserDetailProject,
} from "../routes/Reports/teamLeadTypes";

export function sortTeamLeadReportUserDetailsByTotalHours(
  users: Array<TeamLeadReportUserDetail>,
) {
  return [...users].sort((a, b) =>
    sortNumber(
      b.billable_hours + b.non_billable_hours,
      a.billable_hours + a.non_billable_hours,
    ),
  );
}

export function sortTeamLeadReportUserDetailProjectsByTotalHours(
  projects: Array<TeamLeadReportUserDetailProject>,
) {
  return [...projects].sort((a, b) =>
    sortNumber(
      b.billable_hours + b.non_billable_hours,
      a.billable_hours + a.non_billable_hours,
    ),
  );
}

export function sortTeamLeadReportProjectDetailsByTotalHours(
  projects: Array<TeamLeadReportProjectDetail>,
) {
  return [...projects].sort((a, b) =>
    sortNumber(
      b.billable_hours + b.non_billable_hours,
      a.billable_hours + a.non_billable_hours,
    ),
  );
}

export function sortTeamLeadReportProjectDetailUsersByTotalHours(
  projects: Array<TeamLeadReportProjectDetailUser>,
) {
  return [...projects].sort((a, b) =>
    sortNumber(
      b.billable_hours + b.non_billable_hours,
      a.billable_hours + a.non_billable_hours,
    ),
  );
}

export function sortTeamsByName(teams: Array<TeamWithUsers>) {
  return [...teams].sort((a, b) => sortText(a.team.name, b.team.name));
}

export function sortTenantsByName(tenants: Array<Tenant>) {
  return [...tenants].sort((a, b) => sortText(a.name, b.name));
}

export function sortUsersBySkill(users: Array<UserWithUserSkill>) {
  return [...users].sort((a, b) =>
    sortNumber(a.user_skill.level, b.user_skill.level),
  );
}

export function sortLocationsByName(locations: Array<Location>) {
  return [...locations].sort((a, b) => sortText(a.name, b.name));
}

export function sortProjectRolesByName(skills: Array<ProjectRole>) {
  return [...skills].sort((a, b) => sortText(a.name, b.name));
}

export function sortProjectTasksByName(skills: Array<ProjectTask>) {
  return [...skills].sort((a, b) => sortText(a.name, b.name));
}

export function sortSkillsByName(skills: Array<Skill>) {
  return [...skills].sort((a, b) => sortText(a.name, b.name));
}

export function sortProjectsByName(projects: Array<Project>) {
  return [...projects].sort((a, b) => sortText(a.name, b.name));
}

export function sortUsersByGivenName(users: Array<User>) {
  // Sort by given_name + family_name. Otherwise results are inconsistent when names are repeated
  return [...users].sort((a, b) =>
    sortText(
      `${a.given_name} ${a.family_name}`,
      `${b.given_name} ${b.family_name}`,
    ),
  );
}

export function sortProjectUsersByGivenName(
  projectUsers: Array<UserAndProjectUser>,
) {
  return [...projectUsers].sort((a, b) =>
    sortText(a.user.given_name, b.user.given_name),
  );
}

export function sortProjectUsersByProjectName(
  pus: Array<ProjectAndProjectUser>,
) {
  return [...pus].sort((a, b) => sortText(a.project.name, b.project.name));
}

export function sortProjectTrackingSummariesByDate(
  s: Array<ProjectTrackingSummary>,
) {
  return [...s].sort((a, b) => sortDate(a.last_tracking, b.last_tracking));
}
export function sortDate(a: Date | string, b: Date | string): number {
  const futureDate = dayjs().add(100, "years").toDate();

  if (a === null) {
    a = futureDate;
  }

  if (b === null) {
    b = futureDate;
  }

  const da = dayjs(a);
  const db = dayjs(b);

  if (da.isSame(db)) {
    return 0;
  }

  return da.isBefore(db) ? -1 : 1;
}

export function sortNumber(a: number, b: number): number {
  return defaultSort(a, b);
}

export function sortStat(a: Project, b: Project, stat: string): number {
  const key = stat as keyof ProjectStats;

  const a_stat = a.stats ?? ({} as ProjectStats);
  const b_stat = b.stats ?? ({} as ProjectStats);

  const val_a = a_stat[key] ?? 0;
  const val_b = b_stat[key] ?? 0;

  return defaultSort(val_a, val_b);
}

export function sortText(a: string, b: string): number {
  return defaultSort(a, b);
}

export function filterAndSortByDateProjectDataStaffingRequest(
  data: Array<ProjectUserStaffingSuggestion>,
  status: string,
) {
  return data
    .filter((pusr) => pusr.staffing_suggestion.status === status)
    .sort((a, b) =>
      sortText(
        a.staffing_suggestion.created_at,
        b.staffing_suggestion.created_at,
      ),
    );
}

function defaultSort(a: any, b: any): number {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}
