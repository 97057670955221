import React, { ReactNode } from "react";
import { BookingMode, Timeline } from "../../models";
import { Tooltip } from "../../components/Antd";
import { weeksBetweenDates, WeekInfo, formatFriendlyDate } from "../../utils";

const { Provider, Consumer } = React.createContext({ width: "10px" });

interface BookingTableProps {
  children: ReactNode;
  width: string;
}

export function BookingTable(props: BookingTableProps) {
  const nameWidth = props.width ?? "200px";

  return (
    <Provider value={{ width: nameWidth }}>
      <div className="booking-table" style={{ marginLeft: nameWidth }}>
        <table className="booking-inner-table">
          <tbody>{props.children}</tbody>
        </table>
      </div>
    </Provider>
  );
}

interface BookingLineProps {
  children: ReactNode;
  lineContent?: ReactNode;
  mode?: BookingMode;
}
export function BookingLine(props: BookingLineProps) {
  const lineContent = props.lineContent;
  const headerClass =
    props.mode === BookingMode.Edit ? "headcol edit-mode" : "headcol";

  return (
    <tr>
      <Consumer>
        {(value) => (
          <th className={headerClass} style={{ width: value.width }}>
            {lineContent ? lineContent : <></>}
          </th>
        )}
      </Consumer>

      {props.children}
    </tr>
  );
}

interface BookingsHeaderProps {
  timeline: Timeline;
}
export function BookingsHeader(props: BookingsHeaderProps) {
  const timeline = props.timeline;
  const weeks = weeksBetweenDates(timeline.view.start, timeline.view.end);

  return (
    <>
      {weeks.map((w) => (
        <BookingHeaderCell key={`bh-${w.year}-${w.week}`} week={w} />
      ))}
    </>
  );
}

interface BookingHeaderCellProps {
  week: WeekInfo;
}

function BookingHeaderCell(props: BookingHeaderCellProps) {
  const week = props.week;
  let classNames = ["allocation", "week-header"].join(" ");

  const w = formatFriendlyDate(week.date);

  return (
    <td className={classNames}>
      <Tooltip title={w}>
        W{week.week}
        <br />
        <span className="tinyYear">{week.year}</span>
      </Tooltip>
    </td>
  );
}
