import type { UseQueryResult } from "@tanstack/react-query";
import { Card, Col, Row } from "../../components/Antd";
import { InternalError } from "../../components/Status/InternalError";
import { Loading } from "../../components/Status/Loading";
import type { Booking, DateSpan, TimeEntry, User } from "../../models";
import { useAuth } from "../../Providers/AuthProvider";
import {
  addWeeks,
  dateSpanToQueryParam,
  weekEnd,
  weekStart,
  weeksBetweenDates,
} from "../../utils";
import { UserReport } from "../Reports/User";

interface UserTimeEntriesProps {
  user: User;
  months: number;
}

function collectUniqueProjectIds(
  fetchTimeEntries: UseQueryResult<Array<TimeEntry>>,
  fetchBookings: UseQueryResult<Array<Booking>>,
) {
  if (!fetchTimeEntries.isSuccess || !fetchBookings.isSuccess) {
    return [];
  }

  const timeEntryProjectIds = new Set(
    fetchTimeEntries.data.map((t) => t.project_id),
  );
  const bookingProjectIds = new Set(
    fetchBookings.data.map((b) => b.project_id),
  );

  return [...new Set([...timeEntryProjectIds, ...bookingProjectIds])];
}

export function UserTimeEntries(props: UserTimeEntriesProps) {
  const { api } = useAuth();

  const today = new Date();
  const start = weekStart(addWeeks(today, -8).toDate());
  const end = weekEnd(addWeeks(today, -1).toDate());
  const timeline = {
    start: start.toDate(),
    end: end.toDate(),
  } as DateSpan;

  const params = dateSpanToQueryParam(timeline);
  const fetchTimeEntries = api.fetchTimeEntriesByUserId(
    props.user.id.toString(),
    params,
  );
  const fetchHolidays = api.fetchHolidaysByLocationId(
    props.user.location_id ?? 0,
    params,
  );
  const fetchBookings = api.fetchProjectBookingsByUserId(
    props.user.id.toString(),
    params,
  );
  const fetchAbsences = api.fetchAbsencesByUserId(
    props.user.id.toString(),
    params,
  );
  const fetchProjectUsers = api.fetchProjectUsersByUserId(
    props.user.id.toString(),
  );

  const projectIds = collectUniqueProjectIds(fetchTimeEntries, fetchBookings);
  const fetchProjects = api.fetchProjectsByIds(
    projectIds.map((p) => p.toString()),
  );

  if (
    fetchTimeEntries.isPending ||
    fetchHolidays.isPending ||
    fetchAbsences.isPending ||
    fetchBookings.isPending ||
    fetchProjectUsers.isPending ||
    fetchProjects.some((s) => s.isPending)
  ) {
    return <Loading />;
  }

  if (
    fetchTimeEntries.isError ||
    fetchHolidays.isError ||
    fetchAbsences.isError ||
    fetchBookings.isError ||
    fetchProjectUsers.isError ||
    fetchProjects.some((s) => s.isError)
  ) {
    return <InternalError />;
  }

  const projects = fetchProjects.map((p) => p.data!);
  const weeks = weeksBetweenDates(timeline.start, timeline.end)
    .reverse()
    .map((w) => w.date);

  const projectUsers = fetchProjectUsers.data.map((pu) => pu.project_user);

  return (
    <>
      <Row>
        <Col span={24}>
          <Card size="small" title="Past few weeks">
            <UserReport
              weeks={weeks}
              user={props.user}
              projectUsers={projectUsers}
              timeEntries={fetchTimeEntries.data}
              projects={projects}
              bookings={fetchBookings.data}
              absences={fetchAbsences.data}
              holidays={fetchHolidays.data}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}
