import { IDoesFilterPassParams } from "@ag-grid-community/core";
import { CustomFilterProps, useGridFilter } from "@ag-grid-community/react";
import { useCallback, useEffect, useState } from "react";

interface SetFilterState {
  filterText: string;
  availableValues: Set<string>;
  selectedValues: Set<string>;
  selectAll: boolean;
}

const SetFilter = ({
  model,
  onModelChange,
  getValue,
  api,
}: CustomFilterProps) => {
  const [state, setState] = useState<SetFilterState>({
    filterText: "",
    availableValues: new Set(),
    selectedValues: new Set(),
    selectAll: true,
  });

  const buildAvailableOptions = () => {
    const values = new Set<string>();

    api.forEachNode((node) => {
      if (node.data) {
        const v = getValue(node);
        const val = v === null ? "" : v.toString();
        values.add(val);
      }
    });

    return values;
  };
  // Initialize available values on mount
  useEffect(() => {
    const values = buildAvailableOptions();

    setState((prev) => ({
      ...prev,
      availableValues: values,
      selectedValues: values,
    }));
  }, []);

  const doesFilterPass = useCallback(
    (params: IDoesFilterPassParams) => {
      const { node } = params;
      const value: string = (getValue(node) ?? "").toString();

      return state.selectedValues.has(String(value));
    },
    [model],
  );

  useGridFilter({ doesFilterPass });

  useEffect(() => {
    if (state.selectAll === false && state.selectedValues.size === 0) {
      console.log("reset");
      onModelChange(null);
    } else {
      onModelChange(state);
    }
  }, [state]);

  // Filter the available values based on search text
  const filteredValues = Array.from(state.availableValues).filter((value) =>
    value.toLowerCase().includes(state.filterText.toLowerCase()),
  );

  // Handle individual checkbox changes
  const handleValueChange = (value: string, checked: boolean) => {
    setState((prev) => {
      const newSelected = new Set(prev.selectedValues);
      if (checked) {
        newSelected.add(value);
      } else {
        newSelected.delete(value);
      }
      return {
        ...prev,
        selectedValues: newSelected,
        selectAll: newSelected.size === prev.availableValues.size,
      };
    });
  };

  // Handle select all change
  const handleSelectAllChange = (checked: boolean) => {
    setState((prev) => ({
      ...prev,
      selectedValues: checked ? new Set(prev.availableValues) : new Set(),
      selectAll: checked,
    }));
  };

  return (
    <div className="ag-set-filter p-2 min-w-[200px] max-h-[400px] flex flex-col">
      {/* Search input */}
      <div className="mb-2">
        <input
          type="text"
          className="w-full p-2 border text-sm"
          placeholder="Search..."
          value={state.filterText}
          onChange={(e) =>
            setState((prev) => ({ ...prev, filterText: e.target.value }))
          }
        />
      </div>

      {/* Select all checkbox */}
      <div className="mb-2 border-b pb-2">
        <label className="flex items-center text-sm">
          <input
            type="checkbox"
            className="mr-2"
            checked={state.selectAll}
            onChange={(e) => handleSelectAllChange(e.target.checked)}
          />
          (Select All)
        </label>
      </div>

      {/* Value list */}
      <div className="overflow-auto flex-grow">
        {filteredValues.map((value) => (
          <label
            key={value}
            className="flex items-center p-1 text-sm hover:bg-gray-100"
          >
            <input
              type="checkbox"
              className="mr-2"
              checked={state.selectedValues.has(value)}
              onChange={(e) => handleValueChange(value, e.target.checked)}
            />
            {value}
          </label>
        ))}
      </div>
    </div>
  );
};

export default SetFilter;
