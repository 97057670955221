type BadgeType = "primary" | "warn" | "info" | "success";

const badgeColors = {
  primary: "bg-green-300 text-green-600",
  warn: "bg-red-300 text-red-900",
  info: "bg-blue-300 text-blue-800",
  success: "bg-green-300 text-green-600",
};

export function Badge(props: { type: BadgeType; text: string }) {
  const className = `flex h-4 items-center ${badgeColors[props.type]} font-bold rounded-sm p-1 font-sans `;
  return <span className={className}>{props.text}</span>;
}
