import { LinkToUser } from "../../components/Users/LinkToUser";
import {
  Absence,
  Holiday,
  TimeEntry,
  User,
  UserWorkDaySchedule,
} from "../../models";
import {
  addDays,
  daysBetweenDates,
  formatFriendlyDate,
  groupAbsencesByUserId,
  groupTimeEntriesByUserId,
  groupUserWorkDaySchedulesByUserId,
  isoWeekday,
  parseDate,
  sameDay,
} from "../../utils";

interface MissingDaysProps {
  start: Date;
  end: Date;
  timeEntries: Array<TimeEntry>;
  absences: Array<Absence>;
  users: Array<User>;
  holidays?: Map<number, Array<Holiday>>;
  workSchedules: Array<UserWorkDaySchedule>;
}
function isWorkingDay(days: Array<UserWorkDaySchedule>, date: string): boolean {
  const d = parseDate(date);
  const dayOfWeek = d.getUTCDay();

  if (days.length > 0 && days[0].user_id === 987 && dayOfWeek === 4) {
    //debugger
  }
  return days.some((x) => x.day === dayOfWeek && x.minutes > 0);
}

function isInPast(d: string) {
  const dd = addDays(parseDate(d), 1).toDate();
  return dd < new Date();
}

function getMissingDays(
  expectedDays: Array<string>,
  timeEntries: Array<TimeEntry>,
  absences: Array<Absence>,
  holidays: Array<Holiday>,
  workSchedules: Array<UserWorkDaySchedule>,
): Array<string> {
  const days = new Set(expectedDays);
  const timeEntryDays = new Set(timeEntries.map((t) => t.date));
  const absenceDays = new Set(absences.map((a) => a.date));
  const holidayDays = new Set(holidays.map((h) => h.date));

  return [...days].filter(
    (d) =>
      isWorkingDay(workSchedules, d) &&
      isInPast(d) &&
      !timeEntryDays.has(d) &&
      !absenceDays.has(d) &&
      !holidayDays.has(d),
  );
}

interface UserWithDays {
  user: User;
  days: Array<string>;
}

function getUsersMissingDays(
  expectedDays: Array<string>,
  users: Array<User>,
  timeEntries: Array<TimeEntry>,
  absences: Array<Absence>,
  workSchedules: Array<UserWorkDaySchedule>,
  holidays?: Map<number, Array<Holiday>>,
): Array<UserWithDays> {
  const timeEntriesByUserId = groupTimeEntriesByUserId(timeEntries);
  const absencesByUserId = groupAbsencesByUserId(absences);
  const workSchedulesById = groupUserWorkDaySchedulesByUserId(workSchedules);
  const missedUsers: Array<UserWithDays> = [];

  users.forEach((u) => {
    const locationId = u.location_id;
    const userHolidays = (holidays ? holidays.get(locationId!) : []) ?? [];
    const timeEntries = timeEntriesByUserId.get(u.id) ?? [];
    const absences = absencesByUserId.get(u.id) ?? [];
    const workSchedules = workSchedulesById.get(u.id) ?? [];

    const missingDays = getMissingDays(
      expectedDays,
      timeEntries,
      absences,
      userHolidays,
      workSchedules,
    );

    if (missingDays.length > 0) {
      missedUsers.push({
        user: u,
        days: missingDays,
      });
    }
  });

  return missedUsers;
}

export function MissingDays(props: MissingDaysProps) {
  const endDate = sameDay(props.end, new Date())
    ? addDays(props.end, -1).toDate()
    : props.end;
  const days = daysBetweenDates(props.start, endDate)
    .reverse()
    .filter((d) => isoWeekday(d) < 6);

  const missedUsers = getUsersMissingDays(
    days,
    props.users,
    props.timeEntries,
    props.absences,
    props.workSchedules,
    props.holidays,
  );

  if (missedUsers.length === 0) {
    return <div>Everything looks good!</div>;
  }

  return (
    <>
      {missedUsers.map((i) => {
        return (
          <div key={i.user.id}>
            <LinkToUser user={i.user} />: {i.days.length} missing days -{" "}
            {i.days.map((d) => formatFriendlyDate(d)).join(", ")}{" "}
          </div>
        );
      })}
    </>
  );
}
