import { Button, Result } from "../Antd";
import { Link } from "react-router-dom";

export function Forbidden() {
  return (
    <Result
      status="403"
      title="Forbidden"
      subTitle="You do not have access to this"
      extra={
        <Link to="/">
          <Button type="primary">Back Home</Button>
        </Link>
      }
    />
  );
}
