import { UseMutationResult } from "@tanstack/react-query";
import { Checkbox, Select } from "antd";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Button, Card, Divider, Form, Input } from "../../components/Antd";
import { Forbidden } from "../../components/Status/Forbidden";
import { InternalError } from "../../components/Status/InternalError";
import { Loading } from "../../components/Status/Loading";
import { MainTitle } from "../../components/Typography";
import type { Location, Role, Tenant, User } from "../../models";
import { useAuth } from "../../Providers/AuthProvider";
import { canEditUser } from "../../Providers/permissions";
import { sortLocationsByName, sortTenantsByName, userName } from "../../utils";

export function UserEdit() {
  const { api, roles } = useAuth();
  const { userId } = useParams();

  if (userId === undefined) {
    return <div>error</div>;
  }

  if (!canEditUser(roles)) {
    return <Forbidden />;
  }

  const fetchLocations = api.fetchLocations();
  const fetchTenants = api.fetchTenants();
  const userData = api.fetchUserById(userId);
  const rolesData = api.fetchRoles();

  if (
    fetchLocations.isPending ||
    userData.isPending ||
    rolesData.isPending ||
    fetchTenants.isPending
  ) {
    return <Loading />;
  }
  if (
    fetchLocations.isError ||
    userData.isError ||
    rolesData.isError ||
    fetchTenants.isError
  ) {
    return <InternalError />;
  }

  return (
    <Edit
      user={userData.data}
      roles={rolesData.data}
      locations={fetchLocations.data}
      tenants={fetchTenants.data}
    />
  );
}

function userMutationInfo(mut: UseMutationResult<User, Error, User, unknown>) {
  if (mut.isPending) {
    console.log("loading");
  } else if (mut.isError) {
    toast("error: There was an error updating the user");
  } else if (mut.isSuccess) {
    toast("success: User updated!");
  }
}

function buildTenantSelect(tenants: Array<Tenant>) {
  const sortedTenants = sortTenantsByName(tenants);

  return sortedTenants.map((l) => {
    return {
      label: l.name,
      value: l.id,
    };
  });
}

function buildLocationSelect(locations: Array<Location>) {
  const sortedLocations = sortLocationsByName(locations);

  return sortedLocations.map((l) => {
    return {
      label: l.name,
      value: l.id,
    };
  });
}

interface UserShowProps {
  user: User;
  locations: Array<Location>;
  roles: Array<Role>;
  tenants: Array<Tenant>;
}
function Edit(props: UserShowProps) {
  const { api } = useAuth();
  const user = props.user;
  const roles = props.roles;
  const locations = buildLocationSelect(props.locations);
  const tenants = buildTenantSelect(props.tenants);

  const [form] = Form.useForm();

  const mut = api.updateUser();

  const onFinish = (u: User) => {
    console.log("Received values of form: ", u);
    u.id = user.id;

    mut.mutate(u);
  };

  const roleOptions = roles.map((r) => {
    return { label: r.name, value: r.id };
  });

  userMutationInfo(mut);

  return (
    <div>
      <MainTitle text={userName(user)} />
      <Divider />
      <Card title="Editing User">
        <Form
          form={form}
          onFinish={onFinish}
          name="edit_user"
          initialValues={user}
          style={{ maxWidth: "500px" }}
        >
          <Form.Item name="given_name" label="Given Name">
            <Input disabled />
          </Form.Item>
          <Form.Item name="family_name" label="Family Name">
            <Input disabled />
          </Form.Item>
          <Form.Item name="working_hours" label="Working Hours">
            <Input min={0} max={40} />
          </Form.Item>

          <Form.Item name="tenant_id" label="Tenant">
            <Select options={tenants} />
          </Form.Item>
          <Form.Item name="location_id" label="Location">
            <Select options={locations} />
          </Form.Item>

          <Form.Item name="role_ids" label="Role">
            <Checkbox.Group>
              <div>
                {roleOptions.map((r) => (
                  <div key={`role-${r.value}`}>
                    <Checkbox value={r.value}>{r.label}</Checkbox>
                    <br />
                  </div>
                ))}
              </div>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item name="active" valuePropName="checked">
            <Checkbox>Active</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}
