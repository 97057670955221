import { TenantRenderer } from "@/components/Table/Renderers/TenantRenderer";
import { Column, Table } from "@/components/Table/Table";
import { useAuth } from "../../Providers/AuthProvider";
import { canViewTenants } from "../../Providers/permissions";
import { Forbidden } from "../../components/Status/Forbidden";
import { Loading } from "../../components/Status/Loading";
import { MainTitle } from "../../components/Typography";
import { Tenant } from "../../models";
import { InternalError } from "@/components/Status/InternalError";

export function TenantIndex() {
  const { api, roles } = useAuth();

  if (!canViewTenants(roles)) {
    return <Forbidden />;
  }

  const fetchTenants = api.fetchTenants();

  if (fetchTenants.isPending) {
    return <Loading />;
  }

  if (fetchTenants.isError) {
    return <InternalError />;
  }

  return (
    <div>
      <MainTitle text="Tenants" />
      <TenantTable tenants={fetchTenants.data} />
    </div>
  );
}

const columns: Column<Tenant>[] = [
  {
    field: "name",
    flex: 1,
    cellRenderer: TenantRenderer,
  },
];

function TenantTable(props: { tenants: Array<Tenant> }) {
  return <Table data={props.tenants} columns={columns} autoHeight={true} />;
}
